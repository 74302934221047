import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[showAuthed]'
})
export class ShowAuthedDirectiveDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthenticationService,
    private viewContainer: ViewContainerRef
  ) { }

  permission: string;

  @Input() set showAuthed(permission: string) {
    this.permission = permission;
  }

  ngOnInit() {
    // debugger;
    var user = this.authService.currentUserValue;
    if (user) {
      var userPermissions = user.permissions;
      if (userPermissions.includes(this.permission)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
    else
      this.viewContainer.clear();
    // if (isAuthenticated && this.condition || !isAuthenticated && !this.condition) {
    //   this.viewContainer.createEmbeddedView(this.templateRef);
    // } else {
    //   this.viewContainer.clear();
    // }
  }
}
