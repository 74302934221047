import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { BehaviorSubject, Subject } from 'rxjs';
import { VotingView } from '../partial/agenda-list/agenda-item/case-voting/Voting.models';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  refreshList = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  /** Treatment Plans **/
  AgendItemTreatmentList(agendaItemId) {
    return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AgendItemTreatmentList`, { agendaItemId });
  }
  saveAgendItemTreatment(data) {
    if (data.id) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/EditAgendItemTreatment`, data);
    } else {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AddAgendItemTreatment`, data);
    }
  }
  DeleteAgendItemTreatment(Id: number) {
    return this.http.get(`${Config.apiUrl}/MeetingAgendaItems/DeleteAgendItemTreatment?Id=${Id}`);
  }
  SortAgendItemTreatment(data) {
    return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/SortAgendItemTreatment`, data);
  }
  getFieldData(url: string) {
    return this.http.get(`${Config.apiUrl}/${url}`);
  }

  /** Recommendation **/
  AgendItemRecommendationList(agendaItemId) {
    return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AgendItemRecommendationList`, { agendaItemId });
  }
  AddAgendItemRecommendation(data) {
    return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AddAgendItemRecommendation`, data);
  }
  DeleteAgendItemRecommendation(Id: number) {
    return this.http.get(`${Config.apiUrl}/MeetingAgendaItems/DeleteAgendItemRecommendation?Id=${Id}`);
  }
  SortAgendItemRecommendation(data) {
    return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/SortAgendItemRecommendation`, data);
  }

  /*** Voting ***/
  listAllVotes(agendaItemId, module) {
    if (module == VotingView.Plan) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/TreatmentPlaneVotesList`, { agendaItemId });
    }
    if (module == VotingView.Recommend) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/RecommendationVoteList`, { agendaItemId });
    }
  }
  startVoting(data, module) {
    if (module == VotingView.Plan) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AddTreatmentPlaneVote`, data);
    }
    if (module == VotingView.Recommend) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AddRecommendationVote`, data);
    }
  }
  submitVote(data, module) {
    if (module == VotingView.Plan) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AddTreatmentPlaneInviteeVote`, data);
    }
    if (module == VotingView.Recommend) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/AddRecommendationInviteeVote`, data);
    }
  }
  closeVote(data, module) {
    if (module == VotingView.Plan) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/CloseTreatmentPlaneVote`, data);
    }
    if (module == VotingView.Recommend) {
      return this.http.post(`${Config.apiUrl}/MeetingAgendaItems/CloseRecommendationVote`, data);
    }
  }

}
