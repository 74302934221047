import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-multi-slide',
  templateUrl: './view-multi-slide.component.html'
})
export class ViewMultiSlideComponent implements OnInit {
  @Input() slides: any[] = [];
  @Output() cancelPop: EventEmitter<any> = new EventEmitter();

  isSubmited = false;
  hasError='';
  notFound = this.translate.instant('general.N/A');
  constructor(
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.slides.map(item => {
      item.checked = false
    });
  }

  viewSlides() {
    this.hasError = '';
    this.isSubmited = true;
    let viewSlides = this.slides.filter(item => item.checked);

    if (!viewSlides.length) { this.hasError = 'required'; }
    if (viewSlides.length > 4) { this.hasError = 'max'; }

    if (this.hasError) return;

    this.cancelPop.emit(viewSlides.map(item => item.id));

    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(
    //     ['admin/slidesViewer'],
    //     {queryParams: {'slide': viewSlides.map(item => item.id)}}
    //   )
    // );

    // window.open(url, '_blank');
  }

}
