import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';

@Component({
  selector: 'app-surgery-form',
  templateUrl: './surgery-form.component.html'
})
export class SurgeryFormComponent implements OnInit {

  clinicalTypes!: any;
  clinicTypeLoading = false;
  // enum clinic, lab and surgery

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() item: any;
  @Input() meetingAgendaItemId: any;
  @Input() meetingId: any;
  @Input() surgeryId: any;
  loading = false;
  formGroup: FormGroup;
  files = [];
  deletedFiles = [];

  genders = [
    { name: 'agendaCase.male', id: 'male' },
    { name: 'agendaCase.female', id: 'female' },
  ];

  isCase;
  minDate = new Date();
  maxDate: Date;
  isValidDate = true;
  language = localStorage.getItem('language') || 'en';

  constructor(
    private detailsService: MeetingDetailsService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.clinicTypeLookupList();
    this.formGroup = this.formBuilder.group({
      sNumber: ['', [Validators.required]],
      doctorName: ['', [Validators.required]],
      sdate: ['', [Validators.required]],
      clinicTypeLookupId: ['', [Validators.required]]
    });
    if (this.item) {
      this.formGroup.controls.sNumber.setValue(this.item.sNumber);
      this.formGroup.controls.doctorName.setValue(this.item.doctorName);
      this.formGroup.controls.sdate.setValue(this.item.sdate);
      this.formGroup.controls.clinicTypeLookupId.setValue(this.item.clinicTypeLookupId);
      this.files = JSON.parse(JSON.stringify(this.item.attachments));
      this.files = this.files.filter(item => !item.isDeleted);
    }
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }
  clinicTypeLookupList() {
    this.clinicTypeLoading = true;
    this.detailsService.clinicTypeLookupList('surgery').subscribe((data) => {
      this.clinicalTypes = data;
      this.clinicTypeLoading = false;
    })
  }

  saveItem() {

    if (this.formGroup.valid) {
      this.loading = true;
      let surgery;
      surgery = {
        sNumber: this.formGroup.controls.sNumber.value,
        doctorName: this.formGroup.controls.doctorName.value,
        sdate: this.formGroup.controls.sdate.value,
        clinicTypeLookupId: this.formGroup.controls.clinicTypeLookupId.value,
        meetingAgendaItemId: this.meetingAgendaItemId
      };

      if (this.item) {
        surgery.deletedAttachments = this.deletedFiles;
        surgery.id = this.surgeryId;
      }
      this.detailsService.saveSurgery(surgery, this.files).subscribe(
        data => {
          this.loading = false;
          this.closePopAndSave.emit();
          if (this.item && this.item.pathologySlides) {
            this.detailsService.refreshSlides.next(true);
          }
        }, err => {
          console.error(err);
        });
    }
  }
  closePop() {
    this.cancelPop.emit();
  }
  counter(i: number) {
    return new Array(i);
  }

  clearDate(event) {
    event.stopPropagation();
    this.formGroup.controls.date.setValue('');
  }
  validateDates(value) {
    this.isValidDate = true;
    if (new Date(value) < new Date()) {
      this.isValidDate = false;
    }
  }

}
