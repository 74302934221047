import { Component, Input, OnInit, ViewChild, ViewRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountdownComponent } from 'ngx-countdown';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { CaseService } from 'src/app/services/Case.service';
import { CaseVote, VotingListData, VotingView } from './Voting.models';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-case-voting',
  templateUrl: './case-voting.component.html'
})
export class CaseVotingComponent implements OnInit {
  @Input() agendaItem: AgendaItem;
  @Input() module: VotingView;
  votingView = VotingView;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  language = localStorage.getItem('language') || 'en';

  dataLoading = false;

  currentTabVal = 0;
  tabs = [
    { label: this.translate.instant('case.Attendees Votes'), value: 0 },
    { label: this.translate.instant('meetings.Summary'), value: 1 }
  ];

  showSetVotingTime = false;
  votingAnswerTime: number = null;

  votingStarted = false;
  startVoteSubmit = false;
  canEndVote = false;
  startVoteLoading = false;

  votingListData: VotingListData;
  currentVote: CaseVote;
  voteAnswerValue;

  listSubscriber: Subscription;
  constructor(
    private service: CaseService,
    private translate: TranslateService,
    private signalRService: SignalRServiceService,
  ) { }

  ngOnInit() {
    this.listAllVotes();
    if (this.module == VotingView.Plan) {
      this.signalRService.handleTreatmentPlaneVotingEvent(this, this.updateVoting);
    }
    if (this.module == VotingView.Recommend) {
      this.signalRService.handleRecommendationVotingEvent(this, this.updateVoting);
    }

    this.tabs.unshift(
      { label: this.translate.instant('case.' + (this.module == VotingView.Plan ? 'Plan' : 'Recommendations')), value: 2 }
    )
  }

  updateVoting(context, agendaItemId, canEndVote) {
    if (context.agendaItem.agendaItemEntityId === agendaItemId) {
      if (canEndVote) {
        context.canEndVote = true;
        context.listAllVotes();
        if (context.changeDetectorRef) {
          context.changeDetectorRef.detectChanges();
        }
        setTimeout(() => {
          if (context.changeDetectorRef && !(context.changeDetectorRef as ViewRef).destroyed) {
            context.changeDetectorRef.detectChanges();
          }
        });
      }
    }
  }

  listAllVotes() {
    if (this.listSubscriber) this.listSubscriber.unsubscribe();
    this.dataLoading = true;
    this.listSubscriber = this.service.listAllVotes(this.agendaItem.agendaItemEntityId, this.module).subscribe(
      (response: VotingListData) => {
        this.dataLoading = false;
        this.votingListData = response;
        this.currentVote = this.votingListData.pageData.find(v => !v.votingClosed);
        this.votingListData.pageData.map(i => {
          i.history = i.treatmentPlaneHistory || i.recommendationHistory
        })
      }, error => {
        console.log('Faild');
      });
  }

  startVote() {
    this.startVoteSubmit = true;
    if (!this.votingAnswerTime) return;
    this.startVoteLoading = true;
    let data = {
      agendaItemId: this.agendaItem.agendaItemEntityId,
      votingAnswerTime: this.votingAnswerTime
    }
    this.service.startVoting(data, this.module).subscribe(
      (response) => {
        this.startVoteLoading = false;
        this.listAllVotes();
      }, error => {
        console.log('Faild');
      });
  }

  closeVote() {
    this.dataLoading = true;
    let data = {
      agendaItemId: this.agendaItem.agendaItemEntityId,
      voteId: this.currentVote.id
    }
    this.service.closeVote(data, this.module).subscribe(
      (response) => {
        this.resetVoteScreen();
        this.listAllVotes();
      }, error => {
        console.log('Faild');
      });
  }

  timesUp(event) {
    if (event.action === 'done') {
      this.countdown.stop();
      this.resetVoteScreen();
      setTimeout(() => {
        this.listAllVotes();
      }, 1000);
    }
  }

  submitVote() {
    let data = {
      agendaItemEntityId: this.agendaItem.agendaItemEntityId,
      voteId: this.currentVote.id,
      votingSchemaOptionId: this.voteAnswerValue,
      createdAsId: ''
    };
    this.service.submitVote(data, this.module).subscribe(
      (response) => {
        this.listAllVotes();
      }, error => {
        console.log('Faild');
      });
  }

  resetVoteScreen() {
    this.showSetVotingTime = false;
    this.votingAnswerTime = null;

    this.votingStarted = false;
    this.startVoteSubmit = false;
    this.canEndVote = false;
  }
}
