import { Component, OnInit, NgZone } from '@angular/core';
import { LookupsService } from 'src/app/services/lookups.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user/user';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SideBarComponent implements OnInit {
  menuApiItems: any[] = [];
  shouldOpenMenu = false;
  unseenMessages: any;
  currentUser: User = {
    name: '',
  } as User;
  activeMenu: string;
  currentYear = null;
  constructor(
    private authenticationService: AuthenticationService,
    private lookupsService: LookupsService,
    private sidebarService: SidebarService,
    private signalRService: SignalRServiceService,
    public zone: NgZone,
    private messagesService: MessagesService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.signalRService.buildConnection();
    this.handleNewMessageToUpdateNotification();
  }

  ngOnInit() {
    this.GetCurrentYear();
    this.getMenuItems();
    this.GetUnseenMessagesCount();
    this.sidebarService.currentMessage.subscribe(
      message => {
        this.shouldOpenMenu = message;
      });
  }

  isActiveMenu(item) {
    return location.href.includes(item);
  }
  handleNewMessageToUpdateNotification() {
    this.signalRService.handleNewMessageHubEvent(this, this.updateMessagesNumber);
  }

  updateMessagesNumber(context, messageCount) {
    context.zone.run(() => {
      context.GetUnseenMessagesCount();
    });
  }

  toggleOpenClass() {
    this.shouldOpenMenu = !this.shouldOpenMenu;
    this.sidebarService.messageSource.next(this.shouldOpenMenu);
    this.activeMenu = null;
  }

  GetCurrentYear() {
    this.lookupsService.GetCurrentYear().subscribe(
      data => {
        this.currentYear = data as any;
      },
      err => {
        console.error(err);
      },
      () => { }
    );
  }

  getMenuItems() {
    this.lookupsService.getMenu().subscribe(
      data => {
        this.menuApiItems = data as any[];
        const menuList = [];
        this.menuApiItems.forEach(element => {
          menuList.push(element.url);
        });
        this.authenticationService.menuItems.next(menuList);
        this.sidebarService.menuLoaded.next(true);
      },
      err => {
        console.error(err);
      },
      () => { }
    );
  }
  logout() {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  GetUnseenMessagesCount() {
    this.messagesService.GetUnseenMessagesCount().subscribe(
      result => {
        this.unseenMessages = result;
      }, err => {
        console.error(err);
      });
  }

  goTo() {
    // this.router.navigate([url]);
    setTimeout(() => {
      this.shouldOpenMenu = false;
      this.sidebarService.messageSource.next(this.shouldOpenMenu);
    }, 100);

  }
}
