
export class CommentAsModal {
    id: string;
    fullName: string;
    email: string;
    userName: string;
    profilePhoto: string;
    collectionEntityId: number;
    userArabicName: string;
    collectionsGroupingId: number;
    currentUser: boolean;
}
