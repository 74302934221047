import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SlidesService } from 'src/app/services/slides.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-slides-list',
  templateUrl: './slides-list.component.html'
})
export class SlidesListComponent implements OnInit {
  @Input() meetingId: any;
  @Input() meeting: Meeting;
  @Input() meetingAgendaItemId: any;
  @Input() liveView!: boolean;

  openSlideForm = false;
  openLinkSlideForm = false;

  dataLoading = false;
  slidesList!: any;

  selectedSlide;
  isUpdate = false;

  notFound = this.translate.instant('general.N/A');

  constructor(
    private service: SlidesService,
    private detailsService: MeetingDetailsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.GetMeetingDetails();
    this.GetPathologySlides();
    this.service.refreshList.subscribe(data => {
      if (data && this.meetingId) {
        this.GetPathologySlides();
      }
    });
    this.detailsService.refreshSlides.subscribe(data => {
      if (data && this.meetingId) {
        this.GetPathologySlides();
      }
    });
  }

  GetMeetingDetails() {
    this.detailsService.GetMeetingDetails(this.meetingId).subscribe(
      result => {
        if (result) {
          this.dataLoading = false;
          this.meeting = result as Meeting;
        }
      },
      err => {
        this.meeting = null;
        this.dataLoading = false;
        console.error(err);
      }
    );
  }

  openSlidesViewer(slidesIds: number[]) {
    this.service.openSlidesViewer.next(slidesIds);
  }

  GetPathologySlides() {
    this.dataLoading = true;
    this.service.GetPathologySlides(this.meetingId, this.meetingAgendaItemId).subscribe(
      resp => {
        this.dataLoading = false;
        this.slidesList = resp as [];
      }, err => {
        console.error(err);
        this.dataLoading = false;
      });
  }

  openLinkSlide(slide) {
    this.selectedSlide = slide;
    this.openLinkSlideForm = true;
  }

  DeleteSlide(slideId: number) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.service.DeleteSlide(slideId).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              // text:  this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.GetPathologySlides();
          }, error => {
            console.error(error);
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text:  this.translate.instant('swalMsg.FailedText'),
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
          });
      }
    });
  }

  editSlide(slide) {
    this.selectedSlide = slide;
    this.openSlideForm = true;
    this.isUpdate = true;
  }
}
