import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SlidesService } from 'src/app/services/slides.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-slide-form',
  templateUrl: './slide-form.component.html'
})
export class SlideFormComponent implements OnInit {
  @Input() meeting: Meeting;
  @Input() meetingId: any;
  @Input() agendaItemId: any;

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() slide: any;
  @Input() isUpdate: boolean = false;

  loading = false;
  formGroup: FormGroup;
  isSubmited = false;

  dataLoading = false;
  slideLookUps: any;

  selectedSubspeciality;

  itemsLoading = false;
  agendItems: AgendaItem[] = [];

  slidesLoading = false;
  slides: any[] = [];

  files: any[] = []

  selectedStainType: any;
  selectedSpecimenSource: any;
  selectedTissueType: any;
  relatedAgendaItem: any;

  constructor(
    private service: SlidesService,
    private detailsService: MeetingDetailsService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.buildForm();
    this.GetSlideLookUps();
    this.listAgendItems();

    for (var k in this.meeting.pathologyCommunictions) {
      if (this.meeting.pathologyCommunictions.hasOwnProperty(k)) {
        if (this.meeting.pathologyCommunictions[k]) {
          this.formGroup.controls['CommunicationMethodLookup'].setValue(this.meeting.pathologyCommunictions[k].lookupCode);
          if (this.meeting.pathologyCommunictions[k] != 3) {
            this.GetSlidesList();
          }
          break;
        }
      }
    }


  }
  buildForm() {
    this.formGroup = this.formBuilder.group({
      tissueType: [],
      specimenSource: [],
      stainType: [],
      meetingAgendaItemId: [this.agendaItemId],
      CommunicationMethodLookup: [null, Validators.required],
      SelectedSlide: [null]
    });
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  bindDataToUpdate() {
    this.selectedSubspeciality = this.slideLookUps.tissueWithSubspecialityLookupDTOs.find(item => item.id == this.slide.subspeciality.id);
    if (this.selectedSubspeciality) {
      this.selectedSubspeciality.active = true;
      this.selectedTissueType = this.slide.tissueType;
    }
    if (this.slide.relatedAgendaItem) {
      this.relatedAgendaItem = this.slide.relatedAgendaItem.id
    }
    this.formGroup.patchValue({
      tissueType: this.slide.tissueType.id,
      specimenSource: this.slide.specimenSource.specimenSourceId,
      stainType: this.slide.stainType.id,
      meetingAgendaItemId: this.agendaItemId,
      // CommunicationMethodLookup: this.slide.CommunicationMethodLookup,
      // SelectedSlide: this.slide.SelectedSlide.meetingId
    });
  }

  typeChange() {
    this.formGroup.controls['SelectedSlide'].setValue(null);
    this.files = [];
    if (this.formGroup.controls['CommunicationMethodLookup'].value != 3) {
      this.GetSlidesList();
    }
  }

  GetSlideLookUps() {
    this.dataLoading = true;
    this.service.GetSlideLookUps().subscribe(
      resp => {
        this.dataLoading = false;
        this.slideLookUps = resp;
        if (this.isUpdate) {
          this.bindDataToUpdate();
        }
      }, err => {
        console.error(err);
        this.loading = false;
      });
  }
  GetSlidesList() {
    this.slidesLoading = true;
    this.slides = [];
    this.service.GetSelectedSlides(
      this.meetingId,
      this.formGroup.controls['CommunicationMethodLookup'].value
    ).subscribe(
      resp => {
        this.slidesLoading = false;
        this.slides = resp as [];
      }, err => {
        console.error(err);
        this.slidesLoading = false;
      });
  }
  listAgendItems() {
    const obj = {
      id: this.meetingId,
      PageNumber: 0,
      PageSize: 100
    };
    this.itemsLoading = true;
    this.detailsService.ListAgendItems(obj).subscribe(
      (result: any) => {
        this.itemsLoading = false;
        this.agendItems = result.pageData as AgendaItem[];
      },
      err => {
        this.itemsLoading = false;
        console.error(err);
      }
    );
  }

  save() {
    this.isSubmited = true;
    if (
      this.formGroup.controls['CommunicationMethodLookup'].value == 3
      && !this.files.length && !this.isUpdate
    ) { return; }

    if (this.formGroup.valid) {

      // let data: any = this.formGroup.value;
      let data: any;

      this.slideLookUps.stainTypes.forEach((stainType) => {
        if (this.formGroup.controls.stainType.value == stainType.id) {
          this.selectedStainType = stainType;
        }
      });

      this.slideLookUps.specimenSourceLookupDTO.forEach((specimenSource) => {
        if (this.formGroup.controls.specimenSource.value == specimenSource.specimenSourceId) {
          // if (this.isUpdate) {
          //   this.selectedSpecimenSource = {
          //     "id": this.formGroup.controls.specimenSource.value,
          //     "name": specimenSource.specimenSourceName
          //   }
          // } else {
          //   this.selectedSpecimenSource = specimenSource;
          // }
          this.selectedSpecimenSource = {
            "id": this.formGroup.controls.specimenSource.value,
            "name": specimenSource.specimenSourceName
          }
        }
      });
      if (this.selectedSubspeciality) {
        if (this.selectedSubspeciality.childs) {
          this.selectedSubspeciality.childs.forEach(element => {
            if (this.formGroup.controls.tissueType.value == element.id) {
              this.selectedTissueType = element;
            }
          });
          delete this.selectedSubspeciality.childs;
        }
      }

      data = {
        subspeciality: this.selectedSubspeciality,
        tissueType: this.selectedTissueType,
        stainType: this.selectedStainType,
        specimenSource: this.selectedSpecimenSource,
        meetingAgendaItemId: this.formGroup.controls.meetingAgendaItemId.value,
      }

      this.loading = true;
      if (!this.isUpdate) {
        data.CommunicationMethodLookup = this.formGroup.controls.CommunicationMethodLookup.value,
        data.SelectedSlide = this.formGroup.controls.SelectedSlide.value,
        data.meetingId = this.meeting.meetingId
        this.service.AddSlide(data, this.files).subscribe(
          data => {
            this.loading = false;
            Swal.fire({
              title: this.translate.instant('swalMsg.addedsuccessfully'),
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok'),
            });
            this.closePopAndSave.emit();
          }, err => {
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text: '',
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.loading = false;
          });
      } else {
        data.id = this.slide.id
        this.service.EditSlide(data).subscribe(
          data => {
            this.loading = false;
            Swal.fire({
              title: this.translate.instant('swalMsg.updatedsuccessfully'),
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok'),
            });
            this.closePopAndSave.emit();
          }, err => {
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text: '',
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.loading = false;
          });
      }
    }
  }
  closePop() {
    this.cancelPop.emit();
  }

  activateParent(group, child) {
    this.selectedSubspeciality = JSON.parse(JSON.stringify(group));
    this.selectedTissueType = child;
    setTimeout(() => { group.active = true }, 100)
  }
  clearSpaciality() {
    this.selectedSubspeciality = null;
    this.selectedTissueType = null;
  }

  activeGroups() {
    if (this.slideLookUps && this.slideLookUps.tissueWithSubspecialityLookupDTOs) {
      this.slideLookUps.tissueWithSubspecialityLookupDTOs.map(item => item.active = true);
    }
  }
}
