
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DICOMService {
    refreshList = new BehaviorSubject<boolean>(false);
    openStudyViewer = new Subject<string>();

    constructor(private http: HttpClient) { }

    AddDicomFile(files: any, data: any) {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        files.forEach(file => {
            formData.append('files', file, file.name);
          });
        return this.http.post(`${Config.apiUrl}/Meetings/AddDicomFile`, formData);
    }
    GetMeetingDicomFiles(meetingId: number, agendaItemId?: number) {
        return this.http.get(`${Config.apiUrl}/Meetings/GetMeetingDicomFiles?meetingId=${meetingId}&agendaItemId=${agendaItemId}`);
    }
    DeleteMeetingDicomFile(studyUID: any, meetingId: number) {
        return this.http.get(`${Config.apiUrl}/Meetings/DeleteMeetingDicomFile?studyUID=${studyUID}&meetingId=${meetingId}`);
    }
    GetDicomServer() {
        return this.http.get(`${Config.apiUrl}/Meetings/GetDicomServerConfiguration`);
    }
}
