export class Comment {
  canEdit: boolean;
  comment: string;
  commentBy: string;
  commentByImage: string;
  commentByName: string;
  commentDate: string;
  commentId: boolean;

  editMode: boolean;
}

export class CommentsFilter {
  SlideID: number;
  PathologistId: number;
  UserType: string;
  FilterIds: string;
  Token?: string;
  SharedFromDemo: boolean;
}
