import { Injectable, EventEmitter } from '@angular/core';
declare var $: any;
import { Config } from '../models/config';
import * as signalR from '@aspnet/signalr';
import { HttpClient, HttpRequest, HttpResponse, IHttpConnectionOptions } from '@aspnet/signalr';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class SignalRServiceService {
  signalReceived = new EventEmitter<any>();

  private hubConnection: signalR.HubConnection;

  constructor(private authService: AuthenticationService) { }

  public buildConnection = () => {
    const options: IHttpConnectionOptions = {
      accessTokenFactory() {
        return this.authService.currentUserValue.access_token;
      }
    };

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(Config.signalRUrl, {
        transport: signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => this.authService.currentUserValue.access_token
      })
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started ...'))
      .catch(err => console.log('Error while starting connection ... ' + err));
  }

  // buildConnection() {
  //   let connection = $.hubConnection(Config.signalRUrl);
  //   this.hubConnection = connection.createHubProxy('hubConnection');
  //   this.hubConnection.on('testEvent', () => {
  //     // debugger;
  //   });
  //   const token = this.authService.currentUserValue.access_token;
  //   $.signalR.ajaxDefaults.headers = { Authorization: 'Bearer ' + token };
  //   connection.start()
  //     .done(() => {
  //       // console.log('connection started ..........');
  //     })
  //     .fail(() => {
  //       console.log('connection failed');
  //     });
  // }

  // starthubConnectionConnection() {
  //   this.hubConnection = this.SignalrConnection.createHubProxy('hubConnection');
  //   var token = this.authService.currentUserValue.access_token;
  //   // $.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };
  //   var self = this;
  //   self.hubConnection.on("testEvent", function () {
  //     debugger;
  //   });
  //   this.SignalrConnection.start().done(function () {
  //     // alert("Connected to Signalr Server");

  //     self.hubConnection.on("testEvent", function () {
  //       debugger;
  //     });
  //   })
  //     .fail(function () {
  //       alert("failed in connecting to the signalr server");
  //     });
  // }

  handleChangeNotificationCountEvent(context, handleFunc) {
    this.hubConnection.on('changeNotificationCount', (messageCount) => {
      handleFunc(context, messageCount);
    });
  }

  handleNewMessageHubEvent(context, handleFunc) {
    this.hubConnection.on('changeMessageCount', (messageCount) => {
      handleFunc(context, messageCount);
    });
  }

  handleLoadMessageListEvent(context, handleFunc) {
    this.hubConnection.on('loadMessageList', () => {
      handleFunc(context);
    });
  }

  // NEW
  handleLoadNewGroupVoteEvent(context, handleFunc) {
    this.hubConnection.on('newgroupVote', (mileStoneId) => {
      handleFunc(context, mileStoneId);
    });
  }

  handleLoadMessageCommentsEvent(context, handleFunc) {
    this.hubConnection.on('loadMessageComments', (messageId) => {
      handleFunc(context, messageId);
    });
  }

  handleReadUnReadMessageEvent(context, handleFunc) {
    this.hubConnection.on('ReadUnReadMessage', (messageId) => {
      handleFunc(context, messageId);
    });
  }




  handleChangeCommentNumberEvent(context, handleFunc) {
    this.hubConnection.on('changeCommentNumber', (messageId, commentsNumber) => {
      handleFunc(context, messageId, commentsNumber);
    });
  }

  handleChangeReplayNumberEvent(context, handleFunc) {
    this.hubConnection.on('changeReplayNumber', (messageId, commentsNumber) => {
      handleFunc(context, messageId, commentsNumber);
    });
  }

  handleLoadCommentReplaiesEvent(context, handleFunc) {
    this.hubConnection.on('loadCommentReplaies', (commentId, parentId) => {
      if (parentId === -1) {
        parentId = null;
      }
      handleFunc(context, commentId, parentId);
    });
  }

  handleChangeVoteNumberEvent(context, handleFunc) {
    this.hubConnection.on('changeVoteNumber', (messageId, upVoteNumber, downVoteNumber) => {
      handleFunc(context, messageId, upVoteNumber, downVoteNumber);
    });
  }

  handleEntityNewMemberEvent(context, handleFunc) {
    this.hubConnection.on('entityNewMember', (entityId) => {
      handleFunc(context, entityId);
    });
  }
  handleGroupNewEntityEvent(context, handleFunc) {
    this.hubConnection.on('groupNewEntity', (groupId) => {
      handleFunc(context, groupId);
    });
  }
  handleGroupNewUserEvent(context, handleFunc) {
    this.hubConnection.on('groupNewUser', (groupId) => {
      handleFunc(context, groupId);
    });
  }
  handleMainTaskListChangeEvent(context, handleFunc) {
    this.hubConnection.on('mainTaskListChange', (groupId) => {

      handleFunc(context, groupId);
    });
  }
  handleMainTaskDetailsChangeEvent(context, handleFunc) {
    this.hubConnection.on('mainTaskDetailsChange', (taskId, taskType) => {
      handleFunc(context, taskId, taskType);
    });
  }
  handleSubTaskListChangeEvent(context, handleFunc) {
    this.hubConnection.on('mainTaskDetailsChange', (entityId) => {
      handleFunc(context, entityId);
    });
  }

  // Meeting
  handleRefreshMeetingListEvent(context, handleFunc) {
    this.hubConnection.on('refreshMeetingList', () => {
      handleFunc(context);
    });
  }

  handleGoLiveEvent(context, handleFunc) {
    this.hubConnection.on('goLive', (meetingId) => {
      handleFunc(context, meetingId);
    });
  }

  handleMeetingChangedEvent(context, handleFunc) {
    this.hubConnection.on('meetingChanged', (meetingId) => {
      handleFunc(context, meetingId);
    });
  }
  handleNewRecommendationEvent(context, handleFunc) {
    this.hubConnection.on('newRecommendation', (meetingId) => {
      handleFunc(context, meetingId);
    });
  }
  handleStartVotingEvent(context, handleFunc) {
    this.hubConnection.on('startVoting', (agendaItemId, votingTime) => {
      handleFunc(context, agendaItemId, votingTime);
    });
  }
  handleEndVotingEvent(context, handleFunc) {
    this.hubConnection.on('endVoting', (agendaItemId, votingTime) => {
      handleFunc(context, agendaItemId, votingTime);
    });
  }
  handleNewVotingEvent(context, handleFunc) {

    this.hubConnection.on('newVoting', (agendaItemId, canEndVote) => {
      handleFunc(context, agendaItemId, canEndVote);
    });
  }
  handleNewDelegationEvent(context, handleFunc) {
    this.hubConnection.on('newDelegation', (meetingId) => {
      handleFunc(context, meetingId);
    });
  }
  handleUpdateInviteesListEvent(context, handleFunc) {
    this.hubConnection.on('updateInviteesList', (meetingId) => {
      handleFunc(context, meetingId);
    });
  }

  handleRefreshDashBoardEvent(context, handleFunc) {
    this.hubConnection.on('RefreshDashBoard', (target) => {
      handleFunc(context, target);
    });
  }

  // chat
  handleNewChatMessageEvent(context, handleFunc) {
    this.hubConnection.on('newChatMessage', (loggedUserId, messageContent) => {
      handleFunc(context, loggedUserId, messageContent);
    });
  }

  handleRefreshUserPermissionsEvent(context, handleFunc) {
    this.hubConnection.on('refreshUserPermissions', () => {
      handleFunc(context);
    });
  }
  handleLogOutInActiveUserEvent(context, handleFunc) {
    this.hubConnection.on('LogOutInActiveUser', (logoutforedit) => {
      handleFunc(context, logoutforedit);
    });
  }
  handleTreatmentPlaneVotingEvent(context, handleFunc) {
    this.hubConnection.on('TreatmentPlaneVoting', (canEndVote, agendaItemId, eventtype) => {
      handleFunc(context, canEndVote, agendaItemId, eventtype);
    });
  }
  handleTreatmentPlaneEvent(context, handleFunc) {
    this.hubConnection.on('TreatmentPlane', (agendaItemId) => {
      handleFunc(context, agendaItemId);
    });
  }
  handleRecommendationVotingEvent(context, handleFunc) {
    this.hubConnection.on('RecommendationVoting', (canEndVote, agendaItemId, eventtype) => {
      handleFunc(context, canEndVote, agendaItemId, eventtype);
    });
  }
  handleRecommendationPlaneEvent(context, handleFunc) {
    this.hubConnection.on('RecommendationPlane', (agendaItemId) => {
      handleFunc(context, agendaItemId);
    });
  }
}
