import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateEntity } from '../models/entity/CreateEntity';
import { Config } from '../models/config';
import { EntitySearchModel } from '../models/entity/EntitySearchModel';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EntityService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {
  }


  CreateEntity(entity: CreateEntity, fileToUpload: any) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(entity));
    if (fileToUpload && fileToUpload.name) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }
    return this.http.post(`${Config.apiUrl}/CollectionEntity`, formData);
  }

  EditEntity(entity: CreateEntity, fileToUpload: any, id) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(entity));
    if (fileToUpload.name) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }

    return this.http.put(`${Config.apiUrl}/CollectionEntity?id=` + id, formData);
  }

  deleteEntity(id) {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/Delete/` + id);
    return this.http.get(`${Config.apiUrl}/CollectionEntity/HardDelete/` + id);
  }

  GetEntities(searchModel: EntitySearchModel) {
    return this.http.post(`${Config.apiUrl}/CollectionEntity/SearchEntities`, searchModel);
  }

  GetUserEntities(searchModel: EntitySearchModel) {
    return this.http.post(`${Config.apiUrl}/CollectionEntity/GetUserEntities`, searchModel);
  }

  GetSubEntities(searchModel: EntitySearchModel) {
    return this.http.post(`${Config.apiUrl}/CollectionEntity/GetSubEntities`, searchModel);
  }

  GetEntityDetails(id) {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/GetEntityDetails/` + id);
  }

  addNewMember(addNewMemberObject: any): any {
    return this.http.post(`${Config.apiUrl}/CollectionEntity/AddNewMember`, addNewMemberObject);
  }

  removeMember(collectionId, memberId): any {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/RemoveMember?collectionId=` + collectionId + '&memberId=' + memberId);
  }

  updateCollectionMemberStatus(userCollectionId, status): any {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/UpdateCollectionMemberStatus?userCollectionId=`
      + userCollectionId + '&status=' + status);
  }

  GetUserPendingEntities() {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/GetUserPendingEntities`);
  }
}
