import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { User } from 'src/app/models/user/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
@Component({
  selector: 'app-multi-user-jitsi',
  templateUrl: './multi-user-jitsi.component.html',
  styles: []
})
export class MultiUserJitsiComponent implements OnInit {

  loginUser: User;
  apiObj: any;
  dataLoading = true;
  meetingId: number;
  meeting: Meeting = new Meeting();

  listUser = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private signalRService: SignalRServiceService,
    private detailsService: MeetingDetailsService
  ) {
    this.loginUser = authenticationService.currentUserValue;
    console.log(this.listUser)
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.meetingId = Number(params.get('meetingId'));
      this.getDetails();
      this.meeting.meetingId = this.meetingId;
    });
    this.apiObj = (window as any).JitsiMeetJS;

    this.signalRService.handleMeetingChangedEvent(this, this.refreshDetails);
  }
  refreshDetails(context, meetingId) {
    if (meetingId === context.meetingId) {
      context.getDetails();
      context.changeDetectorRef.detectChanges();
    }
  }

  getDetails() {
    this.detailsService.GetMeetingDetails(this.meetingId).subscribe(
      result => {
        this.dataLoading = false;
        this.meeting = result as Meeting;
        const startTime = this.meeting.startTime.split(':');
        this.meeting.startTime = startTime[0] + ':' + startTime[1];
        // const endTime = this.meeting.endTime.split(':');
        // this.meeting.endTime = endTime[0] + ':' + endTime[1];
        if (this.meeting.endTime) {
          const endTime = this.meeting.endTime.split(':');
          this.meeting.endTime = endTime[0] + ':' + endTime[1];
        }
        this.startMeeting();
      },
      err => {
        this.dataLoading = false;
        console.error(err);
      }
    );
  }

  startMeeting() {
    // const domain = this.meeting.jitsiServerUrl;
    const domain = 'meet.inndeve.com';
    this.meeting.roomName = 'test';
    var options = null;
    if (this.meeting.isOwner)
      options = {
        roomName: this.meeting.roomName,
        jwt: this.loginUser.token,
        userInfo: {
          email: this.loginUser.email,
          displayName: this.loginUser.name,
          avatarUrl: this.loginUser.profilePicture
        },
        parentNode: document.querySelector('#jitsi-meet-conf-container'),
        onload: () => {
        }
      };
    else
      options = {
        roomName: this.meeting.roomName,
        userInfo: {
          email: this.loginUser.email,
          displayName: this.loginUser.name,
          avatarUrl: this.loginUser.profilePicture
        },
        parentNode: document.querySelector('#jitsi-meet-conf-container'),
        interfaceConfigOverwrite: {
          filmStripOnly: true,
          TOOLBAR_ALWAYS_VISIBLE: true,
          TOOLBAR_BUTTONS: ['help', 'select-background', 'toggle-camera', 'videoquality', 'camera', 'hangup',
                            'microphone', 'settings', 'desktop', 'chat', 'fullscreen', 'raisehand', 'tileview'],
          SETTINGS_SECTIONS: ['devices'],
          OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron'],
          VERTICAL_FILMSTRIP: false,
          RECENT_LIST_ENABLED: false,
          DEFAULT_REMOTE_DISPLAY_NAME: 'Friend',
          DEFAULT_LOCAL_DISPLAY_NAME: 'Me',
          SHOW_JITSI_WATERMARK: false,
          APP_NAME: 'Jitsi call',
          NATIVE_APP_NAME: 'Jisti call',
          MOBILE_APP_PROMO: false,
        },
      };


    this.listUser.forEach(element => {
      options.userInfo.email = element.email;
      options.userInfo.displayName = element.displayName;

      this.apiObj = new (window as any).JitsiMeetExternalAPI(domain, options);
      this.apiObj.executeCommand('subject', this.meeting.meetingTitle);
      var self = this;
      if (self.meeting.isOwner) {
        this.apiObj.addEventListener('participantRoleChanged', function (event) {
          console.log(event.role);
          if (event.role === "moderator") {
            // self.apiObj.executeCommand('password', self.meeting.meetingTitle.replace(" ", "") + self.meeting.roomName);
            self.apiObj.executeCommand('password', self.meeting.meetingId + self.meeting.roomName);
          }
        });
      }
      self.apiObj.on('passwordRequired', () => {
        // self.apiObj.executeCommand('password', self.meeting.meetingTitle.replace(" ", "") + self.meeting.roomName)
        self.apiObj.executeCommand('password', self.meeting.meetingId + self.meeting.roomName);

      });
      self.apiObj.addEventListener('readyToClose', function (event) {
        self.router.navigate(['admin/meetings/', self.meetingId]);
        if (self.meeting.isOwner) {
          self.detailsService.EndMeeting(self.meetingId).subscribe(
            data => {
              // self.getDetails();
            },
            error => {
              console.log('Try again!, ' + error);
            });
        }
      });
    });

  }

}
