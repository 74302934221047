export class MeetingGroupDetails {
    abbreviation: string;
    groupName: string;
    groupId: number;
    groupArabicName: string;
    pathologyCaseSlide: { 
        canUploadPathologySlides: boolean, 
        entitiesCommunications: [] 
    }
}
