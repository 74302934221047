import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { MeetingSearchModel } from '../models/meeting/MeetingSearchModel';
import { SubmitMeetingInvitaion } from 'src/app/models/meeting/SubmitMeetingInvitaion';
import { BehaviorSubject } from 'rxjs';
import { AddMeeting } from 'src/app/models/meeting/AddMeeting';
@Injectable({
  providedIn: 'root'
})
export class MeetingsService {
  originUrl = Config.originUrl;
  refreshDetails = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  getListInviteeMeetings(searchModel: MeetingSearchModel): any {
    return this.http.post(`${Config.apiUrl}/meetings/ListInviteeMeetings`, searchModel);
  }
  GetUserGroups() {
    return this.http.get(`${Config.apiUrl}/meetings/GetUserGroups`);
  }
  GetLiveMeetings() {
    return this.http.get(`${Config.apiUrl}/meetings/GetLiveMeetings`);
  }
  GetInviteesToDelegate(meetingId) {
    return this.http.get(`${Config.apiUrl}/meetingInvitees/GetInviteesToDelegate/` + meetingId);
  }
  SubmitMeetingInvitaion(SubmitMeetingInvitaionObj: SubmitMeetingInvitaion): any {
    return this.http.post(`${Config.apiUrl}/meetingInvitees/SubmitMeetingInvitaion`, SubmitMeetingInvitaionObj);
  }
  getListMeetings() {
    return this.http.get(`${Config.apiUrl}/meetings/ListMeetings`);
  }
  GetGroupCollectionAndUsers(groupId, searchText = null) {
    if (searchText != null && searchText != "") {
      return this.http.get(`${Config.apiUrl}/meetings/GetGroupCollectionAndUsers/` + groupId + '?searchText=' + searchText);
    } else {
      return this.http.get(`${Config.apiUrl}/meetings/GetGroupCollectionAndUsers/` + groupId);
    }
  }

  AddMeeting(AddMeetingObj: AddMeeting): any {
    const formData = new FormData();
    AddMeetingObj.agendaItems.forEach((element, index) => {
      element.files.forEach(file => {
        formData.append(String(index), file, file.name);
      });
    });
    formData.append('data', JSON.stringify(AddMeetingObj));
    return this.http.post(`${Config.apiUrl}/meetings/AddMeeting`, formData);
  }
  EditMeetingAgendaItem(obj) {
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/EditMeetingAgendaItem`, obj);
  }

}
