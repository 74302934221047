import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { User } from 'src/app/models/user/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import * as CryptoJS from 'crypto-js';
import { Config } from 'src/app/models/config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-jitsi-meeting',
  templateUrl: './jitsi-meeting.component.html'
})
export class JitsiMeetingComponent implements OnInit {

  loginUser: User;
  apiObj: any;
  dataLoading = true;
  meetingId: number;
  meeting: Meeting = new Meeting();

  jitsiContainer = null;
  loading = false;
  errorMsg = null;
  errorData = null;
  expiryDate = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private signalRService: SignalRServiceService,
    private translation: TranslateService,
    private detailsService: MeetingDetailsService
  ) {
    this.loginUser = authenticationService.currentUserValue;
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   this.meetingId = Number(params.get('meetingId'));
    //   this.getDetails();
    //   this.meeting.meetingId = this.meetingId;
    // });
    // this.apiObj = (window as any).JitsiMeetJS;

    // this.signalRService.handleMeetingChangedEvent(this, this.refreshDetails);

    // Refresh all page
    this.detailsService.refreshMeetingDetails.subscribe(data => {
      if (data) {
        this.meeting = data;
        this.meetingId = this.meeting.meetingId;
        this.getDetails();
      }
    });

    // this.apiObj = (window as any).JitsiMeetJS;
  }

  generateToken(payload: any, key: string) {
    const secret = key;
    const token: any = this.encodeToken(payload);

    let signature: any = CryptoJS.HmacSHA256(token, secret);
    signature = this.base64url(signature);

    const signedToken = token + '.' + signature;
    return signedToken;
  }
  base64url(source: any) {
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    encodedSource = encodedSource.replace(/=+$/, '');

    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }
  encodeToken(payload: any) {
    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };

    const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    const encodedHeader = this.base64url(stringifiedHeader);

    const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
    const encodedData = this.base64url(stringifiedData);

    return encodedHeader + '.' + encodedData;
  }

  // getDetails() {
  //   if (this.meeting.startDate) {
  //     const startDate = this.meeting.startDate.split('T');
  //     this.meeting.startDate = startDate[0];
  //   }
  //   if (this.meeting.startTime) {
  //     const startTime = this.meeting.startTime.split(':');
  //     this.meeting.startTime = startTime[0] + ':' + startTime[1];
  //   }
  //   if (this.meeting.endTime) {
  //     const endTime = this.meeting.endTime.split(':');
  //     this.meeting.endTime = endTime[0] + ':' + endTime[1];
  //   }
  //   this.meeting.endDateTime = this.meeting.startDate + 'T' + this.meeting.endTime;


  //   this.setExpDate();
  //   if (this.meeting.jitsiServerUrl) {
  //     this.startMeeting();
  //   } else {
  //     this.errorMsg = this.translation.instant('meetings.Error has been occurred!, Please contact Administator.');
  //   }
  // }

  // setExpDate(extend = false) {
  //   let date = new Date();
  //   if (extend) {
  //     date.setTime(date.getTime() + (Number(Config.NoOfHoursToEndMeeting) * 60 * 60 * 1000));
  //   } else {
  //     if (this.meeting.startDate && this.meeting.endTime) {
  //       date = new Date(this.meeting.endDateTime);
  //     }
  //   }
  //   this.expiryDate = Math.floor(date.getTime() / 1000);
  // }

  // Same regression
  getDetails() {
    const startTime = this.meeting.startTime.split(':');
    this.meeting.startTime = startTime[0] + ':' + startTime[1];
    // const endTime = this.meeting.endTime.split(':');
    // this.meeting.endTime = endTime[0] + ':' + endTime[1];
    if (this.meeting.endTime) {
      const endTime = this.meeting.endTime.split(':');
      this.meeting.endTime = endTime[0] + ':' + endTime[1];
    }

    this.setExpDate();
    if (this.meeting.jitsiServerUrl) {
      this.startMeeting();
    } else {
      this.errorMsg = this.translation.instant('meetings.Error has been occurred!, Please contact Administator.');
    }
  }

  setExpDate(extend = false) {
    const date = new Date();
    // if (!extend) {
    //   date = new Date(this.meeting.startDate);
    // }
    date.setTime(date.getTime() + (Number(Config.NoOfHoursToEndMeeting) * 60 * 60 * 1000));
    this.expiryDate = Math.floor(date.getTime() / 1000);
  }
  /////////////////

  public get meetingToken(): string {
    const tokenPayload = {
      context: {
        user: {
          avatar: this.loginUser.profilePicture,
          name: this.loginUser.name,
          email: this.loginUser.email
        }
      },
      moderator: this.meeting.isOwner ? true : false,
      aud: 'jitsi',
      iss: 'expert-apps-jitsi',
      sub: '*',
      room: this.meeting.roomName,
      exp: this.expiryDate
    };

    const jitsiToken = this.generateToken(tokenPayload, 'M1dCabohXUytWfmVXx5n');
    console.log(jitsiToken);
    return jitsiToken;
  }

  startMeeting() {
    this.loading = true;
    this.jitsiContainer = document.querySelector('#jitsi-meet-conf-container');
    const domain = this.meeting.jitsiServerUrl;
    let options = {};
    if (this.meeting.isOwner) {
      options = {
        roomName: this.meeting.roomName,
        jwt: this.meetingToken,
        avatarUrl: this.loginUser.profilePicture,
        userInfo: {
          email: this.loginUser.email,
          displayName: this.loginUser.name,
        },
        parentNode: this.jitsiContainer,
        onload: () => {
          this.loading = false;
        }
      };
    } else {
      options = {
        roomName: this.meeting.roomName,
        jwt: this.meetingToken,
        displayName: this.loginUser.name,
        avatarUrl: this.loginUser.profilePicture,
        userInfo: {
          email: this.loginUser.email,
          displayName: this.loginUser.name,
        },
        parentNode: this.jitsiContainer,
        interfaceConfigOverwrite: {
          filmStripOnly: true,
          TOOLBAR_ALWAYS_VISIBLE: true,
          TOOLBAR_BUTTONS: ['help', 'select-background', 'toggle-camera', 'videoquality', 'camera', 'hangup',
            'microphone', 'settings', 'desktop', 'chat', 'fullscreen', 'raisehand', 'tileview'],
          SETTINGS_SECTIONS: ['devices'],
          OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron'],
          VERTICAL_FILMSTRIP: false,
          RECENT_LIST_ENABLED: false,
          DEFAULT_REMOTE_DISPLAY_NAME: 'Friend',
          DEFAULT_LOCAL_DISPLAY_NAME: 'Me',
          SHOW_JITSI_WATERMARK: false,
          APP_NAME: 'Jitsi call',
          NATIVE_APP_NAME: 'Jisti call',
          MOBILE_APP_PROMO: false
        },
        onload: () => {
          this.loading = false;
        }
      };
    }

    this.apiObj = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.apiObj.executeCommand('subject', this.meeting.meetingTitle);
    // this.apiObj.executeCommand('avatarUrl', this.loginUser.profilePicture);
    const self = this;
    if (this.meeting.setMeetingPassword) {
      if (self.meeting.isOwner) {
        this.apiObj.addEventListener('participantRoleChanged', (event) => {
          console.log(event.role);
          if (event.role === 'moderator') {
            self.apiObj.executeCommand('password', self.meeting.meetingId + self.meeting.roomName);
          }
        });
      }
      self.apiObj.on('passwordRequired', () => {
        self.apiObj.executeCommand('password', self.meeting.meetingId + self.meeting.roomName);
      });
    }
    self.apiObj.addListener('readyToClose', (event) => {
      if (self.meeting.isOwner) {
        self.detailsService.EndMeeting(self.meetingId).subscribe(
          data => {
            self.router.navigate(['admin/meetings/', self.meetingId]);
          },
          error => {
            console.log('Try again!, ' + error);
          });
      } else {
        self.router.navigate(['admin/meetings/', self.meetingId]);
      }
    });


    self.apiObj.addListener('errorOccurred', (data) => {
      console.log(data);
      if (data && data.error) {
        this.loading = false;
        if (!data.error.recoverable) {
          this.errorData = data;
          this.jitsiContainer.innerHTML = '';
          self.apiObj.dispose();
          if (data.error.type === 'CONNECTION') {
            this.errorMsg = this.translation.instant('meetings.Sorry!, Meeting time ended.');
          }
        }
      }
    });

    console.log(self.apiObj);
  }

  public get canReload(): boolean {
    return this.meeting.meetingType === 0 ? true : false;
  }

  extendMeeting() {
    this.errorMsg = null;
    this.setExpDate(true);
    this.startMeeting();
  }

}
