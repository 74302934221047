import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';
@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html'
})
export class SidebarLayoutComponent implements OnInit {

  constructor( private sidebarService :SidebarService) { }

  ngOnInit() { 
    
  }

}
