
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';

import { DemoMaterialModule } from 'src/app/helpers/material-modules';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';

import { ShortNamePipe } from '../helpers/shortName.pipe';
import { DndDirective } from 'src/app/helpers/dnd.directive';
import { SanitizeHtmlPipePipe } from 'src/app/helpers/sanitize-html-pipe.pipe';
import { OnlynumberDirective } from 'src/app/helpers/onlynumber.directive';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FilesViewerComponent } from './files-viewer/files-viewer.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MustMatchDirective } from 'src/app/helpers/must-match.directive';
import { AlertComponent } from './alert/alert.component';
import { ShowAuthedDirectiveDirective } from 'src/app/helpers/show-authed-directive.directive';
import { TimeAgoPipe } from 'time-ago-pipe';
import { TimeDiffPipe } from 'src/app/helpers/time-left.pipe';
import { MatNativeDateModule } from '@angular/material';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { ExportAsModule } from 'ngx-export-as';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { CalendarModule } from 'angular-calendar';
import { CreateEditTaskComponent } from './create-edit-task/create-edit-task.component';
import { MessageCommentsComponent } from './message-comments/message-comments.component';
import { CalenderViewComponent } from './calender-view/calender-view.component';
import { CalenderViewHeaderComponent } from './calender-view/calender-view-header/calender-view-header.component';
import { AgendaListComponent } from './agenda-list/agenda-list.component';
import { AgendaItemComponent } from './agenda-list/agenda-item/agenda-item.component';
import { AgendaFormComponent } from './agenda-list/agenda-item/agenda-form/agenda-form.component';
import { MeetingVoteComponent } from './agenda-list/meeting-vote/meeting-vote.component';
import { VotingTabsComponent } from './agenda-list/meeting-vote/voting-tabs/voting-tabs.component';
import { RouterModule } from '@angular/router';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AgoTimePipe } from '../helpers/ago-time.pipe';
import { JitsiMeetingComponent } from './jitsi-meeting/jitsi-meeting.component';
import { MultiUserJitsiComponent } from './multi-user-jitsi/multi-user-jitsi.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DownloadDirective } from '../helpers/download.directive';
import { Config } from '../models/config';
import { HeaderComponent } from '../layout/shared/header/header.component';
import { ViewMultiSlideComponent } from '../modules/meetings/meeting-details/slides-list/view-multi-slide/view-multi-slide.component';
import { SlidesListingViewComponent } from '../modules/meetings/meeting-details/slides-list/slides-listing-view/slides-listing-view.component';
import { CommentsComponent } from '../modules/slides-viewer/components/comments/comments.component';
import { ViewerDetectionResultComponent } from '../modules/slides-viewer/components/viewer-detection-result/viewer-detection-result.component';
import { ViewerImageAnalysisResultComponent } from '../modules/slides-viewer/components/viewer-image-analysis-result/viewer-image-analysis-result.component';
import { SlidesViewerComponent } from '../modules/slides-viewer/slides-viewer.component';
import { SanitizeSrcPipe } from '../helpers/sanitize-url-pipe.pipe';
import { StudyViewerComponent } from '../modules/meetings/meeting-details/study-list/study-viewer/study-viewer.component';
import { JsonParsePipe } from '../modules/slides-viewer/models/helpers/json-parse.pipe';
import { SignatureFormComponent } from './my-profile/signature-form/signature-form.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TreatmentFormComponent } from './agenda-list/agenda-item/treatment-plan/treatment-form/treatment-form.component';
import { TreatmentListComponent } from './agenda-list/agenda-item/treatment-plan/treatment-list/treatment-list.component';
import { CaseVotingComponent } from './agenda-list/agenda-item/case-voting/case-voting.component';
import { CaseRecommendationsComponent } from './agenda-list/agenda-item/case-recommendations/case-recommendations.component';
import { SurgeryComponent } from '../modules/meetings/agenda-case-details/surgery/surgery.component';
import { LabTestResultsComponent } from '../modules/meetings/agenda-case-details/lab-test-results/lab-test-results.component';
import { ClinicalVisitsComponent } from '../modules/meetings/agenda-case-details/clinical-visits/clinical-visits.component';
import { CaseRecommendationComponent } from '../modules/meetings/agenda-case-details/case-recommendation/case-recommendation.component';
import { ClinicalVisitFormComponent } from '../modules/meetings/agenda-case-details/clinical-visits/clinical-visit-form/clinical-visit-form.component';
import { LabTestFormComponent } from '../modules/meetings/agenda-case-details/lab-test-results/lab-test-form/lab-test-form.component';
import { SurgeryFormComponent } from '../modules/meetings/agenda-case-details/surgery/surgery-form/surgery-form.component';
import { TreatmentPlansComponent } from '../modules/meetings/agenda-case-details/treatment-plans/treatment-plans.component';
import { LinkSlideAgendaComponent } from '../modules/meetings/meeting-details/slides-list/link-slide-agenda/link-slide-agenda.component';
import { SlidesListComponent } from '../modules/meetings/meeting-details/slides-list/slides-list.component';
import { StudyListComponent } from '../modules/meetings/meeting-details/study-list/study-list.component';
import { UploadStudyComponent } from '../modules/meetings/meeting-details/study-list/upload-study/upload-study.component';
import { SlideFormComponent } from '../modules/meetings/meeting-details/slides-list/slide-form/slide-form.component';

export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  const lang = localStorage.getItem('language') || 'en';
  paginatorIntl.itemsPerPageLabel = (lang === 'en') ? 'Entries per page' : 'إدخالات لكل صفحة';
  paginatorIntl.nextPageLabel = (lang === 'en') ? 'Next page' : 'الصفحة التالية';
  paginatorIntl.previousPageLabel = (lang === 'en') ? 'Previous page' : 'الصفحة السابقة';
  paginatorIntl.firstPageLabel = (lang === 'en') ? 'First page' : 'الصفحة الاولي';
  paginatorIntl.lastPageLabel = (lang === 'en') ? 'Last page' : 'الصفحة الاخيرة';
  return paginatorIntl;
}

// function countdownConfigFactory(): CountdownGlobalConfig {
//   return;
// }
@NgModule({
  declarations: [
    DndDirective,
    OnlynumberDirective,
    ShortNamePipe,
    SanitizeHtmlPipePipe,
    SanitizeSrcPipe,
    FileManagerComponent,
    FilesViewerComponent,
    MustMatchDirective,
    DownloadDirective,
    AlertComponent,
    ShowAuthedDirectiveDirective,
    TimeAgoPipe,
    AgoTimePipe,
    TimeDiffPipe,
    HeaderComponent,
    CreateEditTaskComponent,
    MessageCommentsComponent,
    CalenderViewComponent,
    CalenderViewHeaderComponent,
    AgendaListComponent,
    AgendaItemComponent,
    AgendaFormComponent,
    MeetingVoteComponent,
    VotingTabsComponent,
    MyProfileComponent,
    JitsiMeetingComponent,
    MultiUserJitsiComponent,
    ViewMultiSlideComponent,
    SlidesListingViewComponent,
    SlidesViewerComponent,
    ViewerDetectionResultComponent,
    ViewerImageAnalysisResultComponent,
    CommentsComponent,
    StudyViewerComponent,
    JsonParsePipe,
    SignatureFormComponent,
    TreatmentFormComponent,
    TreatmentListComponent,
    CaseVotingComponent,
    CaseRecommendationsComponent,
    ClinicalVisitsComponent,
    LabTestResultsComponent,
    SurgeryComponent,
    ClinicalVisitFormComponent,
    LabTestFormComponent,
    SurgeryFormComponent,
    TreatmentPlansComponent,
    CaseRecommendationComponent,
    SlidesListComponent,
    LinkSlideAgendaComponent,
    SlideFormComponent,
    StudyListComponent,
    UploadStudyComponent,
  ],
  imports: [
    RecaptchaModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DemoMaterialModule,
    InfiniteScrollModule,
    TranslateModule.forChild({}),
    RouterModule,
    DemoMaterialModule,
    MatNativeDateModule,
    CKEditorModule,
    InfiniteScrollModule,
    OwlModule,
    // ExportAsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    // NgxMaterialTimepickerModule.setLocale(localStorage.getItem('language') == 'en' ? 'en-US':'ar-AE'),
    NgxMaterialTimepickerModule,
    CountdownModule,
    CalendarModule,
    SignaturePadModule
  ],
  exports: [
    HeaderComponent,
    RecaptchaModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MessageCommentsComponent,
    OwlModule,
    DemoMaterialModule,

    InfiniteScrollModule,
    TranslateModule,
    DndDirective,
    OnlynumberDirective,
    ShortNamePipe,
    SanitizeHtmlPipePipe,
    SanitizeSrcPipe,
    FileManagerComponent,
    FilesViewerComponent,
    MustMatchDirective,
    DownloadDirective,
    AlertComponent,
    ShowAuthedDirectiveDirective,
    TimeAgoPipe,
    TimeDiffPipe,
    AgoTimePipe,
    DemoMaterialModule,
    MatNativeDateModule,
    CKEditorModule,
    InfiniteScrollModule,
    OwlModule,
    // ExportAsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaterialTimepickerModule,
    CountdownModule,
    CalendarModule,
    CreateEditTaskComponent,
    CalenderViewComponent,
    CalenderViewHeaderComponent,
    AgendaListComponent,
    AgendaItemComponent,
    AgendaFormComponent,
    MeetingVoteComponent,
    VotingTabsComponent,
    MyProfileComponent,
    JitsiMeetingComponent,
    MultiUserJitsiComponent,
    ViewMultiSlideComponent,
    SlidesListingViewComponent,
    SlidesViewerComponent,
    ViewerDetectionResultComponent,
    ViewerImageAnalysisResultComponent,
    CommentsComponent,
    StudyViewerComponent,
    ClinicalVisitsComponent,
    LabTestResultsComponent,
    SurgeryComponent,
    ClinicalVisitFormComponent,
    LabTestFormComponent,
    SurgeryFormComponent,
    TreatmentPlansComponent,
    CaseRecommendationComponent,
    SlidesListComponent,
    LinkSlideAgendaComponent,
    SlideFormComponent,
    StudyListComponent,
    UploadStudyComponent,
    SignaturePadModule,
    SignatureFormComponent
  ],
  entryComponents: [TreatmentFormComponent],
  providers: [
    DatePipe,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: Config.googleSiteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
    { provide: CountdownGlobalConfig }, // , useFactory: countdownConfigFactory
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'en' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }

  ]
})

export class SharedModule { }
