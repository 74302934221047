import { get, forEach, isEqual } from 'lodash';

export const TitleWidget = (args: any) => {



  const purpose = 'title-purpose';
  const currentBody = args.annotation && args.annotation.bodies && args.annotation.bodies.length ?
    args.annotation.bodies[0] : null;
  const currentBodyComment = args.annotation ?
    args.annotation.bodies.find((b) => {
      return b.purpose == 'commenting';
    }) : null;

  const creator = currentBody ? currentBody.creator.id : null;

  // 1. Find a current color setting in the annotation, if any
  var currentTitleBody = args.annotation ?
    args.annotation.bodies.find((b: any) => {
      return b.purpose == purpose;
    }) : null;


  const isReadOnly = () => {
    if (args.editable === true)
      return false;

    if (args.editable === false)
      return true;

    if (args.editable === 'MINE_ONLY') {
      // The original creator of the body

      // The current user
      const me = args.user.id;

      return me !== creator
    }
    // Global setting as last possible option
    return args.readOnly;
  }
  // 2. Keep the value in a variable
  var currentTitleValue = currentTitleBody ? currentTitleBody.value : null;

  // 3. Triggers callbacks on user action
  var addTag = (evt: any) => {
    if (currentTitleBody) {
      args.onUpdateBody(currentTitleBody, {
        type: 'TextualBody',
        purpose: purpose,
        value: evt.target.value
      });
    } else {
      // console.log(currentBodyComment)
      // if (!currentBodyComment) {
      //   args.onAppendBody({
      //     type: 'TextualBody',
      //     purpose: 'commenting',
      //     value: ''
      //   });
      // }
      args.onAppendBody({
        type: 'TextualBody',
        purpose: purpose,
        value: evt.target.value
      });
    }
  }

  // 4. This part renders the UI elements
  var createInput = () => {
    var input = document.createElement('input');
    input.className = 'r6o-editable-text';
    input.placeholder = 'Add a title...';
    input.value = currentTitleValue;
    if (isReadOnly() && currentBody) {
      input.readOnly = isReadOnly();
      input.disabled = isReadOnly();
    }
    input.addEventListener('change', addTag);
    return input;
  }
  var createHeading = () => {
    var element = document.createElement('h6');
    element.className = 'mb-0';
    element.innerText = currentTitleValue;
    return element;
  }

  var container = document.createElement('div');
  if (creator || !currentBody) {
    container.className = 'r6o-widget comment editable';
    if (isReadOnly() && creator) {
      container.appendChild(createHeading());
    } else {
      container.appendChild(createInput());
    }
  }
  return container;
}
