import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { DICOMService } from 'src/app/services/DICOM.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-study',
  templateUrl: './upload-study.component.html',
  styleUrls: []
})
export class UploadStudyComponent implements OnInit {

  @Input() meetingId: any;
  @Input() agendaItemId: any;

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() study: any;

  loading = false;
  formGroup: FormGroup;
  isSubmited = false;

  files: any[] = []

  constructor(private service: DICOMService,
    private translate: TranslateService) { }

  ngOnInit() {
  }

  closePop() {
    this.cancelPop.emit();
  }

  save() {
    this.isSubmited = true;
    if (!this.files.length) { return; }
    let data = {
      meetingId: this.meetingId,
      agendaItemId: this.agendaItemId
    }
    this.loading = true;
    this.service.AddDicomFile(this.files, data).subscribe(
      data => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.addedsuccessfully'),
          text: '',
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok'),
        });
        this.closePopAndSave.emit();
      }, err => {
        Swal.fire({
          title: this.translate.instant('swalMsg.Failed'),
          text: this.translate.instant('messages.InvalidFile'),
          icon: 'error',
          confirmButtonText: this.translate.instant('swalMsg.ok')
        });
        this.loading = false;
      });
  }

}
