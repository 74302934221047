import { Component, Input, OnInit } from '@angular/core';
import { VotingView } from '../case-voting/Voting.models';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { Subscription } from 'rxjs';
import { CaseService } from 'src/app/services/Case.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';

@Component({
  selector: 'app-case-recommendations',
  templateUrl: './case-recommendations.component.html'
})
export class CaseRecommendationsComponent implements OnInit {
  @Input() item: AgendaItem;
  VotingView = VotingView;

  dataLoading = false;
  recordsData: any;
  listItem: string;
  recommendsDeleteId: number;
  itemLoading = false;

  sortSubscribe: Subscription;

  constructor(
    private service: CaseService,
    private signalRService: SignalRServiceService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getAll();
    this.signalRService.handleRecommendationPlaneEvent(this, this.updateList);

    this.service.refreshList.subscribe(data => {
      if (data && this.item.agendaItemEntityId) {
        this.getAll();
      }
    });
  }

  updateList(context, agendaItemId) {
    if (context.item.agendaItemEntityId === agendaItemId) {
      context.getAll();
    }
  }

  getAll() {
    this.dataLoading = true;
    this.service.AgendItemRecommendationList(this.item.agendaItemEntityId).subscribe(
      (resp: any) => {
        this.dataLoading = false;
        this.recordsData = resp;
      }, err => {
        console.error(err);
        this.dataLoading = false;
      });
  }

  addListItem(event) {
    event.preventDefault();
    if (this.listItem) {
      const obj = {
        agendaItemId: this.item.agendaItemEntityId,
        recommendation: this.listItem
      };
      this.itemLoading = true;
      this.service.AddAgendItemRecommendation(obj).subscribe(
        data => {
          this.itemLoading = false;
          this.listItem = undefined;
          this.getAll();
        },
        error => {
          console.log('Try again!, ' + error);
        });
    }
  }
  deleteRecord(id: number) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.recommendsDeleteId = id;
        this.service.DeleteAgendItemRecommendation(id).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.getAll();
          }, error => {
            console.error(error);
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text: this.translate.instant('swalMsg.FailedText'),
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
          });
      }
    });
  }

  // Treatment List
  removeTermsItem(index) {
    this.recordsData.pageData.splice(index, 1);
  }
  drop(event: CdkDragDrop<string[]>) {
    this.sortSubscribe ? this.sortSubscribe.unsubscribe() : '';
    moveItemInArray(this.recordsData.pageData, event.previousIndex, event.currentIndex);
    let data = {
      agendaItemId: this.item.agendaItemEntityId,
      sortData: this.recordsData.pageData.map((i, index) => {
        return {
          id: i.id,
          sortorder: index
        }
      })
    };
    this.sortSubscribe = this.service.SortAgendItemRecommendation(data).subscribe(
      data => {
        // this.getAll();
      }, error => {
        console.error(error);
        Swal.fire({
          title: this.translate.instant('swalMsg.Failed'),
          text: this.translate.instant('swalMsg.FailedText'),
          icon: 'error',
          confirmButtonText: this.translate.instant('swalMsg.ok')
        });
      });
  }
}
