import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VotingListData, VotingView } from 'src/app/partial/agenda-list/agenda-item/case-voting/Voting.models';
import { CaseService } from 'src/app/services/Case.service';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';

@Component({
  selector: 'app-case-recommendation',
  templateUrl: './case-recommendation.component.html'
})
export class CaseRecommendationComponent implements OnInit {
  @Input() meetingAgendaItemId: number;

  dataLoading = false;
  language = localStorage.getItem('language') || 'en';

  currentTabVal = 0;
  tabs = [
    { label: this.translate.instant('case.Attendees Votes'), value: 0 },
    { label: this.translate.instant('meetings.Summary'), value: 1 }
  ];
  
  votingListData: VotingListData;

  constructor(
    private service: CaseService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.listAllVotes();
  }


  listAllVotes() {
    this.dataLoading = true;
    this.service.listAllVotes(this.meetingAgendaItemId, VotingView.Recommend).subscribe(
      (response: VotingListData) => {
        this.dataLoading = false;
        this.votingListData = response;
      }, error => {
        console.log('Faild');
      });
  }

}
