import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SlidesService } from 'src/app/services/slides.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-slides-listing-view',
  templateUrl: './slides-listing-view.component.html'
})
export class SlidesListingViewComponent implements OnInit {
  @Input() liveView = false;
  @Input() agendaItemId: number;
  @Input() slides: any = [];
  @Output() slideUnlinked = new EventEmitter(null);
  openMultiSlideSelect = false;
  loading = false;

  notFound = this.translate.instant('general.N/A');
  
  slidesIds = [];

  constructor(
    private translate: TranslateService,
    private service: SlidesService
  ) { }

  ngOnInit() {
  }

  openSlidesViewer(slidesIds: number[]) {
    this.openMultiSlideSelect = false;
    this.service.openSlidesViewer.next(slidesIds);
  }

  unlinkSlide(slideId) {
    if (this.agendaItemId) {
      this.loading = true;
      let data = {
        agendaItemId: this.agendaItemId,
        slideId,
        isLinked: false
      }
      this.service.LinkUnLinkAgendaItemSlide(data).subscribe(
        data => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('swalMsg.unlinkedSuccessfully'),
            text: '',
            icon: 'success',
            confirmButtonText: this.translate.instant('swalMsg.ok'),
          });
          this.slideUnlinked.emit(slideId);
          this.service.refreshList.next(true);
        }, err => {
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: '',
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
          this.loading = false;
        });
    }
  }
}
