import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { DICOMService } from 'src/app/services/DICOM.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-study-list',
  templateUrl: './study-list.component.html',
  styleUrls: []
})
export class StudyListComponent implements OnInit {

  @Input() meeting: Meeting;
  @Input() meetingId!: any;
  @Input() meetingAgendaItemId!: any;
  @Input() liveView!: boolean;

  openUploadStudyForm = false;

  dataLoading = false;
  deleteLoading = false;
  studyList!: any;

  selectedStudy;
  isUpdate = false;

  isExpanded = false;
  constructor(private service: DICOMService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.GetMeetingDicomFiles();
    this.service.refreshList.subscribe(data => {
      if (data && this.meetingId) {
        this.GetMeetingDicomFiles();
      }
    });
  }
  openStudyViewer(studyId: string) {
    this.service.openStudyViewer.next(studyId);
  }

  GetMeetingDicomFiles() {
    this.dataLoading = true;
    this.service.GetMeetingDicomFiles(this.meetingId, this.meetingAgendaItemId).subscribe(
      resp => {
        this.dataLoading = false;
        this.studyList = resp as [];
      }, err => {
        console.error(err);
        this.dataLoading = false;
      });
  }

  DeleteMeetingDicomFile(studyUID: any, meetingId: number) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      this.deleteLoading = true;
      if (result.value) {
        this.service.DeleteMeetingDicomFile(studyUID, meetingId).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            }).then((result) => {
              this.deleteLoading = false;
              this.GetMeetingDicomFiles();
            });
          }, error => {
            console.error(error);
            this.deleteLoading = false;
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text:  this.translate.instant('swalMsg.FailedText'),
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
          });
      }
    });
  }

  expand(i, parent) {
    const target = document.querySelectorAll('#' + parent + ' .card-container').item(i);
    if (!target.classList.contains('active')) {
      this.removeActiveTab(parent);
      target.classList.add('active');
    } else {
      this.removeActiveTab(parent);
    }
  }

  removeActiveTab(target) {
    const elems = document.querySelectorAll('#' + target + ' .card-container.active');
    [].forEach.call(elems, (el) => {
      el.className = el.className.replace(/\bactive\b/, '');
    });
  }

}
