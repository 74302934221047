import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {
  CalendarEvent,
  CalendarViewPeriod,
  CalendarMonthViewBeforeRenderEvent,
  CalendarWeekViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent,
  CalendarView,
} from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';

import { MeetingsService } from 'src/app/services/meetings.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-calender-view',
  templateUrl: './calender-view.component.html'
})
export class CalenderViewComponent implements OnInit {

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  events: CalendarEvent[] = [
    {
      start: new Date(),
      title: 'An event',
    },
  ];

  activeDayIsOpen: boolean;

  period: CalendarViewPeriod;

  dataLoading = false;
  meetings: any;

  constructor(private router: Router,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private meetingsService: MeetingsService) { }

  ngOnInit() {
    this.getListMeetings();
  }

  beforeViewRender(
    event:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ) {
    this.period = event.period;
    this.cdr.detectChanges();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  getListMeetings() {
    this.dataLoading = true;
    this.meetingsService.getListMeetings().subscribe(
      result => {
        this.dataLoading = false;
        this.meetings = result;
        this.meetings.map(meeting => {
          meeting.start = new Date(meeting.startDate);
          meeting.title = meeting.meetingTitle;
        });
        this.events = this.meetings;
      }, err => {
        console.error(err);
      });
  }
  eventClicked({ event }) {
    this.router.navigate(['admin/meetings/', event.meetingId]);
  }

}
