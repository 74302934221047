import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotificationsSearchModel } from 'src/app/models/notification/NotificationsSearchModel';
import { Notification } from 'src/app/models/notification/Notification';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { VotingService } from 'src/app/services/voting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MessagesService } from 'src/app/services/messages.service';
import { EntityService } from 'src/app/services/entity.service';
import { TasksService } from 'src/app/services/tasks.service';
import { ManageGroupsService } from 'src/app/services/manage-groups.service';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  message = true;
  notificationNumber: number;
  currentUser: User = {
    name: '',
  } as User;
  language = localStorage.getItem('language') || 'en';
  isArabic = false;
  userMenu = false;
  openMenu = false;
  shouldShow = false;

  notifLoading = false;
  pageNumber = 0;
  pageSize = 10;
  pageCount: number;
  notificationMenu = false;
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0
  };
  notificationsList: Array<Notification> = [];
  NotificationsObject: NotificationsSearchModel = new NotificationsSearchModel();

  logoutLoading = false;

  constructor(
    public zone: NgZone,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private signalRService: SignalRServiceService,
    private sidebarService: SidebarService,
    private notificationsService: NotificationsService,
    private _snackBar: MatSnackBar,
    private votingService: VotingService,
    private messagesService: MessagesService,
    private entityService: EntityService,
    private tasksService: TasksService,
    private manageGroupService: ManageGroupsService,
    private detailsService: MeetingDetailsService,
    private MyprofileService: MyprofileService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    // this.hardReload();
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(this.language);

    if (this.language === 'en') { this.isArabic = false; }

    this.getNotifications(false);
  }

  ngOnInit() {
    this.authenticationService.getUserProfile();
    this.signalRService.handleChangeNotificationCountEvent(this, this.updateNotificationNumber);
    this.signalRService.handleRefreshUserPermissionsEvent(this, this.refreshToken);
    this.signalRService.handleLogOutInActiveUserEvent(this, this.logoutUser);
  }

  refreshToken(context) {
    context.authenticationService.RefreshToken();
  }
  logoutUser(context, logoutforedit) {
    Swal.fire({
      // title: 'Are you sure!',
      text: (logoutforedit == 1) ? context.translate.instant('swalMsg.sessionEnd') : context.translate.instant('swalMsg.deactivatedAccount'),
      icon: 'warning',
      showCloseButton: true,
      confirmButtonText: context.translate.instant('swalMsg.yes'),
    }).then(() => {
      context.logout();
    });
  }

  hardReload() {
    Swal.fire({
      // title: 'Are you sure!',
      text: this.translate.instant('swalMsg.attention please! there is a new version'),
      icon: 'warning',
      showCloseButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
    }).then((result) => {
      location.reload();
    });
  }

  refreshAllDetails(notificationType){
    if(notificationType == 1){
      this.messagesService.refreshDetails.next(true);
      this.messagesService.refreshListDetails.next(true);
    }
    if(notificationType == 2){
      this.entityService.refreshDetails.next(true);
    }
    if(notificationType == 3 || notificationType == 4){
      this.tasksService.refreshDetails.next(true);
    }

    if(notificationType == 7){
      this.manageGroupService.refreshDetails.next(true);
    }
    if(notificationType == 8 || notificationType == 9){
      this.detailsService.refreshDetails.next(true);
    }

    if(notificationType == 10){
      this.MyprofileService.refreshDetails.next(true);
    }

    if(notificationType == 11){
      this.votingService.refreshDetails.next(true);
    }
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, 'Dismiss', { duration: 10000, horizontalPosition: 'end' });
  }

  updateNotificationNumber(context, notificationNumber) {
    context.zone.run(() => {
      context.notificationNumber = notificationNumber;
      // context.notificationsResult.unSeenNotificationsCount += 1;
      context.getNotifications(true);
      // context.openSnackBar('You have a new notification!');
    });
  }

  changeMessage(message: boolean) {
    this.sidebarService.messageSource.next(message);
  }
  toggleOpenClass() {
    this.openMenu = !this.openMenu;
    this.changeMessage(this.message);
  }
  logout() {
    this.logoutLoading = true;
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }
  toggleSearchBar() {
    this.shouldShow = !this.shouldShow;
  }
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  changeLanguage() {
    if (this.language === 'en') {
      // this.language = 'ar';
      localStorage.setItem('language', 'ar');
      // this.translate.use('ar');
    } else {
      // this.language = 'en';
      localStorage.setItem('language', 'en');
      // this.translate.use('en');
    }
    // setTimeout(() => {
    //   this.authenticationService.getUserProfile();
    // }, 300);

    window.location.reload();
    return;
    let lang = localStorage.getItem('language');

    let str = './';
    if (location.href.indexOf('localhost') === -1) {
      str = './';
    }

    let style = str + 'assets/css/en-US/style.css';
    let dir = 'ltr';
    if (lang === 'ar') {
      style = str + 'assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    document.getElementById('mainStyleLink').setAttribute('href', style);
    document.getElementById('html').setAttribute('lang', lang);
    document.getElementById('html').setAttribute('dir', dir);
  }

  openNotificationPanel() {
    this.notificationMenu = !this.notificationMenu;
    this.userMenu = false;
    this.updateNotificationsSeenStatus();
  }
  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getNotifications(false);
    }
  }
  getNotifications(update) {
    if (update) {
      this.pageNumber = 0;
      this.notificationsList = [];
    }
    this.notifLoading = true;
    this.NotificationsObject.pageSize = this.pageSize;
    this.NotificationsObject.pageNumber = this.pageNumber;
    this.notificationsService.GetNotifications(this.NotificationsObject).subscribe(
      result => {
        this.notifLoading = false;
        this.notificationsResult = result;
        this.pageCount = Math.ceil(this.notificationsResult.totalCount / this.pageSize);
        this.notificationsList = this.notificationsList.concat(this.notificationsResult.pageData);
      }, err => {
        this.notifLoading = false;
        console.error(err);
      });
  }
  updateNotificationsSeenStatus() {
    this.notificationsService.UpdateNotificationsSeenStatus().subscribe(
      result => {
        this.notificationsResult.unSeenNotificationsCount = 0;
      }, err => {
        console.error(err);
      });
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeOpenedDrop();
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.closeOpenedDrop();
    }
  }
  closeOpenedDrop() {
    this.userMenu = false;
    this.openMenu = false;
    this.shouldShow = false;
    this.notificationMenu = false;
  }
}
