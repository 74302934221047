import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private http: HttpClient) { }

  GetUsers(text) {
    let str = '';
    if (text) { str = '?text=' + text; }
    return this.http.get(`${Config.apiUrl}/messages/GetUsersInSameGroupAndEntity` + str);
  }

  SendChatMessage(toUserId , text) {
    return this.http.post(`${Config.apiUrl}/Chat/SendChatMessage`, {toUserId , text});
  }

  ListChatMessages(userId) {
    return this.http.get(`${Config.apiUrl}/Chat/ListChatMessages?userId=` + userId);
  }

  SeeConversation(userId) {
    return this.http.get(`${Config.apiUrl}/Chat/SeeConversation?userId=` + userId);
  }

  GetMissedConversations(): any {
    return this.http.get(`${Config.apiUrl}/Chat/GetMissedConversations`);
  }
}
