import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { User } from '../models/user/user';
import { RegisterModel } from '../models/auth/register-model';
import { Config } from '../models/config';
import { RegisterIntent } from '../Models/auth/RegisterIntent';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public authUser: any;

    menuItems = new BehaviorSubject<any[]>([]);
    constructor(
      private http: HttpClient,
      private router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        // window.addEventListener('storage', () => {
        //   const LS = JSON.parse(localStorage.getItem('currentUser'));
        //   if ( // This condition to prevent infinit loading
        //     (this.currentUserValue && localStorage.getItem('currentUser') && (this.currentUserValue.email !== LS.email))
        //     || (this.currentUserValue && !localStorage.getItem('currentUser'))
        //     || (!this.currentUserValue && localStorage.getItem('currentUser'))
        //     ) {
        //     window.location.reload();
        //   }
        // }, false);
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username, password, rememberMe) {
        // const headers = new HttpHeaders({
        //     'Content-Type': 'application/x-www-form-urlencoded'
        // });
        // tslint:disable-next-line: max-line-length
        return this.http.post<any>(`${Config.loginUrl}`, { Username: username, Password: password })
            .pipe(map(user => {
                // debugger;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('currentUser', JSON.stringify(user));
                user.rememberMe = rememberMe;
                console.log(user);
                user.access_token = user.token;
                this.currentUserSubject.next(user);
                // console.log(user);
                this.authUser = user;
                this.getUserProfile();
                return user;
            }));
    }
    getUserProfile() {
        let user = this.currentUserValue;
        this.http.get(`${Config.apiUrl}/accounts/UserProfile`).pipe(first())
            .subscribe((data: any) => {
                user.email = data.email;
                user.username = data.userName;
                user.name = data.name;
                user.role = data.role;
                user.permissions = data.permissions;
                user.profilePicture = data.profilePicture;
                user.userAvailability = data.userAvailability;
                user.refreshToken = data.refreshToken ? data.refreshToken : this.currentUserValue.refreshToken;
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                //  setTimeout(() => {
                //   location.reload();
                // }, 100);
                return user; // leh bt3ml return ?!
            },
                error => {
                    // debugger;
                });
    }
    RefreshToken() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        this.http.post(`${Config.apiUrl}/Authenticate/RefreshToken`, 'refreshToken=' + this.currentUserValue.refreshToken, { headers })
          .pipe().subscribe((data: any) => {
            this.currentUserValue.access_token = data.token;
            this.currentUserValue.refreshToken = data.refreshToken;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
            location.reload();
          });
    }
    RefreshAtoken(refreshToken) {
      // let body = new URLSearchParams();
      // body.set('refreshToken', refreshToken);
      let body = `refreshToken=${refreshToken}`;
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      return this.http.post(`${Config.apiUrl}/Authenticate/RefreshToken?${body}`, '', { headers });
    }
    checkEmail(email, userId) {
        return this.http.get(`${Config.apiUrl}/accounts/ValidateEmail?filterKey=` + email + '&userId=' + userId);
    }
    checkUsername(username) {
        return this.http.get(`${Config.apiUrl}/accounts/ValidateUserName?filterKey=` + username);
    }
    checkResetPasswordLink(userId, code) {
        return this.http.post(`${Config.apiUrl}/accounts/VerifyToken`, { userId, token: code });
    }
    register(user: RegisterModel, isWebsite = false) {
      const headers = new HttpHeaders({
        RegisterIntent : (isWebsite)? String(RegisterIntent.Subscribe) : String(RegisterIntent.Register)
      });
      return this.http.post(`${Config.apiUrl}/accounts/create`, user, { headers });
    }
    forgetPassword(email) {
        return this.http.get(`${Config.apiUrl}/accounts/ForgetPassword?email=` + email);
    }
    resetPassword(userId, code, newPassword) {
        return this.http.post(`${Config.apiUrl}/accounts/ResetPassword`, { userId, token: code, newPassword });
    }

    RequestFirstTimePassword(identityNumber) {
      return this.http.get(`${Config.apiUrl}/accounts/RequestFirstTimePassword?identityNumber=` + identityNumber);
    }

    CreateForgetPasswordLink(password) {
      return this.http.get(`${Config.apiUrl}/accounts/CreateForgetPasswordLink?password=` + password, { responseType: 'text' });
    }

    logout() {
      this.http.post(`${Config.apiUrl}/Authenticate/logout`, null).pipe()
        .subscribe((data: any) => {
          // remove user from local storage and set current user to null
          this.clearUserSession();
        },
        err => {
          this.clearUserSession();
        });
    }
    clearUserSession() {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('activeChats');
      localStorage.removeItem('menu');
      this.currentUserSubject.next(null);
      this.router.navigate(['/login']);
    }
    confrimEmail(userId, code) {
        return this.http.get(`${Config.apiUrl}/accounts/ConfirmEmail?userId=` + userId + '&code=' + encodeURIComponent(code));
    }
    CheckIsVisitor(): any {
        return this.http.post(`${Config.apiUrl}/Authenticate/CheckIsVisitor`, {});
    }
}
