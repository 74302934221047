import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUserValue;
        if (!request.url.includes('assets/i18n')) {
            if (currentUser && currentUser.access_token) {
                request = request.clone({
                    setHeaders: {
                        lang: localStorage.getItem('language') || 'en',
                        Authorization: `Bearer ${currentUser.access_token}`
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: { lang: localStorage.getItem('language') || 'en' }
                });
            }
        }
        // this.dateReplacer(request.body);
        return next.handle(request);
    }
    dateReplacer(obj) {
        if (obj) {
            for (const k in obj) {
                if (obj.hasOwnProperty(k)) {
                    if (obj[k] instanceof Object) {
                        obj[k] = this.dateReplacer(obj[k]);
                    }
                    if (obj[k] instanceof Date) {
                        obj[k] = new Date(`${obj[k]} UTC`);
                    }
                }
            }
            return obj;
        }
    }
}
