import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clinical-visits',
  templateUrl: './clinical-visits.component.html'
})
export class ClinicalVisitsComponent implements OnInit {

  clinicalList!: any;
  totalCount: number;
  @Input() meetingAgendaItemId!: any;
  @Input() meetingId!: any;
  @Input() liveView!: boolean;
  dataLoading = false;
  @Output() refreshDetails: EventEmitter<any> = new EventEmitter();

  openForm = false;
  selectedClinicalVisit: any;
  clinicalVisitId: any;

  // enum clinic, lab and surgery
  constructor(private meetingService: MeetingDetailsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getClinicalVisits();
    this.meetingService.refreshSlides.subscribe(data => {
      if (data) {
        this.getClinicalVisits();
      }
    });
  }

  getClinicalVisits() {
    this.dataLoading = true;
    let filter = {
      pageNumber: 0,
      pageSize: 200,
      meetingAgendaItemId: this.meetingAgendaItemId
    }
    this.meetingService.getClinicalVisits(filter).subscribe((data: any) => {
      this.clinicalList = data;
      this.totalCount = data.totalCount;
      this.dataLoading = false;
    })
  }

  deleteClinicalVisit(id) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.meetingService.DeleteClinicalVisits(id).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.meetingService.refreshSlides.next(true);
          },
          error => {
            console.log('Try again!, ' + error);
          });
      }
    });
  }

  openClinicalVisitForm(item) {
    this.openForm = true;
    this.selectedClinicalVisit = item;
    this.clinicalVisitId = item.id;
  }

  saveItemSucsses() {
    this.openForm = false;
    Swal.fire({
      title: this.translate.instant('swalMsg.savedsuccessfully'),
      text: '',
      icon: 'success',
      confirmButtonText: this.translate.instant('swalMsg.ok'),
    }).then((result) => {
      this.getClinicalVisits();
    });
  }

}
