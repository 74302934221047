import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ViewerSlide } from '../../slides-viewer.component';
import { AppType } from '../../models/enums/AppType';

@Component({
  selector: 'app-viewer-image-analysis-result',
  templateUrl: './viewer-image-analysis-result.component.html'
})
export class ViewerImageAnalysisResultComponent implements OnInit, OnChanges {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() showDetectionResult: boolean;
  @Output() showDetection = new EventEmitter<boolean>();
  @Output() cellTypesCountingEvent = new EventEmitter<boolean>();
  @Output() selectAnnEvent = new EventEmitter<string>();
  @Input() slide: ViewerSlide;
  @Input() scrollToId: any;
  selectedFeedbackIndex = 0;
  total = 0;
  dataLoading = false;
  recordsData: any[];
  @Input() allFeedbacks = [];
  @Output() zoomEvent = new EventEmitter<boolean>();
  detectionLoader = false;
  selectedAnno: string;

  constructor() { }

  ngOnInit() {
    if (this.slide.allResultsAi) {
      this.getVisioPharmResult();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    if (changes && changes.slide && changes.slide.currentValue) {
      this.allFeedbacks = this.slide.allFeedbacks;
    } else if (changes && changes.allFeedbacks && changes.allFeedbacks.currentValue) {
      this.getVisioPharmResult();
    }
  }

  getVisioPharmResult() {
    this.dataLoading = true;
    setTimeout(() => {
      if (this.slide.details.aiCurrentApp === AppType.VISIOPHARM) {
        this.recordsData = this.slide.allResultsAi.serializedResultList;
        if (this.recordsData && this.recordsData.length) {
          if (!event) {
            this.selectedFeedbackIndex = this.recordsData.length - 1;
          }
          this.allFeedbacks = this.allFeedbacks.filter(item => this.recordsData.some(record => record.polygonID == item.id.replace('#', '')));
        }
      } else if (this.slide.details.aiCurrentApp === AppType.QUPATH) {
        this.recordsData = this.slide.allResultsAi.annotaionResultSummary;
        if (this.recordsData && this.recordsData.length) {
          this.allFeedbacks = this.allFeedbacks.filter(item => this.recordsData.some(record => record.find(obj => obj.value == item.id.replace('#', ''))));
          this.setSelectedIndex(this.allFeedbacks[0].id);
        }
      } else {
        this.recordsData = this.slide.allResultsAi.cellCounts;
        this.total = this.slide.allResultsAi.cellCounts.reduce((n, { value }) => n + value, 0);
      }
      this.dataLoading = false;
    }, 10);
  }

  closeResult() {
    this.closeEvent.emit(true);
  }

  showDetectionResults() {
    this.showDetection.emit(!this.showDetectionResult);
  }

  showCellCounting() {
    this.cellTypesCountingEvent.emit(true);
  }

  changeActiveFeedback(e, id: string) {
    this.setSelectedIndex(id);
    this.selectAnnEvent.emit(id);
  }

  setSelectedIndex(id: string) {
    if (id) {
      this.selectedAnno = id;
      if (this.slide.details.aiCurrentApp === AppType.VISIOPHARM) {
        this.selectedFeedbackIndex = this.recordsData.findIndex(item => item.polygonID === id.replace('#', ''));
      } else {
        this.selectedFeedbackIndex = this.recordsData.findIndex(record => record.find(obj => obj.value == id.replace('#', '')))
      }

    } else {
      this.selectedAnno = null;
      this.selectedFeedbackIndex = this.recordsData.length - 1;
    }
  }

  zoomToDetection(objectTd) {
    this.zoomEvent.emit(objectTd);
  }

  getCurrentTitleBody(body) {
    return body && body.find((b: any) => { return b.purpose == 'title-purpose'; }) && body.find((b: any) => { return b.purpose == 'title-purpose'; }).value.trim() ? body.find((b: any) => { return b.purpose == 'title-purpose'; }) : null;
  }

}
