import { Component, ElementRef, HostListener, OnInit, Input, QueryList, ViewChild, ViewChildren, Output, EventEmitter } from '@angular/core';
import OpenSeadragon from 'openseadragon';
import * as Annotorious from '@recogito/annotorious-openseadragon';
import * as selectorPack from 'src/app/modules/slides-viewer/js/annotorious-selector-pack.min.js';
import { Feedback, Slide } from './models/Slide';
import { SlideResult } from './models/SlideResult';
import { CaseType } from './models/CaseType';
import { ViewerService } from './services/Viewer.service';
import { Color, Solver } from './models/helpers/FilterRgb';
import { ActivatedRoute } from '@angular/router';
import { AppType } from './models/enums/AppType';
import { PolygonType } from './models/enums/PolygonType';
import { QupathDetection } from './models/QupathDetection';
import { saveAs } from 'file-saver';
import { ListFilterResult } from './models/ListFilterResult';
import { AppCode } from './models/enums/AppCode';
import html2canvas from 'html2canvas';
import { ColorSelectorWidget } from './models/helpers/widgets/color-selector';
import { TitleWidget } from './models/helpers/widgets/title-widget';
declare var initOverlay: any;
declare var fabric: any;

export class ViewerSlide {
  id: number;
  isActive: boolean;
  details: Slide;
  index: number;
  viewer: OpenSeadragon.viewer;
  overlay: any;
  imageZoom = 0;
  imageDefaultZoom = 0;
  viewrOptions = {
    id: 'openseadragon',
    tileSources: null,
    prefixUrl: null,
    maxZoomLevel: 0,
    minZoomImageRatio: 1,
    maxZoomPixelRatio: 0,
    maxZoomImageRatio: 1,
    minZoomPixelRatio: 0,
    // minZoomLevel: 0,
    zoomPerScroll: 2,
    // minPixelRatio: 0,
    visibilityRatio: 1,
    crossOriginPolicy: 'Anonymous',
    showNavigator: true,
    navigatorId: 'navigator-container',
    zoomInButton: 'zoomInButton',
    zoomOutButton: 'zoomOutButton',
    homeButton: 'homeButton',
    fullPageButton: 'fullPageButton',
    debugGridColor: 'white',
    showRotationControl: true,
    gestureSettingsTouch: {
      pinchRotate: true
    },
    rotateLeftButton: 'rotateLeftButton',
    rotateRightButton: 'rotateRightButton'
  };
  annotorious: Annotorious;
  selectedAnno: string;
  drawTool: string;
  opacityValue = 10;
  brightness = 100;
  redColor = 0;
  greenColor = 0;
  blueColor = 0;
  allFeedbacks = [];
  allResultsAi: SlideResult;
  viewerEnabled = false;
  zoomlevelSelected = 0;
  pluginOSD;

  annoCells = [];
  clipPaths = [];
  scrollToId = null;
  filterModel: ListFilterResult;
  hiddenAnnotations = [];
  loading = false;
}
@Component({
  selector: 'app-slides-viewer',
  templateUrl: './slides-viewer.component.html',
  styleUrls: ['./slides-viewer.component.scss']
})
export class SlidesViewerComponent implements OnInit {
  @Output() closeViewer = new EventEmitter();
  @Input() view = 'meeting';
  title = 'Viewr';
  dataLoading = true;
  loading = false;

  caseId: number;
  pathologist: any;
  pathologistId: number;
  pathologistType: string;
  requestId: number;
  caseDetails: any;

  IsEndorsement: string;

  allFeedbacks = []; // passed to viewer
  feedbackFilterBy: any;

  slideId: number;
  slideDetails: Slide;

  isMultiViewer = false;
  @Input() slidesIds = [];
  slides: ViewerSlide[] = [];
  activeSlide: ViewerSlide = new ViewerSlide();
  @ViewChildren('dragonViewer') dragonViewer: QueryList<ElementRef>;
  @ViewChild('comments', { static: true }) comments: ElementRef;
  @ViewChild('remarks', { static: true }) remarks: ElementRef;
  @ViewChild('sideLeft', { static: true }) sideLeft: ElementRef;

  viewer: OpenSeadragon.viewer;
  viewrOptions = {
    id: 'openseadragon',
    tileSources: null,
    visibilityRatio: 1,
    crossOriginPolicy: 'Anonymous',
    showNavigator: true,
    navigatorId: 'navigator-container',
    zoomInButton: 'zoomInButton',
    zoomOutButton: 'zoomOutButton',
    homeButton: 'homeButton',
    fullPageButton: 'fullPageButton',
    debugGridColor: 'white',
    showRotationControl: true,
    gestureSettingsTouch: {
      pinchRotate: true
    },
    rotateLeftButton: 'rotateLeftButton',
    rotateRightButton: 'rotateRightButton',
  };

  // Annotorious Config
  annotorious: Annotorious;
  selectedAnno: string;
  drawTool: string;
  opacityValue = 4;
  brightness = 100;

  // Paging Config
  // currentImageIndex = 0;
  // pageIndex = 0;
  // modeNames = [
  //   'thumbs',
  //   'scroll',
  //   'book',
  //   'page'
  // ];

  viewerEnabled = false;
  enableWithdrawRequest = false;
  enableWithdrawRequestEndorsment = false;
  enableEditingComments = false;
  enableEditingFeedback = false;
  enableEditingReport = false;
  enableSubmitUpdatesDetails = false;
  enableDeclineApprove = false;
  enableSendbackRequest = false;

  slideToken: string;
  anonymous: boolean;
  uploadSlides: boolean;
  enableEditingUpdates: boolean;
  caseIdType = CaseType.Case;
  zoomLevelsList = [
    { name: 'Reset', tooltip: 'Reset Zoom', value: 0 },
    { name: '2x', tooltip: 'Zoom 2x', value: 2 },
    { name: '5x', tooltip: 'Zoom 5x', value: 5 },
    { name: '10x', tooltip: 'Zoom 10x', value: 10 },
    { name: '20x', tooltip: 'Zoom 20x', value: 20 },
    { name: '40x', tooltip: 'Zoom 40x', value: 40 },
    { name: '63x', tooltip: 'Zoom 63x', value: 63 },
    { name: '100x', tooltip: 'Zoom 100x', value: 100 }
  ];
  zoomlevelAll = 0;
  openSideNav: boolean;
  fireToken = null;
  showSidebarRight = true;
  showSidebarLeft = false;
  showResults = false;
  showcellTypesCounting = false;
  showDetectionResults = false;

  constructor(
    private service: ViewerService,
    private activatedRoute: ActivatedRoute,

    private elementRef: ElementRef,
  ) {
    initOverlay(OpenSeadragon);
    document.body.classList.add('fixed-content');
  }
  ngOnDestroy() {
    document.body.classList.remove('fixed-content');
  }

  ngOnInit() {
    // if (localStorage.getItem('language') == 'ar') {
    //   localStorage.setItem('language', 'en');
    //   window.location.reload()
    // }
    // this.activatedRoute.queryParams.subscribe(query => {
    //   if (query.slide) {
    //     this.slidesIds = [];
    //     if (Array.isArray(query.slide)) {
    //       this.isMultiViewer = true;
    //       this.slidesIds = query.slide.splice(0, 4);
    //     } else {
    //       this.slidesIds = [query.slide];
    //     }
    //     this.handleViewerSlides();
    //   }
    // });
    if (this.slidesIds.length > 1) {
      this.isMultiViewer = true;
    }
    this.handleViewerSlides();
  }

  handleViewerSlides(event = false) {
    if (this.slides.length === 0) {
      this.slides = [];
      this.slidesIds.forEach((id, index) => {
        const slide = new ViewerSlide();
        slide.id = Number(id);
        slide.index = Number(index);
        this.slides.push(slide);
      });
    }
    this.slides.forEach(slide => {
      this.getSlideDetails(true, false, slide);
    });

    this.activeSlide = this.slides[0];
    this.activeSlide.isActive = true;
  }
  getSlideDetails(showResult = false, disabledReload = false, slide: ViewerSlide = this.activeSlide): void {
    let pathologistId = null;
    let pathologisType = null;
    if (this.IsEndorsement) {
      pathologistId = this.pathologist.primaryPathologist.userId;
      pathologisType = this.pathologist.primaryPathologist.userType;
    } else if (this.pathologist) {
      pathologistId = this.pathologist.id;
      pathologisType = this.pathologist.type;
    } else if (this.pathologistId) {
      pathologistId = this.pathologistId;
      pathologisType = '';
    }
    if (!disabledReload && !slide.viewer) {
      this.dataLoading = true;
    }
    let params: any = {
      SlideID: slide.id,
      // CaseId: this.caseId,
      // CaseIdType: this.caseIdType,
    }
    if (this.requestId) {
      params.RequestId = this.requestId;
    }
    if (this.IsEndorsement) {
      params.EndorsementRquestedId = this.pathologistId;
      params.EndorsementRquestedType = this.pathologistType;
      params.IsEndorsement = this.IsEndorsement;
    }
    if (pathologistId) {
      params.PathologistId = pathologistId,
        params.UserType = pathologisType
    }
    this.service.GetSlideView(params).subscribe(data => {
      // this.removeMeasurements(data, slide.index);
      slide.opacityValue = 10;
      slide.brightness = 100;
      slide.details = data;
      if (slide.viewer) {
        this.stopDraw(slide);
      }
      // this.goToZoomLevel(0, slide);
      // this.goToZoomLevel(0, null);
      // if (slide.drawTool) {
      //   slide.annotorious.setDrawingTool(slide.drawTool === 'rect2' ? 'rect' : slide.drawTool);
      //   slide.annotorious.setDrawingEnabled(true);
      // }
      if (slide.isActive) {
        this.activateSlide(slide);
      }
      if (slide.details.tilesFolderName) {
        // this.enableViewer(slide);
        if (slide.details && slide.details.aiCurrentApp) {
          this.getAIResult(showResult, slide);
        } else {
          this.getFeadbacks(null, slide);
          slide.allResultsAi = null;
          if (slide.overlay) {
            slide.overlay.fabricCanvas().clear();
          }
          this.dataLoading = false;
        }
      } else {
        this.dataLoading = false;
      }
    });
  }

  getAIResult(showResult, slide: ViewerSlide = this.activeSlide, filter: ListFilterResult = null) {
    slide.annoCells = [];
    slide.clipPaths = [];
    let polygons = null;
    let query = {
      slideId: slide.id
    } as ListFilterResult;
    let aiCurrentApp = slide.details && slide.details.aiCurrentApp ? slide.details.aiCurrentApp : null;
    if (filter) {
      if (filter.AppCode) {
        aiCurrentApp = filter.AppCode == AppCode.QUPATH_CELL_DETECTION || filter.AppCode == AppCode.QUPATH_POSITIVE_CELL_DETECTION || filter.AppCode == AppCode.QUPATH_MARROW_QUANT ? AppType.QUPATH : AppType.VISIOPHARM;
      }
      if (filter.Rois && aiCurrentApp === AppType.VISIOPHARM) {
        filter.RoisIDs = filter.Rois.map(item => String(item.id));
      } else if (filter.Rois) {
        filter.currentAnnotations = filter.Rois.map(item => item.id).join(',');
      }
      slide.filterModel = filter;
      query = { slideId: slide.id, ...filter };
    } else {
      if (slide.details && slide.details.currentDetectionAnnotations && slide.details.currentDetectionAnnotations.length) {
        if (aiCurrentApp === AppType.VISIOPHARM) {
          query.RoisIDs = slide.details.currentDetectionAnnotations.map(item => item.id.replace('#', ''));
        } else {
          query.currentAnnotations = slide.details.currentDetectionAnnotations.map(item => item.id.replace('#', '')).join(',');
        }
        if (slide.details.aiAppDetails) {
          query.AppId = slide.details.aiAppDetails.id;
        }
      }
      slide.filterModel = null;
    }
    slide.details.aiCurrentApp = aiCurrentApp;
    delete query.Rois;
    if (slide.overlay) {
      slide.overlay.fabricCanvas().clear();
    }
    this.service.getResult(query, aiCurrentApp).subscribe(
      (data: any) => {
        slide.allResultsAi = data as SlideResult;
        if (aiCurrentApp === AppType.VISIOPHARM && slide.allResultsAi && slide.allResultsAi.polygons) {
          polygons = JSON.parse(slide.allResultsAi.polygons) as [];
          if (polygons.length) {
            // console.log(polygons)
            polygons.map((item: any) => {
              let stroke = 'transparent';
              let fill = 'transparent';
              let globalCompositeOperation = 'source-over';
              if (item.PolygonType === PolygonType.LABEL) {
                fill = `rgba(${item.RGB},1)`;
              } else {
                stroke = `rgba(${item.RGB},1)`;
              }

              if (item.RGB === '0,0,0') {
                globalCompositeOperation = 'destination-out';
              }
              slide.annoCells.push(this.addPolylineFabric(aiCurrentApp, slide, item.Points, fill, stroke, null, globalCompositeOperation, 1));
            })
          }
        } else if (slide.allResultsAi && slide.allResultsAi.detectionResult) {
          polygons = JSON.parse(slide.allResultsAi.detectionResult) as any;
          if (polygons.features && polygons.features.length) {
            // console.log(polygons.features)
            polygons.features.map((item: QupathDetection) => {
              let stroke = 'transparent';
              let fill = 'transparent';
              if (item.properties.classification) {
                stroke = `rgba(${item.properties.classification.color.join(',')},1)`;
                // fill = `rgba(${item.properties.classification.color.join(',')},1)`;
              } else {
                stroke = `rgba(255,0,0,1)`;
              }
              if (item.properties.objectType !== PolygonType.annotation) {
                if (item.geometry.coordinates.length) {
                  if (item.geometry.type === PolygonType.MultiPolygon) {
                    item.geometry.coordinates.forEach(coordinat => {
                      coordinat.forEach((record, coordinatIndex) => {
                        const id = coordinatIndex != 0 ? item.id + coordinatIndex : item.id;
                        slide.annoCells.push(this.addAnnotation(this.addPolyline(aiCurrentApp, slide, record, fill, stroke, id), id));
                      })
                    })
                  } else {
                    // console.log(item.geometry.coordinates[0])
                    if (!item.nucleusGeometry || !item.nucleusGeometry.coordinates || !item.nucleusGeometry.coordinates.length) {
                      slide.annoCells.push(this.addAnnotation(this.addPolyline(aiCurrentApp, slide, item.geometry.coordinates[0], fill, stroke, item.id), item.id));
                    }
                  }
                }
                if (item.nucleusGeometry && item.nucleusGeometry.coordinates && item.nucleusGeometry.coordinates.length) {
                  slide.annoCells.push(this.addAnnotation(this.addPolyline(aiCurrentApp, slide, [...item.nucleusGeometry.coordinates[0], ...item.geometry.coordinates[0]], fill, stroke, item.id), item.id));
                  // if (item.id == '8995c666-d8c4-4080-84ad-003ab9b13d15') {
                  //   console.log(item.nucleusGeometry.coordinates[0]);
                  //   console.log(item.geometry.coordinates[0]);
                  // }
                }
              }
            })

            // canvas.add(group);
          }
        }
        // console.log(newPolygons)
        // slide.annotorious.clearAnnotations();

        // const group = new fabric.Group(newPolygons);
        // slide.overlay.fabricCanvas().add(group);
        // console.log(slide.overlay.fabricCanvas());
        // slide.viewer.addHandler('zoom', (e) => {
        //   const zoom = e.zoom;
        //   const objects = overlay.fabricCanvas().getObjects()[0].getObjects();
        //   const factor = 1 / Math.round((zoom / slide.viewer.viewport.getMaxZoom()) * 100); // Adjust this value as needed

        //   console.log(Math.round((zoom / slide.viewer.viewport.getMaxZoom()) * 100))
        //   objects.forEach((obj) => {
        //     const strokeWidth = obj.strokeWidth * factor;
        //     obj.set('strokeWidth', strokeWidth);
        //   });

        //   overlay.fabricCanvas().renderAll();
        // });
        if (showResult && slide.details.aiAppDetails && slide.details.aiAppDetails.code !== AppCode.VISIO_10182_IHC_Tissue_Detection_AI) {
          this.showResults = true;
        }
        if (!filter) {
          this.getFeadbacks(null, slide);
        } else {
          this.setSlideAnnotations(slide);
        }
        this.dataLoading = false;
      }, err => {
        this.getFeadbacks(null, slide);
        this.dataLoading = false;
        console.error(err);
      }
    );
  }

  initViewer(slide: ViewerSlide = this.activeSlide): void {
    this.loading = false;
    if (slide.viewer) {
      // slide.viewer.open(this.tileSourceFromData(slide.details));
      // slide.annotorious.setAnnotations(slide.allFeedbacks);
      slide.annotorious.clearAnnotations();
      slide.annotorious.destroy();
      this.initAnn(slide);
    } else {
      slide.viewrOptions.id = 'openseadragon-' + slide.index;
      slide.viewrOptions.navigatorId = 'navigator-container-' + slide.index;
      slide.viewrOptions.zoomInButton = 'zoomInButton-' + slide.index;
      slide.viewrOptions.zoomOutButton = 'zoomOutButton-' + slide.index;
      slide.viewrOptions.homeButton = 'homeButton-' + slide.index;
      slide.viewrOptions.fullPageButton = 'fullPageButton-' + slide.index;
      slide.viewrOptions.rotateLeftButton = 'rotateLeftButton-' + slide.index;
      slide.viewrOptions.rotateRightButton = 'rotateRightButton-' + slide.index;
      slide.viewrOptions.tileSources = this.tileSourceFromData(slide.details);
      slide.viewrOptions.prefixUrl = slide.details.tilesFolderName;
      slide.viewrOptions.maxZoomPixelRatio = 1;

      setTimeout(() => {
        slide.viewer = OpenSeadragon(slide.viewrOptions);
        slide.overlay = slide.viewer.fabricjsOverlay({ scale: 1000 });

        slide.viewer.addHandler('open', (e) => {
          const zoom = slide.viewer.viewport.getZoom();
          slide.viewrOptions.maxZoomLevel = slide.viewer.viewport.getMaxZoom();
          slide.imageZoom = Math.round((zoom / slide.viewrOptions.maxZoomLevel) * 100);
          slide.imageDefaultZoom = Math.round((zoom / slide.viewrOptions.maxZoomLevel) * 100);
        });

        slide.viewer.addHandler('viewport-change', (e) => {
          const zoom = slide.viewer.viewport.getZoom();
          slide.imageZoom = Math.round((zoom / slide.viewer.viewport.getMaxZoom()) * 100);
          if (!slide.imageDefaultZoom) {
            slide.imageDefaultZoom = Math.round((zoom / slide.viewer.viewport.getMaxZoom()) * 100);
          }
          // if (slide.drawTool) {
          //   slide.annotorious.setDrawingTool(slide.drawTool === 'rect2' ? 'rect' : slide.drawTool);
          //   slide.annotorious.setDrawingEnabled(true);
          // }
        });
        this.initAnn(slide);
      }, 10);
    }
    // this.stopDraw(slide);
  }

  initAnn(slide: ViewerSlide = this.activeSlide): void {
    // Add Annotorious
    const annConfig = {
      readOnly: false,
      disableEditor: false,
      widgets: [
        {
          widget: ColorSelectorWidget, editable: 'MINE_ONLY', user: {
            id: 1,
            displayName: 'User'
          }
        },
        {
          widget: TitleWidget, editable: 'MINE_ONLY', user: {
            id: 1,
            displayName: 'User'
          }
        },
        { widget: 'COMMENT', editable: 'MINE_ONLY' },
      ],
      formatter: this.ColorFormatter
    };
    slide.annotorious = Annotorious(slide.viewer, annConfig);
    this.setSlideAnnotations(slide);

    setTimeout(() => {
      selectorPack(slide.annotorious); // adding draw options (Free, circle, ellipse);
    }, 100);

    slide.annotorious.setAuthInfo({
      id: 1,
      displayName: 'User'
    });
    slide.annotorious.on('createAnnotation', (annotation) => {
      const { canvas } = slide.viewer;
      // const newAnnotation = annotation;
      // newAnnotation.target.source = this.captureAnnotation(annotation, canvas);
      // const myFeedbacks = slide.details.slideFeedBacks.find(item => item.canEdit);
      // let myRemarks = [];
      // if (myFeedbacks && myFeedbacks.remarks) {
      //   myRemarks = JSON.parse(myFeedbacks.remarks);
      // }
      // myRemarks.push(newAnnotation);
      // this.saveAnnotations(slide, myRemarks);
      console.log(annotation)
      html2canvas(canvas).then((result) => {
        const newAnnotation = annotation;
        // console.log(this.captureAnnotation(annotation, result))
        newAnnotation.target.source = this.captureAnnotation(annotation, result);
        const currentBodyComment = annotation.body && annotation.body.length ?
          annotation.body.find((b) => {
            return b.purpose == 'commenting';
          }) : null;
        if (!currentBodyComment && annotation.body && annotation.body.length) {
          newAnnotation.body.push({ ...annotation.body[0], purpose: 'commenting', value: null })
        }
        const myFeedbacks = slide.details.slideFeedBacks.find(item => item.canEdit);
        let myRemarks = [];
        if (myFeedbacks && myFeedbacks.remarks) {
          myRemarks = JSON.parse(myFeedbacks.remarks);
        }
        myRemarks.push(newAnnotation);
      });
    });

    slide.annotorious.on('createSelection', (selection) => {
      // console.log("data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(selection.target.selector.value))));
      //
      if (slide.drawTool === 'rect2') {
        const { canvas } = slide.viewer.drawer;
        const [xi, yi, wi, hi] = selection.target.selector.value
          .split(':')[1]
          .split(',')
          .map(str => parseFloat(str));
        const { jpegImage, x, y, kx, ky } = this.getSnippet(slide.viewer, canvas, xi, yi, wi, hi);

        // console.log(jpegImage, x, y, kx, ky);
        // slide.annotorious.readOnly = true;
        slide.annotorious.disableEditor = true;
        // slide.viewerEnabled = false;

        setTimeout(() => {
          this.cancelSelectedAnn();
          slide.viewer.gestureSettingsMouse.clickToZoom = false;
        }, 2000);
      } else {
        slide.annotorious.disableEditor = false;
      }

    });

    slide.annotorious.on('selectAnnotation', (target) => {
      this.setFillColor();
      slide.selectedAnno = target.id;
    });

    // Event handler for mouseEnter annotation
    slide.annotorious.on('mouseEnterAnnotation', (event, element) => {
      if (!element.classList.contains('a9s-annotation-hidden')) {
        const currentTitleBody = event ? event.body.find((b: any) => { return b.purpose == 'title-purpose'; }) : null;
        const currentCommentBody = event ? event.body.find((b: any) => { return b.purpose == 'commenting'; }) : null;
        if (element && !event.isCell && (currentTitleBody || currentCommentBody)) {
          const { left, top, width } = element.getBoundingClientRect();
          const tooltip = document.getElementById('annotation-tooltip');
          // Position the tooltip near the annotation
          tooltip.style.left = `${(window as any).event.pageX}px`;
          tooltip.style.top = `${(window as any).event.pageY}px`;
          // Set the tooltip content
          tooltip.innerHTML = `${currentTitleBody ? '<strong>Title</strong>: ' + currentTitleBody.value + '<br/>' : ''}${currentCommentBody ? '<strong>Description</strong>: ' + currentCommentBody.value : ''}`;

          // Show the tooltip
          tooltip.classList.remove('hidden');
        }
      }
    });

    slide.annotorious.on('mouseLeaveAnnotation', () => {
      // Hide the tooltip.
      this.hideTooltip();
    });

    slide.annotorious.on('cancelSelected', (target) => {
      this.setFillColor();
      this.enableDraw(null, slide);
      slide.selectedAnno = undefined;
      // slide.scrollToId = undefined;
      slide.viewer.gestureSettingsMouse.clickToZoom = false;
      // if (!slide.annotorious.readOnly) {
      //   slide.annotorious.disableEditor = false;
      // }
    });

    slide.annotorious.on('clickAnnotation', (annotation, element) => {
      if (annotation.disableEditor) {
        // console.log(slide.annotorious)
        // slide.annotorious.readOnly = true;
        // slide.annotorious.disableEditor = true;
        this.selectAnn(annotation.id, slide, true, annotation);
      } else {
        // slide.annotorious.readOnly = this.readOnly;
        // slide.annotorious.disableEditor = this.disableEditor;
      }
      //
      // const { snippet, transform } = slide.annotorious.getSelectedImageSnippet();
      // console.log(snippet)
      // setTimeout(() => {
      // }, 10);
    });
  }

  getFeadbacks(feedback: Feedback = null, slide: ViewerSlide = this.activeSlide) {
    slide.allFeedbacks = [];
    if (!feedback) {
      this.feedbackFilterBy = null;
      slide.details.slideFeedBacks.forEach(element => {
        element.remarksObj = JSON.parse(element.remarks);
        if (element.remarks) {
          element.remarksObj.map(r => {
            r.canEdit = element.canEdit;
            r.remarksByImage = element.remarksByImage;
            r.remarksByName = element.remarksByName;
            r.slideFeedBackId = element.slideFeedBackId;
          });
          slide.allFeedbacks = slide.allFeedbacks.concat(element.remarksObj);
        }
      });
    } else {
      this.feedbackFilterBy = feedback.remarksByName;
      if (feedback.remarks) {
        slide.allFeedbacks = feedback.remarksObj;
      }
    }
    slide.allFeedbacks = slide.allFeedbacks.sort(
      (a, b) => a.body[0].modified < b.body[0].modified ? 1 : a.body[0].modified > b.body[0].modified ? -1 : 0
    );
    // if (this.feedbackFilterBy) {
    //   this.getFeadbacks();
    // }
    this.initViewer(slide);
  }

  activateSlide(slide: ViewerSlide) {
    if (this.activeSlide.viewer) {
      this.activeSlide.annotorious.cancelSelected();
      this.activeSlide.viewer.gestureSettingsMouse.clickToZoom = false;
    }
    this.slides.map(item => {
      item.isActive = false;
      item.selectedAnno = undefined;
      item.scrollToId = undefined;
    });
    slide.isActive = true;
    this.activeSlide = slide;
  }

  sortedArray(): ViewerSlide[] {
    return this.activeSlide.allFeedbacks.sort((a, b) => new Date(b.body[0].created).getTime() - new Date(a.body[0].created).getTime());
  }

  toggleSidenav() {
    this.openSideNav = !this.openSideNav;
  }

  addPolyline(aiCurrentApp, slide, arr: any[], fill: string = 'transparent', stroke: string = 'transparent', id = null, globalCompositeOperation = 'source-over', strokeWidth = 2) {
    let points = [];
    if (slide.details && aiCurrentApp === AppType.QUPATH) {
      points = [...arr];
    } else {
      for (let icount = 0; icount < arr.length; icount++) {
        points.push([arr[icount]['x'], arr[icount]['y']]);
      }
    }
    return `<polygon points=\"${points.map(xy => xy.join(',')).join(' ')}\" style='stroke: ${stroke}; stroke-width: ${strokeWidth};fill: ${fill}'></polygon>`;
  }

  addPolylineFabric(aiCurrentApp, slide, arr: any[], fill: string = 'transparent', stroke: string = 'transparent', id = null, globalCompositeOperation = 'source-over', strokeWidth = 2) {
    let points = [...arr];
    console.log(points);
    const Polyline = new fabric.Polyline(points, {
      strokeWidth: Number(strokeWidth),
      stroke: stroke,
      fill: fill,
      selectable: true,
      action: 'gravity',
      id,
      globalCompositeOperation
    });
    return Polyline;
  }

  addAnnotation(polygon, id): object {
    // console.log(polygon)
    return {
      "@context": "http://www.w3.org/ns/anno.jsonld",
      "id": `#${id}`,
      "type": "Annotation",
      "body": [],
      "target": {
        "selector": {
          "type": "SvgSelector",
          "value": `<svg>${polygon}</svg>`
        }
      },
      "readOnly": true,
      "disableEditor": true,
      "disableSelect": true,
      "isCell": true
    };
  }

  zoomToDetection(objectId: string, slide: ViewerSlide = this.activeSlide) {
    const annotationSelected = slide.annotorious.getAnnotationById('#' + objectId);
    if (annotationSelected) {

      // const elems = document.querySelectorAll('.a9s-annotation[data-id="' + annotationSelected.id + '"] .a9s-inner') as any;
      // console.log(elems)
      // elems.forEach(element => {
      //   // element.style.fill = 'rgba(238,223,36, ' + (Number(this.activeSlide.opacityValue) / 10) + ')';
      //   element.dispatchEvent(new Event('click'));
      // });
      // slide.annotorious.readOnly = false;
      slide.annotorious.disableEditor = true;
      // slide.annotorious.disableSelect = false;
      slide.annotorious.selectAnnotation(annotationSelected.id);
      slide.viewer.gestureSettingsMouse.clickToZoom = true;
      // slide.viewer.setMouseNavEnabled(true);
      setTimeout(() => { slide.selectedAnno = annotationSelected.id; }, 10);
      const [xi, yi, wi, hi] = this.calculatePolygonBounds(this.getPointsAnnotation(annotationSelected), false);
      // Calculate the center point of the annotation
      const centerX = xi + wi / 2;
      const centerY = yi + hi / 2;

      // Get the viewport coordinates for the center point
      const viewportPoint = slide.viewer.viewport.imageToViewportCoordinates(centerX, centerY);
      // Pan the viewer to the annotation
      slide.viewer.viewport.panTo(viewportPoint, true);

      // slide.annotorious.readOnly = false;
      // slide.annotorious.disableEditor = true;
      // slide.annotorious.selectAnnotation(annotationSelected.id);
      // setTimeout(() => { slide.selectedAnno = annotationSelected.id; }, 10);
    }
    // slide.viewer.viewport.panTo(xi, yi);
    // slide.annotorious.selectAnnotation(annotationSelected.id);
    // setTimeout(() => { slide.selectedAnno = annotationSelected.id; }, 10);
    // console.log(slide.annotorious.getSelected())

  }

  calculatePolygonBounds(points, calcZoom = true, slide: ViewerSlide = this.activeSlide) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const x = point[0];
      const y = point[1];

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }
    if (calcZoom) {
      const zoomChanged = slide.imageDefaultZoom == slide.imageZoom ? (slide.imageZoom * 2) : slide.imageZoom;
      const zoomx = ((zoomChanged / 100) * (maxX - minX));
      const zoomy = ((zoomChanged / 100) * (maxY - minY));

      if ((minX - zoomx) > 0) {
        minX -= zoomx;
        minY -= zoomy;
        maxX += (zoomx * 2);
        maxY += (zoomy * 2);
      }
    }
    return [
      minX,
      minY,
      maxX - minX,
      maxY - minY
    ]
  }

  getPointsAnnotation(annotation) {
    let points = [];
    if (annotation.target.selector.value.includes("xywh")) {
      points = this.service.convertRectangleCordinates(annotation.target.selector.value, true);
    }
    else if (annotation.target.selector.value.includes("polygon")) {
      points = this.service.convertPolygonToCordinates(annotation.target.selector.value, true);
    }
    else if (annotation.target.selector.value.includes("circle")) {
      points = this.service.convertCircleToCordinates(annotation.target.selector.value, true);
    }
    else if (annotation.target.selector.value.includes("ellipse")) {
      points = this.service.convertEllipseToCordinates(annotation.target.selector.value, true);
    }
    else if (annotation.target.selector.value.includes("path")) {
      points = this.service.convertSvgPathToPolygon(annotation.target.selector.value, true);
    }
    return points;
  }

  captureAnnotation(annotation, canvas, slide: ViewerSlide = this.activeSlide) {
    const [xi, yi, wi, hi] = this.calculatePolygonBounds(this.getPointsAnnotation(annotation));
    // console.log(xi, yi, wi, hi)
    const { jpegImage, x, y, kx, ky } = this.getSnippet(slide.viewer, canvas, xi, yi, wi, hi);
    return jpegImage;
  }

  /**
   * Cuts the selected image snippet from the OpenSeadragon CANVAS element.
   */
  getSnippet(viewer, container, xi, yi, wi, hi) {
    // Scale factor for OSD canvas element (physical vs. logical resolution)
    const { canvas } = viewer.drawer;
    const canvasBounds = canvas.getBoundingClientRect();
    const kx = canvas.width / canvasBounds.width;
    const ky = canvas.height / canvasBounds.height;

    // Parse fragment selector (image coordinates)
    // WARNING: a hack that STRICTLY assumes a box selection
    // from Annotorious (will break for polygon selections)
    // const [xi, yi, wi, hi] = annotation.target.selector.value
    //   .split(':')[1]
    //   .split(',')
    //   .map(str => parseFloat(str));

    // Convert image coordinates (=annotation) to viewport coordinates (=OpenSeadragon canvas)
    const topLeft = viewer.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(xi, yi));
    const bottomRight = viewer.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(xi + wi, yi + hi));

    const { x, y } = topLeft;
    const w = bottomRight.x - x;
    const h = bottomRight.y - y;

    // Cut out the image snippet as in-memory canvas element
    const snippet = document.createElement('canvas');
    const ctx = snippet.getContext('2d');
    snippet.width = w;
    snippet.height = h;
    ctx.drawImage(container, x * kx, y * ky, w * kx, h * ky, 0, 0, w * kx, h * ky);
    const jpegImage = snippet.toDataURL("image/jpeg");
    // Return snippet canvas + basic properties useful for downstream coord translation
    return { jpegImage, kx, ky, x: xi, y: yi };
  }

  tileSourceFromData(slide: Slide): object {
    return {
      Image: {
        xmlns: 'http://schemas.microsoft.com/deepzoom/2008',
        Format: 'jpg',
        Url: slide.tilesFolderName + '/',
        Overlap: slide.tilesOverlap,
        TileSize: slide.tileSize,
        Size: {
          Height: slide.tilesHeight,
          Width: slide.tilesWidth
        }
      }
    };
  }
  goToZoomLevel(percentage: number, slideTarget: ViewerSlide = null, currentZoom = false) {
    if (slideTarget) {
      if (percentage) {
        const zoomLevel = (percentage / 100) * slideTarget.viewer.viewport.getMaxZoom();
        slideTarget.viewer.viewport.zoomTo(zoomLevel, null, false);
      } else {
        this.zoomToSelectedAnn(slideTarget);
      }
    } else {
      // console.log(this.slides.some(obj => obj.zoomlevelSelected === percentage))
      this.zoomlevelAll = percentage;
      this.slides.forEach(slide => {
        if (slide.zoomlevelSelected !== percentage || slide.imageZoom !== percentage) {
          slide.zoomlevelSelected = currentZoom ? slide.zoomlevelSelected : percentage;
          if (percentage) {
            const zoomLevel = (percentage / 100) * slide.viewer.viewport.getMaxZoom();
            slide.viewer.viewport.zoomTo(zoomLevel, null, false);
          } else {
            this.zoomToSelectedAnn(slide);
          }
        }
      });
    }
  }

  activeAnotherTool(toolName, slide: ViewerSlide = this.activeSlide) {
    if (toolName && (slide.drawTool !== String(toolName + 2))) {
      slide.drawTool = String(toolName + 2);
      slide.annotorious.setDrawingEnabled(true);
      slide.annotorious.setDrawingTool(toolName);
    } else {
      slide.drawTool = undefined;
      slide.annotorious.setDrawingEnabled(false);
    }
  }

  enableDraw(toolName, slide: ViewerSlide = this.activeSlide): void {
    if (!slide) { return; }
    if (toolName && (slide.drawTool !== toolName)) {
      slide.drawTool = toolName;
      slide.annotorious.setDrawingTool(toolName);
      slide.annotorious.setDrawingEnabled(true);
    } else {
      slide.drawTool = undefined;
      slide.annotorious.setDrawingEnabled(false);
    }
    console.log(slide.drawTool, toolName)
  }

  stopDraw(slide: ViewerSlide): void {
    this.enableDraw(null, slide);
    this.setFillColor();
    this.cancelSelectedAnn();
  }
  selectAnn(id, slide: ViewerSlide, disableEditor = false, annotation = null): void {
    this.cancelSelectedAnn();
    if (!slide.hiddenAnnotations.includes(id)) {
      if (annotation) {
        slide.scrollToId = annotation.id;
      } else {
        slide.scrollToId = undefined;
      }
      if (id && id !== slide.selectedAnno) {
        this.setFillColor();
        slide.annotorious.disableEditor = disableEditor;
        slide.annotorious.selectAnnotation(id);
        // slide.selectedAnno = id
        setTimeout(() => { slide.selectedAnno = id; }, 10);
        // this.zoomToSelectedAnn(slide);
      }
    }
  }

  cancelSelectedAnn(slide: ViewerSlide = this.activeSlide) {
    slide.annotorious.cancelSelected();
    slide.selectedAnno = undefined;
    slide.scrollToId = undefined;
  }

  zoomToSelectedAnn(slide: ViewerSlide): void {
    slide.viewer.viewport.goHome();
  }
  screenshot(slide: ViewerSlide): void {
    const canvas = document.getElementById('openseadragon-' + slide.index).querySelector('canvas') as HTMLCanvasElement;
    canvas.toBlob(blob => {
      saveAs(blob, 'vervet-' + new Date().getTime() + '.jpg');
    });
  }
  // disableAnn(): void {
  //   this.annotorious.readOnly = true;
  // }
  // enableAnn(): void {
  //   this.annotorious.readOnly = false;
  // }
  setFillColor(): void {
    const elems = document.querySelectorAll((this.isMultiViewer ? '.active-slide' : '') + ' .a9s-inner') as any;
    elems.forEach(element => {
      // element.style.fill = 'rgba(238,223,36, ' + (Number(this.activeSlide.opacityValue) / 10) + ')';
      element.style.opacity = (Number(this.activeSlide.opacityValue) / 10);
    });
  }
  setHiddenAnnotaion(id, hidden = true): void {
    const elems = document.querySelectorAll((this.isMultiViewer ? '.active-slide' : '') + (hidden ? ' .a9s-annotation' : ' .a9s-annotation-hidden') + '[data-id="' + id + '"]') as any;
    elems.forEach(element => {
      // console.log(element);
      if (hidden) {
        element.classList.add('a9s-annotation-hidden');
        element.classList.remove('a9s-annotation');
      } else {
        element.classList.remove('a9s-annotation-hidden');
        element.classList.add('a9s-annotation');
      }
      // element.style.display = hidden ? 'none' : 'block';
    });
  }
  setFillBrightness(slide: ViewerSlide = this.activeSlide): void {
    if (!slide.redColor && !slide.greenColor && !slide.blueColor) {
      slide.viewer.drawer.canvas.style.filter = 'brightness(' + (Number(slide.brightness)) + '%)';
    } else {
      const color = new Color(slide.redColor, slide.greenColor, slide.blueColor);
      const result = new Solver(color).solve();
      slide.viewer.drawer.canvas.style.filter = result.filter + 'brightness(' + (Number(slide.brightness)) + '%)';
    }
  }

  // Annotorious Actions
  // setDebugMode(): void {
  //   console.log(this.activeSlide.viewer);
  //   return
  //   for (let i = 0; i < this.activeSlide.viewer.world.getItemCount(); i++) {
  //     this.activeSlide.viewer.world.getItemAt(i).debugMode = true;
  //   }
  //   this.activeSlide.viewer.forceRedraw();
  // }

  fullScreen(): void {
    const elem = document.getElementById('fullscreenContainer') as any;
    const methodToBeInvoked = elem.requestFullscreen ||
      elem.webkitRequestFullScreen || elem.mozRequestFullscreen || elem.msRequestFullscreen;
    if (methodToBeInvoked) { methodToBeInvoked.call(elem); }
  }

  @HostListener('document:fullscreenchange', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (document.fullscreenElement) {
      document.getElementById('fullscreenContainer').classList.add('fullscreen');
    } else {
      document.getElementById('fullscreenContainer').classList.remove('fullscreen');
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    this.hideTooltip();
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent): void {
    // !this.dragonViewer.find(el => el.nativeElement.contains(event.target)) &&
    // !this.comments.nativeElement.contains(event.target)
    if (
      event.target['tagName'] !== 'INPUT' &&
      event.target['tagName'] !== 'TEXTAREA' &&
      !this.comments.nativeElement.contains(event.target) &&
      document.querySelector('#country-search-box') !== event.target
    ) {
      if (event.key === 'Escape') {
        this.enableDraw(null);
        this.activeSlide.selectedAnno = undefined;
        if (this.activeSlide.viewer) {
          this.activeSlide.annotorious.cancelSelected();
        }
      }
      if (!event.ctrlKey) {
        if (event.key === 'c' || event.key === 'C') {
          this.enableDraw('circle');
        }
        if (event.key === 'e' || event.key === 'E') {
          this.enableDraw('ellipse');
        }
        if (event.key === 'p' || event.key === 'P') {
          this.enableDraw('freehand');
        }
        if (event.key === 'y' || event.key === 'Y') {
          this.enableDraw('polygon');
        }
        if (event.key === 'q' || event.key === 'Q') {
          this.enableDraw('rect');
        }
      }
    }
  }

  ColorFormatter = (annotation) => {
    const highlightBody = annotation.bodies.find((b) => {
      return b.purpose == 'highlighting';
    });

    if (highlightBody)
      return highlightBody.value;
  }

  hideTooltip() {
    const tooltip = document.getElementById('annotation-tooltip');
    tooltip.classList.add('hidden');
  }
  setSlideAnnotations(slide: ViewerSlide) {
    if (slide.details.aiCurrentApp === AppType.VISIOPHARM) {
      const group = new fabric.Group(slide.annoCells);
      slide.overlay.fabricCanvas().add(group);
      slide.annotorious.setAnnotations([...slide.allFeedbacks]);
    } else {
      slide.annotorious.setAnnotations([...slide.allFeedbacks, ...slide.annoCells]);
    }
    // const elems = document.querySelectorAll((this.isMultiViewer ? '.active-slide' : '') + ' .a9s-annotationlayer g') as any;
    // elems.forEach(element => {
    //   element.insertAdjacentHTML('beforeend', `
    //     <clipPath id="myMask" maskContentUnits="objectBoundingBox">
    //       ${slide.clipPaths.join(' ')}
    //     </clipPath>`)
    // });
    // console.log(elems)

    slide.allFeedbacks.forEach(item => {
      if (slide.hiddenAnnotations.includes(item.id)) {
        this.setHiddenAnnotaion(item.id);
      }
    });
    slide.loading = false;
  }
}
