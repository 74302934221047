import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-study-viewer',
  templateUrl: './study-viewer.component.html'
})
export class StudyViewerComponent implements OnInit, OnDestroy {
  @Output() closeViewer = new EventEmitter();
  @Input() studyId: string;
  @Input() view = 'meeting';
  viewerSrc: string;

  constructor() { }

  ngOnInit() {
    this.viewerSrc = location.origin + '/admin/dicom-viewer/viewer/' + this.studyId;
    console.log(this.viewerSrc);

    document.body.classList.add('fixed-content');
  }
  ngOnDestroy() {
    document.body.classList.remove('fixed-content');
  }

}
