export class AddUserModel {
    id?: string;
    email: string="";
    username: string="";
    fullName: string="";
    arabicName: string="";
    identityNumber?: string;
    countryKey: string="";
    // extention: number =0;
    phoneNumber: string="";
    nationalityId: string="";
    countryId: string="";
    cityId: string="";
    jobTitle: string="";
    profilePhoto: string="";
    birthdate: Date;
    gender: string="";
    status: number =0;
    joinedDate: "2020-04-22T10:34:50.783Z";
    lastLoginDate: "2020-04-22T10:34:50.783Z";
    password: string="";
    confirmPassword: string = '';
    employer: string="";
    workLocation: string;
    isVisitor?: boolean;
}
