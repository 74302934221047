import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from './services/Update.service';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  hasUpdate = false;

  constructor(
    public translate: TranslateService,
    private sw: UpdateService
  ) {
    this.sw.checkForUpdates();

    // if (location.href.includes('slidesViewer')) {}
    const lang = localStorage.getItem('language') || 'en';
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(lang);


    var style = './assets/css/en-US/style.css?vr=' + version;
    var dir = 'ltr';
    if (lang == 'ar') {
      style = './assets/css/ar-SA/style.css?vr=' + version;
      dir = 'rtl';
    }
    document.getElementById('mainStyleLink').setAttribute('href', style);
    document.getElementById('html').setAttribute('lang', lang);
    document.getElementById('html').setAttribute('dir', dir);
  }
}
