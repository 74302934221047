import { Component, OnInit, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable } from 'rxjs';
import { Collection } from 'src/app/models/tasks/Collection';
import { TasksService } from 'src/app/services/tasks.service';
import { startWith, map } from 'rxjs/operators';
import { Task } from 'src/app/models/tasks/Task';
import { TasksType } from 'src/app/models/tasks/TasksType';
import { User } from 'src/app/models/user/user';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-create-edit-task',
  templateUrl: './create-edit-task.component.html'
})
export class CreateEditTaskComponent implements OnInit {

  @Input() taskId;
  @Input() taskType;
  @Input() groupId;
  @Input() groupAbbreviation;
  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();

  @Input() taskTitle: string;

  loading = false;
  loadingData = false;
  openTaskForm = false;
  minDate = new Date();
  maxDate: Date;
  priorities: any = [];

  task = new Task();
  updateTask = false;

  taskForm = this.formBuilder.group({
    privateTask: new FormControl(false),
    completion: [null, [ Validators.max(99), Validators.min(0) ]],
    title: new FormControl('', [ Validators.required ]),
    details: new FormControl(''),
    entity: new FormControl('', [ Validators.required ]),
    dueDate: new FormControl('', [ Validators.required ]),
    priority: new FormControl('', [ Validators.required ]),
  });

  filteredCollections: Observable<Collection[]>;
  collections: Collection[] = [];
  validCollection = true;

  filteredUsers: Observable<User[]>;
  users: User[] = [];
  validUser = true;

  files: any = [];
  selectedfiles = 0;
  deletedFiles = [];
  validFile = true;
  fileMaxSiZeErorr = false;

  detailsValidator: any;
  isValidDate = true;
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private tasksService: TasksService,
    private elementRef: ElementRef
  ) {
    this.filteredCollections = this.taskForm.get('entity').valueChanges.pipe(
      startWith(''),
      map(collection => collection ? this.filterCollections(collection) : this.collections.slice())
    );

    this.filteredUsers = this.taskForm.get('entity').valueChanges.pipe(
      startWith(''),
      map((user) => (user ? this.filterUsers(user) : this.users.slice())));
  }

  ngOnInit() {
    this.GetTaskPriorities();

    if (this.taskId !== -1) { // get details by id to edit task
      this.updateTask = true;
      this.getTask(this.taskId, this.taskType);
    } else {
      this.GetCollectionsByGroupId(this.groupId);
    }
    // if (this.taskType === 0) {
    //   this.taskForm.get('details').setValidators(Validators.required);
    // } else {
    //   this.taskForm.get('details').setValidators(null);
    // }

    if (this.taskTitle !== undefined) {
      this.taskForm.get('title').setValue(this.taskTitle);
    }
  }
  detectLang() {
    const lang = localStorage.getItem('language') || 'en';
    const wrapper = $('.meridian .mat-button .mat-button-wrapper');
    setTimeout(() => {
      if (lang === 'ar') {
        if (wrapper.text() === ' PM ') {
          wrapper.text(' م ');
        } else {
          wrapper.text(' ص ');
        }
      }
    }, 1000);
    if (lang === 'ar') {
      $(document).on('click', '.meridian .mat-button', () => {
        if (wrapper.text() === ' ص ') {
          wrapper.text(' م ');
        } else {
          wrapper.text(' ص ');
        }
      });
    }
  }

  GetTaskPriorities() {
    this.tasksService.GetTaskPriorities().subscribe(
      data => {
          this.priorities = data;
          console.log(this.priorities)
          this.taskForm.controls.priority.setValue(2);
      }, err => {
        console.error(err);
      });
  }

  GetCollectionsByGroupId(groupId) {
    this.tasksService.GetCollectionsByGroupId(groupId).subscribe(
      data => {
        this.collections = data as Collection[];
      }, err => {
        console.error(err);
      });
  }
  GetAssigneesByTaskId(parentTaskId) {
    this.tasksService.GetAssigneesByTaskId(parentTaskId, 0).subscribe(
      (data) => {
        this.users = data as User[];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getTask(taskId, type) {
    this.loadingData = true;
    this.tasksService.GetTask(taskId, type).subscribe(
      data => {
        this.loadingData = false;
        this.task = data as Task;
        this.taskForm.controls.privateTask.setValue(this.task.isPrivate);
        this.taskForm.controls.completion.setValue(this.task.completion);
        this.taskForm.controls.title.setValue(this.task.taskTitle);
        this.taskForm.controls.details.setValue(this.task.taskDetails);
        this.taskForm.controls.dueDate.setValue(this.task.dueDate);
        this.taskForm.controls.priority.setValue(this.task.taskPriorityId);
        this.files = this.task.taskAttachments;
        // get this task collection
        if (type === TasksType.MainTaskEntity) {
          this.GetCollectionsByGroupId(this.task.groupData.groupId);
          this.taskForm.controls.entity.setValue(this.task.collectionData);
          this.minDate = new Date(this.task.minimumDueDate);
        }
        if (type === TasksType.SubTasksEntity) {
          this.maxDate = new Date(this.task.maximumDueDate);
          this.GetAssigneesByTaskId(this.task.parentTaskId);
          const assigneeUser = {
            name: this.task.userData['userTitle'],
            userId: this.task.userData['userId'],
            profilePicture: this.task.userData['userImage'],
            userCollectionGroupingId: this.task.userData['userCollectionGroupingId']
          };
          this.taskForm.controls.entity.setValue(assigneeUser);
        }
      }, err => {
        console.error(err);
      });
  }

  saveTask() {
    this.validateDates(this.taskForm.value.dueDate.toLocaleString());
    if (this.taskForm.valid && this.validCollection && this.validUser && this.isValidDate) {
      this.loading = true;
      let task;
      task = {
        IsPrivate: this.taskForm.value.privateTask,
        TaskTitle: this.taskForm.value.title,
        Description: this.taskForm.value.details,
        AssignEntity: this.taskForm.value.entity.collectionGroupingId,
        DueDate: this.taskForm.value.dueDate.toLocaleString(),
        TaskPriority: this.taskForm.value.priority
      };

      if (this.updateTask) {
        task.completion = this.taskForm.value.completion;
      }

      if (this.taskId !== -1) {
        task.Id = this.taskId;
        task.TaskType = this.taskType;
        // tslint:disable-next-line: max-line-length
        task.AssignEntity = (this.taskType === TasksType.MainTaskEntity) ? this.taskForm.value.entity.collectionGroupingId : this.taskForm.value.entity.userCollectionGroupingId;
        task.OldAttachments = this.deletedFiles;
      }

      this.tasksService.saveTask(task, this.files).subscribe(
        data => {
          this.loading = false;
          this.closeTask();
          this.closePopAndSave.emit();
          Swal.fire({
            title: this.translate.instant('swalMsg.success'),
            text:  this.translate.instant('swalMsg.savedsuccessfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('swalMsg.ok'),
          }).then((result) => { });
        }, error => {
          if (error && error.detail) {
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text: error.detail,
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.closeTask();
          }
          console.error(error);
        });
    }
  }
  closeTask() {
    this.cancelPop.emit();
  }

  validateDates(value) {
    this.isValidDate = true;
    if (new Date(value) <= new Date()) {
      this.isValidDate = false;
    }
  }

  clearDueDate(event) {
    event.stopPropagation();
    this.taskForm.controls.dueDate.setValue('');
  }
  datePlusDay(date) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }
  textAreaAdjust(event) {
    event.target.style.height = '30px';
    event.target.style.height = event.target.scrollHeight + 'px';
  }
  displayFn(obj: any): string {
    if (obj && 'name' in obj) {
      return obj && obj.name ? obj.name : '';
    }
    if (obj && 'collectionName' in obj) {
      return obj && obj.collectionName ? obj.collectionName : '';
    }
    return '';
  }
  filterCollections(value: any) {
    let filterValue = '';
    if (typeof value === 'string' || value instanceof String) {
      this.validCollection = false;
      filterValue = value.toLowerCase();
    } else {
      this.validCollection = true;
      filterValue = value.collectionName.toLowerCase();
    }
    return this.collections.filter(collection => collection.collectionName.toLowerCase().indexOf(filterValue) === 0);
  }
  filterUsers(value: any) {
    let filterValue = '';
    if (typeof value === 'string' || value instanceof String) {
      this.validUser = false;
      filterValue = value.toLowerCase();
    } else {
      this.validUser = true;
      filterValue = value.name.toLowerCase();
    }
    return this.users.filter((user) => user.name.toLowerCase().indexOf(filterValue) === 0);
  }
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  /********************************************************/
  fileType(fileName) {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
      case 'png':
        return 'image';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
      case 'txt':
        return 'doc';
      case 'xls':
      case 'xlsb':
      case 'xlsx':
      case 'xltx':
      case 'xlsm':
        return 'xls';
      default:
        return false;
    }
    return false;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, id) {
    if (id) {
      this.deletedFiles.push(id);
      console.log(this.deletedFiles);
    }
    this.files.splice(index, 1);
    this.selectedfiles = this.files.length;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.validFile = true;
    this.selectedfiles = (this.files.length + files.length);
    if (this.selectedfiles > 5) {
      return;
    }
    for (const item of files) {
      if (this.fileType(item.name)) {
        this.validFile = true;
      } else {
        this.validFile = false;
      }
      if (((item.size / (1024 * 1024)) <= 10) && (this.fileType(item.name))) {
        item.fileType = this.fileType(item.name);
        this.files.push(item);
        this.fileMaxSiZeErorr = false;
      } else {
        this.fileMaxSiZeErorr = true;
      }
    }
    console.log(this.files);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
