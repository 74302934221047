export interface VotingListData {
  canAdd: boolean
  pageData: CaseVote[]
  totalCount: number
}
export interface CaseVote {
  id: number
  title: string
  isOwner: boolean
  votingClosed: boolean
  treatmentPlanApproved: boolean
  canViewSummary: boolean
  canViewVotes: boolean
  votingTime: number
  votingStartDateTime: string
  votingRemainingTime: number
  treatmentPlaneHistory: TreatmentPlaneHistory[]
  recommendationHistory: RecommendationHistory[]
  history: any[]
  attendeeVotingAnswers: AttendeeVotingAnswers[]
  votingSummaryList: VotingSummaryList[]
  voteOption: VoteOption[]
}
export interface AttendeeVotingAnswers {
  creator: Creator
  delegator: Creator
  answer: string
  answerDate: string
  minPassed: number
  lookup: string
}

export interface Creator {
  creatorId: string
  creatorProfilePicture: string
  creatorUserName: string
  creatorName: string
  creatorCollectionName: any
}

export interface TreatmentPlaneHistory {
  id: number
  medicalPhase: MedicalPhase
  medicalPhasefields: MedicalPhasefield[]
  sortingOrder: number
  cycleNumber: string
  startDate: string
  endDate: string
  additionalNotes: string
  canDelete: boolean
  canEdit: boolean
}

export interface RecommendationHistory {
  id: number
  recommendation: string
  sortingOrder: number
}

export interface MedicalPhase {
  id: number
  icon: string
  name: string
  arabicTitle: string
  englishTitle: string
}

export interface MedicalPhasefield {
  fieldId: number
  fieldname: string
  fieldType: string
  arabicTitle: string
  englishTitle: string
  fieldValue: string
  lookupApi: string
  isRequired: boolean
}

export interface VotingSummaryList {
  title: string
  optionId: number
  votesNumber: number
  percentage: number
}

export interface VoteOption {
  id: number
  title: string
}

export enum VotingView {
  Plan = 'Plan',
  Recommend = 'Recommend'
}
