import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ViewerService } from '../../services/Viewer.service';
import { Comment, CommentsFilter } from '../../models/Comment';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html'
})
export class CommentsComponent implements OnInit {
  @Input() viewerEnabled: boolean;
  @Input() slideId: number;
  @Input() slideToken: string;
  @Input() pathologistId: number;
  @Input() IsEndorsement: string;
  @Input() sharedFromDemo: boolean;
  @Input() reload: boolean;

  dataLoading = false;
  loading = false;
  comment = '';
  selectedComment = '';
  commentFocus = false;
  commentsList: Comment[] = [];
  commentsFilter: CommentsFilter = new CommentsFilter();
  commentsUsers = [];
  FilterIds: string[] = [];

  constructor(
    private service: ViewerService
  ) { }

  ngOnInit(): void {
    // if (this.slideId) {
    //   this.getComments();
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.slideId || (changes.reload && changes.reload.currentValue)) && !this.slideToken) {
      this.getComments();
    }
  }

  getComments() {
    this.dataLoading = true;
    this.commentsFilter.SlideID = this.slideId;
    this.makeFilter();
    this.service.getComments(this.commentsFilter).subscribe(
      data => {
        this.commentsList = data as [];
        this.handleCommentsForFilter();
        this.dataLoading = false;
      }, err => {
        console.error(err);
      });
  }
  handleCommentsForFilter() {
    this.commentsList.map(comment => {
      comment.editMode = false;
      comment.comment = decodeURIComponent(comment.comment);
    });
  }

  makeFilter() {
    if (this.FilterIds && this.FilterIds.length) {
      this.commentsFilter.FilterIds = JSON.stringify(this.FilterIds);
    } else {
      delete this.commentsFilter.FilterIds;
    }
  }
}
