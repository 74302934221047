import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { MyprofileService } from 'src/app/services/myprofile.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signature-form',
  templateUrl: './signature-form.component.html'
})
export class SignatureFormComponent implements OnInit, AfterViewInit {
  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() isUpdate: boolean = false;
  @Input() signature: string;

  loading = false;
  isSubmited = false;
  files = [];

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  signaturePadOptions: object;

  constructor(
    private translate: TranslateService,
    private service: MyprofileService,
    private cdRef: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.signaturePadOptions = {
      minWidth: 1,
      canvasWidth: document.getElementById('signatureDialog').offsetWidth - 30,
      canvasHeight: (document.getElementById('signatureDialog').offsetWidth - 30) * 0.6
    };
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  drawStart() { }
  drawComplete() { }

  save() {
    this.isSubmited = true;
    if (this.signaturePad.isEmpty() && !this.files.length) {
      let msg = '';
      if (this.signature) {
        msg = this.translate.instant('users.You already has signature');
      } else {
      }
      msg = this.translate.instant('users.Please upload or draw new signature');
      Swal.fire({
        text: msg,
        icon: 'info',
        confirmButtonText: this.translate.instant('swalMsg.ok')
      });
      return;
    }

    if (!this.signaturePad.isEmpty() && this.files.length) {
      Swal.fire({
        text: this.translate.instant('users.Please choose one signature to save it!'),
        icon: 'info',
        confirmButtonText: this.translate.instant('swalMsg.ok')
      });
      return;
    }

    this.loading = true;
    const formData = new FormData();
    let signature: any;
    if (!this.signaturePad.isEmpty()) {
      signature = this.dataURItoBlob(this.signaturePad.toDataURL());
    } else {
      signature = this.files[0];
    }
    formData.append('file', signature, signature.name);

    this.service.AddUserSigneture(formData).subscribe(
      data => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.savedsuccessfully'),
          text: '',
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok'),
        });
        this.closePopAndSave.emit();
      }, err => {
        Swal.fire({
          title: this.translate.instant('swalMsg.Failed'),
          text: '',
          icon: 'error',
          confirmButtonText: this.translate.instant('swalMsg.ok')
        });
        this.loading = false;
      });
  }
  dataURItoBlob(dataURI: string) {
    dataURI = dataURI.replace('data:image/png;base64,', '');
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    const imageBlob: Blob = blob;
    const imageName: string = "signature.png";
    const imageFile: File = new File([imageBlob], imageName, {
      type: "image/png"
    });
    return imageFile;
  }
}
