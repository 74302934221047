export enum AppCode {
  QUPATH_CELL_DETECTION = 'QUPATH_CELL_DETECTION',
  QUPATH_POSITIVE_CELL_DETECTION = 'QUPATH_POSITIVE_CELL_DETECTION',
  QUPATH_MARROW_QUANT = 'QUPATH_MARROW_QUANT',
  VISIO_CYTOPLASM = 'VISIO_CYTOPLASM',
  VISIO_MEMBRANNE = 'VISIO_MEMBRANNE',
  VISIO_NUCLEI = 'VISIO_NUCLEI',
  VISIO_NUCLEI_DETECTION = 'VISIO_NUCLEI_DETECTION',
  VISIO_T_D1 = 'VISIO_T_D1',
  VISIO_M_D = 'VISIO_M_D',
  VISIO_P_P = 'VISIO_P_P',
  VISIO_C_R = 'VISIO_C_R',
  VISIO_KI = 'VISIO_KI',
  VISIO_01_T_D = 'VISIO_01_T_D',
  VISIO_02_TS = 'VISIO_02_TS',
  VISIO_03_TUMOR_POST = 'VISIO_03_TUMOR_POST',
  VISIO_04_BREAST_CANCER = 'VISIO_04_BREAST_CANCER',
  VISIO_01_PR_Tissue_Detection = 'VISIO_01_PR_Tissue_Detection',
  VISIO_02_PR_Tumor_Segmentation = 'VISIO_02_PR_Tumor_Segmentation',
  VISIO_03_PR_Tumor_Postprocessing = 'VISIO_03_PR_Tumor_Postprocessing',
  VISIO_04_PR_Breast_Cancer_AI = 'VISIO_04_PR_Breast_Cancer_AI',
  VISIO_10182_IHC_Tissue_Detection_AI = 'VISIO_10182_IHC_Tissue_Detection_AI',
}
