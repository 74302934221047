import { Component, NgZone, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { User } from 'src/app/models/messages/user';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'src/app/models/chat/message';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent implements OnInit {
  loadingUsers = false;
  openChatList = false;

  searchText: string;
  usersList: User[] = [];

  activeChats: {
    active: boolean,
    loading: boolean,
    user: User,
    message: string,
    textDir: string,
    msgList: Message[]
  }[] = [];

  constructor(
    public translate: TranslateService,
    private chatService: ChatService,
    public zone: NgZone,
    private signalRService: SignalRServiceService
  ) {
    this.signalRService.buildConnection();
  }

  ngOnInit() {
    this.getUsers('');
    this.signalRService.handleNewChatMessageEvent(this, this.newMessage);
  }

  checkKeyboard(event, chat) {
    if (!chat.message) { chat.textDir = ''; return; }
    if (event.keyCode === 32 || event.keyCode === 8) { return; }
    const re = /\d|\w|[\.\$@\*\\\/\+\-\^\!\(\)\[\]\~\%\&\=\?\>\<\{\}\"\'\,\:\;\_]/g;
    const a = event.key.match(re);
    if (a == null) { chat.textDir = 'rtl'; return; }
    chat.textDir = 'ltr';
  }

  newMessage(context, loggedUserId, messageContent) {
    context.zone.run(() => {
      const findUser = context.usersList.find((user) => user.id === loggedUserId);
      context.openChat(findUser);
      return;
      let newMessage = new Message();
      newMessage = {
        mine: false,
        text: messageContent,
        time: new Date(),
        isSeen: false
      };
      const chatIndex = context.activeChats.findIndex((item) => item.user.id === loggedUserId);
      if (chatIndex !== -1) {
        context.activeChats[chatIndex].msgList.push(newMessage);
        context.activeChats[chatIndex].active = true;
      } else {
        const findUser = context.usersList.find((user) => user.id === loggedUserId);
        context.activeChats.push({
          active: true,
          loading: false,
          user: findUser,
          message: '',
          msgList: [newMessage]
        });
      }
    });
  }

  getUsers(text) {
    this.loadingUsers = true;
    this.chatService.GetUsers(text).subscribe(
      result => {
        this.usersList = result as User[];
        this.loadingUsers = false;
        this.openStoredChats();
        this.missedChat();
      }, err => {
        console.error(err);
      });
  }

  openStoredChats() {
    const storedChats = JSON.parse(localStorage.getItem('activeChats'));
    if (storedChats && storedChats.length) {
      storedChats.forEach(chat => {
        const findUser = this.usersList.find((user) => user.id === chat.user.id);
        this.openChat(findUser);
      });
      this.activeChats.forEach((chat) => {
        chat.active = false;
      });
    }
  }

  openChat(user) {
    const chatIndex = this.activeChats.findIndex((item) => item.user.id === user.id);
    if (chatIndex === -1) {
      this.activeChats.push({
        active: true,
        loading: true,
        user,
        message: '',
        textDir: '',
        msgList: []
      });
    } else {
      this.activeChats[chatIndex].loading = true;
      this.activeChats[chatIndex].active = true;
    }
    this.listChatMessages(user.id);
    localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
  }

  listChatMessages(userId) {
    let chatIndex = this.activeChats.findIndex((item) => item.user.id === userId);
    this.chatService.ListChatMessages(userId).subscribe(
      result => {
        chatIndex = this.activeChats.findIndex((item) => item.user.id === userId);
        if (chatIndex >= 0) {
          this.activeChats[chatIndex].msgList = result as Message[];
          this.activeChats[chatIndex].loading = false;
          setTimeout(() => {
            if (document.querySelectorAll('#chat-' + chatIndex + ' ul').length) {
              document.getElementById('chat-' + chatIndex).scrollTop =
                document.querySelectorAll('#chat-' + chatIndex + ' ul')[0].clientHeight;
            }
          }, 100);
        }
      }, err => {
        console.error(err);
      });
  }

  chatSeen(userId) {
    this.chatService.SeeConversation(userId).subscribe(
      result => { }, err => {
        console.error(err);
      });
  }
  missedChat() {
    this.chatService.GetMissedConversations().subscribe(
      result => {
        result.forEach(chat => {
          const findUser = this.usersList.find((user) => user.id === chat.userId);
          this.openChat(findUser);
        });
      }, err => {
        console.error(err);
      });
  }

  removeChat(index) {
    this.activeChats.splice(index, 1);
    localStorage.setItem('activeChats', JSON.stringify(this.activeChats));
    return;
    Swal.fire({
      // title: 'Are you sure!',
      text: this.translate.instant('chat.Do you want to end this conversation?'),
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: this.translate.instant('swalMsg.yes'),
      cancelButtonText: this.translate.instant('swalMsg.no'),
    }).then((result) => {
      if (result.value) {
        this.activeChats.splice(index, 1);
      }
    });
  }

  send(chat) {
    const self = this;
    if (chat.message) {
      this.chatService.SendChatMessage(chat.user.id, chat.message).subscribe(
        result => {
          let newMessage = new Message();
          newMessage = {
            mine: true,
            text: chat.message,
            time: new Date(),
            isSeen: true
          };
          chat.msgList.push(newMessage);
          chat.message = '';
          setTimeout(() => {
            if (document.querySelectorAll('#chat-' + this.activeChats.indexOf(chat) + ' ul').length) {
              document.getElementById('chat-' + this.activeChats.indexOf(chat)).scrollTop =
                document.querySelectorAll('#chat-' + this.activeChats.indexOf(chat) + ' ul')[0].clientHeight;
            }
          }, 100);
        }, err => {
          console.error(err);
        });
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
}
