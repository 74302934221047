import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { Meeting } from 'src/app/models/meeting/Meeting';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SlidesService } from 'src/app/services/slides.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-link-slide-agenda',
  templateUrl: './link-slide-agenda.component.html'
})
export class LinkSlideAgendaComponent implements OnInit {
  @Input() meeting: Meeting;
  @Input() slide: any;

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();

  agendaItemId: number;
  loading = false;

  itemsLoading = false;
  agendItems: AgendaItem[] = [];
  isSubmited = false;

  constructor(
    private service: SlidesService,
    private detailsService: MeetingDetailsService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.listAgendItems();
  }
  listAgendItems() {
    const obj = {
      id: this.meeting.meetingId,
      PageNumber: 0,
      PageSize: 100
    };
    this.itemsLoading = true;
    this.detailsService.ListAgendItems(obj).subscribe(
      (result: any) => {
        this.itemsLoading = false;
        this.agendItems = result.pageData as AgendaItem[];
      },
      err => {
        this.itemsLoading = false;
        console.error(err);
      }
    );
  }

  save() {
    this.isSubmited = true;
    if (this.agendaItemId) {
      this.loading = true;
      let data = {
        agendaItemId: this.agendaItemId,
        slideId: this.slide.id,
        isLinked: true
      }
      this.service.LinkUnLinkAgendaItemSlide(data).subscribe(
        data => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('swalMsg.linkedSuccessfully'),
            text: '',
            icon: 'success',
            confirmButtonText: this.translate.instant('swalMsg.ok'),
          });
          this.closePopAndSave.emit();
        }, err => {
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: '',
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
          this.loading = false;
        });
    }
  }
  closePop() {
    this.cancelPop.emit();
  }
}
