import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';

@Component({
  selector: 'app-agenda-form',
  templateUrl: './agenda-form.component.html',
  styles: []
})
export class AgendaFormComponent implements OnInit {

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() item: AgendaItem;
  @Input() meetingId;
  @Input() itemsCount: number;
  loading = false;
  formGroup: FormGroup;
  files = [];
  deletedFiles = [];

  genders = [
    { name: 'agendaCase.male', id: 'male' },
    { name: 'agendaCase.female', id: 'female' },
  ];

  isCase = false;
  minDate = new Date();
  maxDate = new Date();
  isValidDate = true;

  diagnosisList!: any[];
  diagnosisListLoading = false;

  referredFromList!: any[];

  language = localStorage.getItem('language') || 'en';

  constructor(
    private detailsService: MeetingDetailsService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      title: ['', [Validators.required]],
      // itemOrder: [''],
      mrn: [''],
      nationalId: [''],
      pationtName: [''],
      dateOfBirth: [''],
      gender: [''],
      diagnosisId: [''],
      meetingAgendaItemId: [''],
      referredFromId: ['']
    });
    this.getDiagnosisList();
    this.getReferredFromList();
    this.isCase = false;
    if (this.item) {
      this.formGroup.controls.title.setValue(this.item.title);
      // this.formGroup.controls.itemOrder.setValue(this.item.itemOrder);
      if (this.item.madicalCases) {
        this.isCase = true;
        this.formGroup.controls.mrn.setValue(this.item.madicalCases.mrn);
        this.formGroup.controls.nationalId.setValue(this.item.madicalCases.nationalId);
        this.formGroup.controls.pationtName.setValue(this.item.madicalCases.pationtName);
        this.formGroup.controls.dateOfBirth.setValue(this.item.madicalCases.dateOfBirth);
        this.formGroup.controls.gender.setValue(this.item.madicalCases.gender);
        this.formGroup.controls.diagnosisId.setValue(this.item.madicalCases.diagnosisId);
        this.formGroup.controls.referredFromId.setValue(this.item.madicalCases.referredFromId);
      }
      this.files = JSON.parse(JSON.stringify(this.item.attachments));
      this.files = this.files.filter(item => !item.isDeleted);
    }
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  getDiagnosisList() {
    this.diagnosisListLoading = true;
    this.detailsService.getDiagnosisList().subscribe((data: any) => {
      this.diagnosisList = data;
      this.diagnosisListLoading = false;
    })
  }

  getReferredFromList() {
    this.detailsService.ReferredFromDtoList().subscribe((data: any) => {
      this.referredFromList = data;
    })
  }

  saveItem() {
    if (this.formGroup.valid) {
      this.loading = true;
      let agendaItem;
      agendaItem = {
        title: this.formGroup.controls.title.value,
        // itemOrder: this.formGroup.controls.itemOrder.value,
        madicalCases: null
      };
      if (this.item) {
        agendaItem.agendaItemEntityId = this.item.agendaItemEntityId;
        agendaItem.deletedAttachments = this.deletedFiles;
      } else {
        agendaItem.meetingEntityId = this.meetingId;
        // agendaItem.itemOrder = this.itemsCount + 1;
      }

      if (this.isCase) {
        agendaItem.madicalCases = {
          mrn: this.formGroup.controls.mrn.value,
          nationalId: this.formGroup.controls.nationalId.value,
          pationtName: this.formGroup.controls.pationtName.value,
          dateOfBirth: this.formGroup.controls.dateOfBirth.value,
          gender: this.formGroup.controls.gender.value,
          diagnosisId: this.formGroup.controls.diagnosisId.value,
          referredFromId: this.formGroup.controls.referredFromId.value,

          meetingAgendaItemId: this.item ? this.item.agendaItemEntityId : 0,
          id: (this.item && this.item.madicalCases) ? this.item.madicalCases.id : 0
        }
      }
      this.detailsService.saveMeetingAgendaItem(agendaItem, this.files).subscribe(
        data => {
          this.loading = false;
          this.closePopAndSave.emit();
          if (this.item && this.item.pathologySlides) {
            this.detailsService.refreshSlides.next(true);
          }
        }, err => {
          console.error(err);
        });
    }
  }
  closePop() {
    this.cancelPop.emit();
  }
  counter(i: number) {
    return new Array(i);
  }

  validateDates(value) {
    this.isValidDate = true;
    if (new Date(value) < new Date()) {
      this.isValidDate = false;
    }
  }
}
