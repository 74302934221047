import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  lang = localStorage.getItem('language') || 'en';
    constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private snackBar: MatSnackBar
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                let currentUser;
                currentUser = this.authenticationService.currentUserValue;
                if (currentUser.rememberMe) {
                    this.authenticationService.RefreshToken();
                } else {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    this.authenticationService.clearUserSession();
                }
            }
            if (err.status === 404) {
              this.router.navigate(['/admin/myprofile']);
            }
            if (err.status === 0) { // Offline
              this.snackBar.open(
                this.lang == 'en' ? 'You are offline!' : 'أنت غير متصل!',
                this.lang == 'en' ? 'Ok' : 'حسنا',
                {
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });
            }

            // const error = err.error.message || err.statusText;
            return throwError(err.error);
        }));
    }
}
