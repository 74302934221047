import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'agoTime'
})
export class AgoTimePipe implements PipeTransform {


  language = localStorage.getItem('language') || 'en';
  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(this.language);
  }
  transform(value: any, ...args: any[]): any {
    var values = value.split(" ");
    // console.log(values);
    // console.log(values[0]);
    // console.log(values[1]);
    // console.log(values[2]);
    var f_value = values[0];
    var l_value = values[1] ? value.substr(value.indexOf(' ') + 1) : '';
    // console.log( l_value);
    return l_value;
  }

}

