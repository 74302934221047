import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-surgery',
  templateUrl: './surgery.component.html'
})
export class SurgeryComponent implements OnInit {

  surgeryList!: any;
  totalCount!: number;
  @Input() meetingAgendaItemId!: any;
  @Input() meetingId!: any;
  @Input() liveView!: boolean;
  dataLoading = false;

  openForm = false;
  selectedSurgery: any;
  surgeryId: any;

  constructor(private meetingService: MeetingDetailsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getSurgeryList();
    this.meetingService.refreshSlides.subscribe(data => {
      if (data) {
        this.getSurgeryList();
      }
    });
  }

  getSurgeryList() {
    this.dataLoading = true;
    let filter = {
      pageNumber: 0,
      pageSize: 200,
      meetingAgendaItemId: this.meetingAgendaItemId
    }
    this.meetingService.getSurgery(filter).subscribe((data: any) => {
      this.surgeryList = data;
      this.totalCount = data.totalCount;
      this.dataLoading = false;
    })
  }

  deleteSurgery(id) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.meetingService.DeleteSurgery(id).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.meetingService.refreshSlides.next(true);
          },
          error => {
            console.log('Try again!, ' + error);
          });
      }
    });
  }

  openSurgeryForm(item) {
    this.openForm = true;
    this.selectedSurgery = item;
    this.surgeryId = item.id;
  }

  saveItemSucsses() {
    this.openForm = false;
    Swal.fire({
      title: this.translate.instant('swalMsg.addedsuccessfully'),
      text: '',
      icon: 'success',
      confirmButtonText: this.translate.instant('swalMsg.ok'),
    }).then((result) => {
      this.getSurgeryList();
    });
  }

}
