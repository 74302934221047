import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { NotificationsSearchModel } from 'src/app/models/notification/NotificationsSearchModel';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  GetNotifications(searchModel: NotificationsSearchModel) {
    return this.http.post(`${Config.apiUrl}/notifications/Notifications`, searchModel);
  }
  UpdateNotificationsSeenStatus() {
    return this.http.get(`${Config.apiUrl}/notifications/UpdateNotificationsSeenStatus`);
  }
}
