import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  menuItems: any;
  menuLoaded = false;

  lang = localStorage.getItem('language') || 'en';
  moduleLoading: boolean = false;
  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sidebarService: SidebarService,
    private router: Router
  ) {
    router.events.subscribe((event: any) => {
      this.navigationInterceptor(event);
    });


    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      let ref = this.snackBar.open(this.lang == 'en' ? 'You are back online!' : 'أنت متصل بالإنترنت مرة أخرى!', this.lang == 'en' ? 'Reload' : 'إعادة تحميل', {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      ref.afterDismissed().subscribe(info => {
        if (info.dismissedByAction === true) {
          window.location.reload()
        }
      });
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.snackBar.open(this.lang == 'en' ? 'You are offline!' : 'أنت غير متصل!', this.lang == 'en' ? 'Ok' : 'حسنا', {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }));
  }

  ngOnInit(): void {
    this.sidebarService.menuLoaded.subscribe(data => {
      if (data) {
        this.menuLoaded = true;
      }
    });
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string= '', breadcrumbs: any = []): [] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[MainLayoutComponent.ROUTE_DATA_BREADCRUMB];
      let title = '';
      if (child.snapshot.data.title) {
        title = child.snapshot.data.title;
      }
      if (label) {
        if (!isNullOrUndefined(label)) {
          breadcrumbs.push({label, url, title});
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }


  // Shows and hides the moduleLoading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.moduleLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.moduleLoading = false;
        this.snackBar.dismiss();
      }, 100);
    }

    // Set moduleLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
  }
}
