import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-voting-tabs',
  templateUrl: './voting-tabs.component.html',
  styles: []
})
export class VotingTabsComponent implements OnInit {
  language = localStorage.getItem('language') || 'en';
  @Input() item: AgendaItem = new AgendaItem();
  @Input() stopedClicked: boolean;
  @Input() availableTime;
  @Output() openTaskForm: EventEmitter<any> = new EventEmitter();

  currentTabVal = 0;
  tabs = [
    {label: this.translate.instant('meetings.AttendeesAnswers'), value: 0},
    {label: this.translate.instant('meetings.Summary'), value: 1}
  ];

  listItem: string;
  terms = [];
  itemLoading = false;

  recommendsLoader = false;
  recommendsDeleteId: number;
  recommendsResult: any;

  votingLoader = false;
  votingResult: any;

  @Output() canEndVote: EventEmitter<any> = new EventEmitter();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private signalRService: SignalRServiceService,
    private detailsService: MeetingDetailsService) {
      this.signalRService.buildConnection();
    }

  ngOnInit() {
    // this.listAgendItemRecommendations();
    this.listAgendItemVoting();
    this.signalRService.handleNewVotingEvent(this, this.updateVoting);
  }
  updateVoting(context, agendaItemId, canEndVote) {
    context.canEndVote.emit(canEndVote);
    if (context.item.agendaItemEntityId === agendaItemId) {
      context.listAgendItemVoting();
    }
    context.changeDetectorRef.detectChanges();
  }
  listAgendItemRecommendations() {
    this.recommendsLoader = true;
    this.detailsService.ListAgendItemRecommendations(this.item.agendaItemEntityId).subscribe(
      data => {
        this.recommendsLoader = false;
        this.terms = data as [];
        if (this.terms.length && this.tabs.length !== 3) {
          this.tabs.push({label: this.translate.instant('meetings.Recommendations'), value: 2}); // generate recom. tab
        }
      }, err => {
        console.error(err);
      });
  }
  listAgendItemVoting() {
    this.votingLoader = true;
    this.detailsService.ListAgendItemVoting(this.item.agendaItemEntityId).subscribe(
      data => {
        this.votingLoader = false;
        this.votingResult = data;
      }, err => {
        console.error(err);
      });
  }
  addRecommendations() {
    this.tabs.push({label: this.translate.instant('meetings.Recommendations'), value: 2});
    this.currentTabVal = 2;
  }
  addListItem(event) {
    event.preventDefault();
    if (this.terms.length < 5) {
      if (this.listItem) {
        const obj = {
          AgendaItemEntityId: this.item.agendaItemEntityId,
          RecommendationText: this.listItem
        };
        this.itemLoading = true;
        this.detailsService.AddRecommendation(obj).subscribe(
          data => {
            this.itemLoading = false;
            // this.terms.push({ title: this.listItem });
            this.listAgendItemRecommendations();
            this.listItem = undefined;
          },
          error => {
            console.log('Try again!, ' + error);
          });
      }
    }
  }
  removeListItem(index, id) {
    this.recommendsDeleteId = id;
    // this.detailsService.refreshAgendaItemSubject.next(true);
    this.detailsService.DeleteRecommendation(id).subscribe(
      data => {
        this.recommendsDeleteId = undefined;
        this.listAgendItemRecommendations();
        // this.terms.splice(index, 1);
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  updateListItem(index, event) {
    this.terms[index].title = event.target.value;
  }
  openCreateTask(title) {
    this.openTaskForm.emit({open: true, title});
  }
  textAreaAdjust(event) {
    event.target.style.height = '22px';
    event.target.style.height = event.target.scrollHeight + 'px';
  }
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

}
