import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { ActivatedRoute } from '@angular/router';
import { MeetingDetailsService } from '../services/meeting-details.service';
import { InviteeMeetingInvitaionStatus } from '../models/meeting/InviteeMeetingInvitaionStatus';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @ViewChild('pdfContainer', { static: true }) pdfContainer: ElementRef<HTMLDivElement>;
  @ViewChild('textDropdown', { static: true }) textDropdown: ElementRef<HTMLSelectElement>;

  dataLoading = true;
  loading: string;
  documentId: number;
  agendaItemId: number;
  meetingId: number;

  details: any;

  private pdfDoc: any;
  private pdfLibDoc: PDFDocument;
  private pageRefs: any[] = [];
  private insertedOptions: {
    text: string,
    userId: number,
    isSigned?: boolean,
    signatureImg?: string,
    userName?: string,

    x: number,
    y: number,
    pageIndex: number
  }[] = [];
  scale = 2;

  optionsList: any[] = [];
  showUsersPopup = false;
  selectedUser: any = null;

  clickPosition = { x: 0, y: 0 };
  signatureDim = { width: 100, hegiht: 100 };
  selectedPageIndex = 0;

  isConfig = false;
  searchUser = '';

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private service: MeetingDetailsService,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.route.paramMap.subscribe(params => {
      this.documentId = Number(params.get('documentId'));
      this.agendaItemId = Number(params.get('agendaItemId'));
      this.meetingId = Number(params.get('meetingId'));
      this.getDetails();
      if (this.meetingId) {
        this.isConfig = true;
        this.listInvitees();
      }
    });
  }

  ngOnInit() {
  }

  getDetails() {
    let data = {
      agendaItemId: this.agendaItemId,
      documentId: this.documentId
    }
    this.service.GetAgindaDocumentDetails(data).subscribe(
      (result: any) => {
        if (result) {
          this.dataLoading = false;
          this.details = result;
          if (result.attachment.path) {
            this.loadPdf(result.attachment.path);
          }
        }
      },
      err => {
        this.dataLoading = false;
        console.error(err);
      }
    );
  }
  listInvitees() {
    let inviteeFilter: any = {};
    inviteeFilter.pageNumber = 0;
    inviteeFilter.pageSize = 100;
    inviteeFilter.inviteeStatus = InviteeMeetingInvitaionStatus.Accept;
    inviteeFilter.meetingId = this.meetingId;
    inviteeFilter.isAttendeeView = false;
    this.service.ListInvitees(inviteeFilter).subscribe(
      result => {
        this.optionsList = result.pageData;
      },
      err => {
        console.error(err);
      }
    );
  }
  saveConfig(reset = false) {
    this.loading = reset ? 'reset' : 'save';
    if (reset) { this.insertedOptions = [] }
    let data = {
      meetingId: this.meetingId,
      documentId: this.documentId,
      users: this.insertedOptions
    }
    this.service.SaveAgindaDocumentSignature(data).subscribe(
      result => {
        this.loading = '';
        if (reset) {
          this.pdfContainer.nativeElement.innerHTML = '';
          this.getDetails();
        }
        Swal.fire({
          title: this.translate.instant('swalMsg.success'),
          text: this.translate.instant('swalMsg.savedsuccessfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok'),
        })
      },
      err => {
        this.loading = '';
        console.error(err);
      }
    );
  }

  async loadPdf(url: string) {
    this.dataLoading = true;
    const loadingTask = pdfjsLib.getDocument(url);
    this.pdfDoc = await loadingTask.promise;
    const pdfBytes = await fetch(url).then(res => res.arrayBuffer());
    this.pdfLibDoc = await PDFDocument.load(pdfBytes);

    for (let i = 1; i <= this.pdfDoc.numPages; i++) {
      const page = await this.pdfDoc.getPage(i);
      this.pageRefs.push(page);
      await this.renderPage(i, page);
    }

    if (this.isConfig) {
      this.bindConfiguration();
    } else {
      this.insertedOptions = this.details.users;
      this.applySignature();
    }
    this.dataLoading = true;
  }

  async renderPage(pageNum: number, page: any) {
    const viewport = page.getViewport({ scale: this.scale });
    const canvas = this.renderer.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    this.renderer.listen(canvas, 'click', (event) => this.onCanvasClick(event, pageNum - 1));

    const pdfContainer = this.pdfContainer.nativeElement;
    this.renderer.appendChild(pdfContainer, canvas);
    await page.render(renderContext).promise;
  }

  async bindConfiguration() {
    this.dataLoading = true;
    setTimeout(() => {
      this.details.users.forEach(element => {
        this.addTextToDoc(element);
      });
      this.dataLoading = false;
    }, 1000);
  }
  addTextToDoc(selectedOption) {
    let selectedPage = Number(this.selectedPageIndex || selectedOption.pageIndex || 0);
    const canvas = <HTMLCanvasElement>this.pdfContainer.nativeElement.children[selectedPage];
    const context = canvas.getContext('2d');
    context.fillText(
      selectedOption.fullName || selectedOption.userName,
      Number(this.clickPosition.x || selectedOption.x),
      Number(this.clickPosition.y || selectedOption.y)
    );
    this.insertedOptions.push({
      text: selectedOption.fullName,
      userId: selectedOption.userId,
      signatureImg: selectedOption.signatureImg,
      x: this.clickPosition.x || selectedOption.x,
      y: this.clickPosition.y || selectedOption.y,
      pageIndex: selectedPage
    });
  }

  async applySignature() {
    this.dataLoading = true;
    const pages = this.pdfLibDoc.getPages();

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = <HTMLCanvasElement>this.pdfContainer.nativeElement.children[i];
      const imageData = canvas.toDataURL('image/png');
      const pngImageBytes = await fetch(imageData).then(res => res.arrayBuffer());
      const pngImage = await this.pdfLibDoc.embedPng(pngImageBytes);

      const { width, height } = page.getSize();
      page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: width,
        height: height,
      });

      for (const option of this.insertedOptions) {
        if (Number(option.pageIndex) === i && option.signatureImg && option.isSigned) {
          let imageBase64 = 'data:image/png;base64,' + option.signatureImg;
          const embeddedImage = await this.pdfLibDoc.embedPng(imageBase64);
          page.drawImage(embeddedImage, {
            x: Number(option.x) / this.scale,
            y: height - (Number(option.y) / this.scale) - (this.signatureDim.hegiht / 2),
            width: this.signatureDim.width,
            height: this.signatureDim.hegiht
          });
        }
      };
    }

    const pdfBytes = await this.pdfLibDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl);

    this.dataLoading = false;
    history.back();
  }

  onCanvasClick(event: MouseEvent, pageIndex: number) {
    var rect = document.getElementsByTagName('canvas')[pageIndex].getBoundingClientRect();
    this.clickPosition = { x: event.clientX - rect.left, y: event.clientY - rect.top };
    this.showUsersPopup = true;
    this.selectedPageIndex = pageIndex;
  }

  selectChanged() {
    if (this.selectedUser) {
      this.addTextToDoc(this.selectedUser);
      this.showUsersPopup = false;
      this.selectedUser = null;

      this.snackBar.open(
        this.translate.instant('swalMsg.addedsuccessfully'),
        this.translate.instant('swalMsg.ok'),
        {
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top'
        }
      );
    }
  }

  hideOption(item: any) {
    return new RegExp(this.searchUser, 'i').test(item.fullName) === false
  }
}
