import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ViewerSlide } from '../../slides-viewer.component';
import { ViewerService } from '../../services/Viewer.service';
import { ListFilterResult } from '../../models/ListFilterResult';
import { AppType } from '../../models/enums/AppType';

@Component({
  selector: 'app-viewer-detection-result',
  templateUrl: './viewer-detection-result.component.html'
})
export class ViewerDetectionResultComponent implements OnInit, OnChanges, OnDestroy {
  @Output() hideDetection = new EventEmitter<boolean>();
  @Output() zoomEvent = new EventEmitter<boolean>();
  @Input() slide: ViewerSlide;
  @Input() scrollToId: any;
  dataLoading = false;
  pageNumber = 1;
  pageSize = 10;
  pageCount: number;
  tHeader = [];
  recordData = [];
  recordDataWorket = [];
  selectedIndex: number;
  @ViewChild('slidebarContentResult', { static: false }) slidebarContentResult: ElementRef<HTMLElement>;
  interval;

  constructor(private service: ViewerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.slide && changes.slide.currentValue) {
      this.getDetectionMeasurmentsResult();
    }
  }

  ngOnInit() {
  }

  hideDetectionResults() {
    this.hideDetection.emit(true);
  }

  getDetectionMeasurmentsResult() {
    this.dataLoading = true;
    let query = {
      slideId: this.slide.id
    } as ListFilterResult;
    if (this.slide.filterModel) {
      query = {
        slideId: this.slide.id,
        ...this.slide.filterModel
      };
    } else {
      if (this.slide.details && this.slide.details.currentDetectionAnnotations && this.slide.details.currentDetectionAnnotations.length) {
        if (this.slide.details.aiCurrentApp === AppType.VISIOPHARM) {
          query.RoisIDs = this.slide.details.currentDetectionAnnotations.map(item => item.id.replace('#', ''));
        } else {
          query.currentAnnotations = this.slide.details.currentDetectionAnnotations.map(item => item.id.replace('#', '')).join(',');
        }
        if (this.slide.details.aiAppDetails) {
          query.AppId = this.slide.details.aiAppDetails.id;
        }
      }
    }
    this.service.GetDetectionMeasurmentsResult(query).subscribe(res => {
      for (const [key, value] of Object.entries(res)) {
        if (Array.isArray(value) && key) {
          this.tHeader.push(key);
          value.map((item, index) => {
            if (this.recordDataWorket[index]) {
              this.recordDataWorket[index][key] = item;
            } else {
              this.recordDataWorket.push({
                [key]: item
              })
            }
          })
        }
      }
      this.recordData = this.recordDataWorket.splice(0, 100);
      this.interval = setInterval(() => {
        this.getNextPage();
      }, 100);
      this.pageCount = Math.ceil(this.recordDataWorket.length / this.pageSize);
      console.log(this.pageCount)
      this.dataLoading = false;
    }, err => {
      this.dataLoading = false;
    })
  }

  scrollToDataRow() {
    if (document.querySelectorAll('.wrap-datatable').length && this.slidebarContentResult) {
      const firstDataRow: HTMLElement = this.slidebarContentResult.nativeElement.querySelector(
        'table #tr-' + this.scrollToId.replace('#', '')
      );
      document.getElementById('slidebarContentResult').scrollTop = firstDataRow.offsetTop - (firstDataRow.clientHeight * 4);
    }
  }

  zoomToDetection(record, index: number) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = null;
    }
    this.zoomEvent.emit(record['Object ID']);
  }

  getNextPage() {
    if (this.pageNumber <= this.pageCount - 1 && this.recordDataWorket.length) {
      this.pageNumber += 1;
      this.recordData = this.recordData.concat(this.recordDataWorket.splice(0, 100));
    } else {
      clearInterval(this.interval);
    }
  }

  ngOnDestroy(): void {
    this.selectedIndex = null;
  }

}
