import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VotingListData, VotingView } from 'src/app/partial/agenda-list/agenda-item/case-voting/Voting.models';
import { CaseService } from 'src/app/services/Case.service';

@Component({
  selector: 'app-treatment-plans',
  templateUrl: './treatment-plans.component.html'
})
export class TreatmentPlansComponent implements OnInit {
  @Input() meetingAgendaItemId: number;

  dataLoading = false;
  language = localStorage.getItem('language') || 'en';

  currentTabVal = 0;
  tabs = [
    { label: this.translate.instant('case.Attendees Votes'), value: 0 },
    { label: this.translate.instant('meetings.Summary'), value: 1 }
  ];
  
  votingListData: VotingListData;

  constructor(
    private service: CaseService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cdr.detectChanges();  // Force change detection to update the view
    }, 0);
  }

  ngOnInit() {
    this.listAllVotes();
  }


  listAllVotes() {
    this.dataLoading = true;
    this.service.listAllVotes(this.meetingAgendaItemId, VotingView.Plan).subscribe(
      (response: VotingListData) => {
        this.dataLoading = false;
        this.votingListData = response;
      }, error => {
        console.log('Faild');
      });
  }
}
