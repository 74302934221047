import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from 'src/app/services/Case.service';
import { LookupsService } from 'src/app/services/lookups.service';
import Swal from 'sweetalert2';

export interface DialogData {
  record: any,
  agendaItemId: any
}
@Component({
  selector: 'app-treatment-form',
  templateUrl: './treatment-form.component.html'
})
export class TreatmentFormComponent implements OnInit {
  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  record = this.data.record;
  agendaItemId = this.data.agendaItemId;
  isUpdate: boolean = false;

  loading = false;
  formGroup: FormGroup;
  isSubmited = false;

  dataLoading = false;

  phases = [];
  fields = [];
  files: any[] = [];
  today = new Date();

  selectedViewValue: any;

  constructor(
    private dialogRef: MatDialogRef<TreatmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private service: CaseService,
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
    private translate: TranslateService
  ) { }

  closePop() {
    this.cancelPop.emit();
  }

  ngOnInit() {
    this.MedicalTreatmentPhases();
    this.buildForm();
    if (this.record) {
      this.isUpdate = true;
      this.bindData();
    }
  }

  MedicalTreatmentPhases() {
    this.dataLoading = true;
    this.lookupsService.MedicalTreatmentPhases().subscribe(
      resp => {
        this.dataLoading = false;
        this.phases = resp;
        this.selectedViewValue = this.phases.find(p => p.id == this.formGroup.controls['medicalPhaseId'].value);
      }, err => {
        console.error(err);
        this.loading = false;
      });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      id: [this.record ? this.record.id : 0],
      agendaItemId: [this.agendaItemId],
      medicalPhaseId: [null, Validators.required],
      medicalPhasefields: this.formBuilder.array([]),
      cycleNumber: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      additionalNotes: [null, Validators.required]
    });
  }

  bindData() {
    this.formGroup.patchValue(this.record);
    this.formGroup.controls['medicalPhaseId'].setValue(this.record.medicalPhase.id);
    this.fields = this.record.medicalPhasefields;
    this.createFields();
  }

  get medicalPhasefieldsArray() {
    return this.formGroup.get("medicalPhasefields") as FormArray;
  }
  fieldHasError(controlName: string, index: number, errorName: string) {
    return (this.medicalPhasefieldsArray.controls[index] as FormGroup).controls[controlName].hasError(errorName);
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  handleFields() {
    this.fields = [];
    this.clearFormArray(this.medicalPhasefieldsArray);
    const selectedPhase = this.phases.find(p => p.id == this.formGroup.controls['medicalPhaseId'].value);
    this.selectedViewValue = selectedPhase;
    if (selectedPhase) {
      this.fields = selectedPhase.medicalPhasefields;
    }
    setTimeout(() => {
      this.createFields();
    }, 100);
  }
  createFields() {
    if (this.fields && this.fields.length) {
      this.fields.forEach((field, index) => {
        if (field.fieldType == 'list') {
          this.getFieldData(index);
          field.fieldValue = Number(field.fieldValue);
        }
        this.medicalPhasefieldsArray.push(
          this.formBuilder.group({
            fieldId: [field.fieldId],
            fieldValue: [
              field.fieldValue,
              field.isRequired ? [Validators.required] : []
            ]
          })
        );
      });
    }
  }

  getFieldData(index) {
    this.fields[index].loading = true;
    if (!this.fields[index].lookupApi) return;
    this.service.getFieldData(this.fields[index].lookupApi).subscribe(
      resp => {
        this.fields[index].loading = false;
        this.fields[index].list = resp;
      }, err => {
        console.error(err);
        this.loading = false;
      });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  save() {
    this.isSubmited = true;

    if (this.formGroup.valid) {
      let data = this.formGroup.value;
      this.loading = true;
      this.service.saveAgendItemTreatment(data).subscribe(
        data => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('swalMsg.savedsuccessfully'),
            text: '',
            icon: 'success',
            confirmButtonText: this.translate.instant('swalMsg.ok'),
          });
          this.dialogRef.close(true);
        }, err => {
          Swal.fire({
            title: this.translate.instant('swalMsg.Failed'),
            text: '',
            icon: 'error',
            confirmButtonText: this.translate.instant('swalMsg.ok')
          });
          this.loading = false;
        });
    }
  }
}
