import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { AppType } from '../models/enums/AppType';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(private http: HttpClient) { }

  GetSlideView(params): any {
    return this.http.get(`${Config.apiUrl}/Meetings/GetSlideView`, { params });
  }
  GetVisioPharmResult(params) {
    return this.http.get(`${Config.apiUrl}/Meetings/GetVisioPharmResult?${this.getParams(params).toString()}`);
  }
  GetCellCountResult(params) {
    return this.http.get(`${Config.apiUrl}/Meetings/GetCellCountResult`, { params });
  }
  GetDetectionMainResult(params) {
    return this.http.get(`${Config.apiUrl}/Meetings/GetDetectionMainResult`, { params });
  }
  GetDetectionMeasurmentsResult(params) {
    return this.http.get(`${Config.apiUrl}/Meetings/GetDetectionMeasurmentsResult`, { params });
  }
  getResult(params: any, appType: AppType) {
    if (appType === AppType.VISIOPHARM) {
      return this.GetVisioPharmResult(params);
    } else if (appType === AppType.QUPATH) {
      return this.GetDetectionMainResult(params);
    } else {
      return this.GetCellCountResult(params);
    }
  }
  getComments(params): any {
    return this.http.get(`${Config.apiUrl}/Meetings/GetSlideViewComments`, { params });
  }


  getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    return params;
  }
  convertRectangleCordinates(cordinatesString: string, repeat = false) {
    let splitstring = cordinatesString.split(":");
    let stringArray = splitstring[1].split(",");
    let cordinate = [];
    stringArray.forEach(cordinte => {
      cordinate.push(parseInt(cordinte));
    });
    let x1 = cordinate[0];
    let y1 = cordinate[1];
    let width = cordinate[2];
    let height = cordinate[3];
    let x2 = width + x1;
    let y2 = height + y1;
    let rectanglePoits = [[x1, y1], [x2, y2]];
    let coordinates = [];
    let cordinatesSaveInDatabase = [rectanglePoits[0], [rectanglePoits[1][0], rectanglePoits[0][1]], rectanglePoits[1], [rectanglePoits[0][0], rectanglePoits[1][1]]];
    cordinatesSaveInDatabase.forEach((coordinate) => {
      coordinates.push([coordinate[0], coordinate[1]]);
    })
    if (repeat) { coordinates.push(coordinates[0]); }
    return coordinates;
  }

  convertPolygonToCordinates(cordinatestring: string, repeat = false) {
    let cordinatesString = [];
    const SubString = cordinatestring.substring(
      cordinatestring.indexOf("\"") + 1,
      cordinatestring.lastIndexOf("\"")
    );
    let splitThird = SubString.split(" ");
    splitThird.forEach((splitdata) => {
      let splitcordi = splitdata.split(",");
      cordinatesString.push([parseInt(splitcordi[0]), parseInt(splitcordi[1])]);
    });
    if (repeat) { cordinatesString.push(cordinatesString[0]); }
    return cordinatesString;
  }

  convertCircleToCordinates(cordinatestring: string, repeat = false) {
    const SubString = cordinatestring.substring(
      cordinatestring.indexOf("\""),
      cordinatestring.lastIndexOf("\"") + 1
    ).split(" ").map(item => item.substring(
      item.indexOf("\"") + 1,
      item.lastIndexOf("\"")
    ));

    const centerX = Number(SubString[0]);
    const centerY = Number(SubString[1]);
    const radius = Number(SubString[2]);

    // Define the number of sides for the polygon approximation
    const numSides = 100; // Adjust this value to control the smoothness of the resulting polygon

    // Calculate the angle between each side of the polygon
    const angleStep = (1 * Math.PI) / (numSides / 2);
    // Create an array to store the polygon vertices
    const polygonVertices = [];

    // Generate the vertices of the polygon by evenly distributing them around the circle
    for (let i = 0; i < numSides; i++) {
      const angle = i * angleStep;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      polygonVertices.push([x, y]);
    }
    if (repeat) { polygonVertices.push(polygonVertices[0]); }
    return polygonVertices;
  }

  convertEllipseToCordinates(cordinatestring: string, repeat = false) {
    const SubString = cordinatestring.substring(
      cordinatestring.indexOf("\""),
      cordinatestring.lastIndexOf("\"") + 1
    ).split(" ").map(item => item.substring(
      item.indexOf("\"") + 1,
      item.lastIndexOf("\"")
    ));

    const centerX = Number(SubString[0]);
    const centerY = Number(SubString[1]);
    const radiusX = Number(SubString[2]);
    const radiusY = Number(SubString[3]);

    // Define the number of sides for the polygon approximation
    const numSides = 100; // Adjust this value to control the smoothness of the resulting polygon

    // Calculate the angle between each side of the polygon
    const angleStep = (1 * Math.PI) / (numSides / 2);
    // Create an array to store the polygon vertices
    const polygonVertices = [];

    // Generate the vertices of the polygon by evenly distributing them around the circle
    for (let i = 0; i < numSides; i++) {
      const angle = i * angleStep;
      const x = centerX + radiusX * Math.cos(angle);
      const y = centerY + radiusY * Math.sin(angle);
      polygonVertices.push([x, y]);
    }
    if (repeat) { polygonVertices.push(polygonVertices[0]); }
    return polygonVertices;
  }

  convertSvgPathToPolygon(svgPath, repeat = false) {
    const pathParser = new DOMParser();
    const pathDoc = pathParser.parseFromString(svgPath, 'image/svg+xml');
    const pathElement = pathDoc.querySelector('path');
    const pathData = pathElement.getAttribute('d');

    // Extract the coordinates from the parsed path data
    const coordinates = [];
    const pathSegments = pathData.split(/[A-Za-z]/).filter(Boolean);
    pathSegments.forEach(function (segment) {
      const segmentCoordinates = segment.trim().split(/[ ,]+/);
      const x = parseFloat(segmentCoordinates[segmentCoordinates.length - 2]);
      const y = parseFloat(segmentCoordinates[segmentCoordinates.length - 1]);
      coordinates.push([x, y]);
    });

    if (repeat) { coordinates.push(coordinates[0]); }

    return coordinates;
  }
}
