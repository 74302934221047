import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';

@Component({
  selector: 'app-clinical-visit-form',
  templateUrl: './clinical-visit-form.component.html'
})
export class ClinicalVisitFormComponent implements OnInit {

  clinicalTypes!: any;
  clinicTypeLoading = false;

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() item: any;
  @Input() meetingAgendaItemId: any;
  @Input() meetingId: any;
  @Input() clinicalVisitId: any;
  loading = false;
  formGroup: FormGroup;
  files = [];
  deletedFiles = [];

  genders = [
    { name: 'agendaCase.male', id: 'male' },
    { name: 'agendaCase.female', id: 'female' },
  ];

  isCase;
  minDate = new Date();
  maxDate: Date;
  isValidDate = true;
  language = localStorage.getItem('language') || 'en';

  constructor(
    private detailsService: MeetingDetailsService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.clinicTypeLookupList();
    this.formGroup = this.formBuilder.group({
      vNumber: ['', [Validators.required]],
      doctorName: ['', [Validators.required]],
      vdate: ['', [Validators.required]],
      clinicTypeLookupId: ['', [Validators.required]]
    });
    if (this.item) {
      this.formGroup.controls.vNumber.setValue(this.item.vNumber);
      this.formGroup.controls.doctorName.setValue(this.item.doctorName);
      this.formGroup.controls.vdate.setValue(this.item.vdate);
      this.formGroup.controls.clinicTypeLookupId.setValue(this.item.clinicTypeLookupId);
      this.files = JSON.parse(JSON.stringify(this.item.attachments));
      this.files = this.files.filter(item => !item.isDeleted);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  clinicTypeLookupList() {
    this.clinicTypeLoading = true;
    this.detailsService.clinicTypeLookupList('clinic').subscribe((data) => {
      this.clinicalTypes = data;
      this.clinicTypeLoading = false;
    })
  }

  saveItem() {

    if (this.formGroup.valid) {
      this.loading = true;
      let clinicalVisit;
      clinicalVisit = {
        vNumber: this.formGroup.controls.vNumber.value,
        doctorName: this.formGroup.controls.doctorName.value,
        vdate: this.formGroup.controls.vdate.value,
        clinicTypeLookupId: this.formGroup.controls.clinicTypeLookupId.value,
        meetingAgendaItemId: this.meetingAgendaItemId
      };

      if (this.item) {
        clinicalVisit.deletedAttachments = this.deletedFiles;
        clinicalVisit.id = this.clinicalVisitId;
      }
      this.detailsService.saveClinicalVisits(clinicalVisit, this.files).subscribe(
        data => {
          this.loading = false;
          this.closePopAndSave.emit();
          this.detailsService.refreshSlides.next(true);
        }, err => {
          console.error(err);
        });
    }
  }
  closePop() {
    this.cancelPop.emit();
  }
  counter(i: number) {
    return new Array(i);
  }

  clearDate(event) {
    event.stopPropagation();
    this.formGroup.controls.date.setValue('');
  }
  validateDates(value) {
    this.isValidDate = true;
    if (new Date(value) < new Date()) {
      this.isValidDate = false;
    }
  }

}
