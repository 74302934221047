import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ManageUsersService } from 'src/app/services/manage-users.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { Entity } from 'src/app/models/entity/Entity';
import { Group } from 'src/app/models/group/group';
import { AddUserModel } from 'src/app/models/user/add-user';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html'
})

export class MyProfileComponent implements OnInit {
  dataLoadingEntities = false;
  dataLoadingGroups = false;
  displayedColumns: string[] = ['image', 'entityName', 'cityOrCountry', 'representativeAdmin', 'joinedSince', 'tableOptions'];
  dataSource = new MatTableDataSource<Entity>();
  @ViewChild('paginator', { static: true }) paginator;
  @ViewChild('form', { static: false }) form;
  // userId: string = "7fc38ded-2185-4cba-974b-46deaf2edca9";
  userId: string;
  userInformation: any = {};
  entities: Entity[] = [];
  groups: Group[] = [];
  @ViewChild('paginatorGroup', { static: true }) paginatorGroup;
  dataSourceGroup: MatTableDataSource<Group> = new MatTableDataSource<Group>();
  obs: Observable<any>;
  totalCountGroups = 0;
  totalCountEntities = 0;
  pageNumber = 0;
  pageSize = 5;
  showErrorMsg = false;
  showModal = false;
  minDate = new Date(1950, 4, 22);
  maxDate = new Date(2002, 3, 22);
  date: any;
  UserGroupsObj: any = {
    id: '',
    pageSize: 8,
    pageNumber: 0
  };
  entitiesObj: any = {
    id: '',
    pageSize: 6,
    pageNumber: 0
  };
  userStatus = {
    status: 0,
    usersID: []
  }
  // update user
  public addUserForm: FormGroup;
  countries: any;
  cities: any;
  Nationalities: any;
  enableCity = false;
  updateUser = false;
  imageSrc: string;
  budge: string;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  fileToUpload: any = {};
  fileInputValue: string;
  formErrors: any = [];
  loading = false;
  newUserObject: AddUserModel = new AddUserModel();
  isProfile = false;
  loginUserName;
  entitesCount;
  groupCount;
  pageCountEntities = 0;
  pageCountGroups = 0;
  manualPageEntities: number;
  manualPageGroup: number;
  emailnameexist = false;
  countryKey: string = '';

  offlineLoader = false;

  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  isSuperAdmin = false;
  currentUser: any;

  visitorCheck = {
    visible: false,
    required: false
  };

  openSignatureForm = false;

  constructor(
    public translate: TranslateService,
    private MyprofileService: MyprofileService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ManageUserService: ManageUsersService,
    private router: Router) {

    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('userId')) {
        this.userId = params.get('userId');
        this.isProfile = false;
      } else {
        this.userId = '0';
        this.isProfile = true;
      }
      this.userStatus.usersID.push(this.userId);
      this.loginUserName = authenticationService.currentUserValue.name;
      // console.log(this.loginUserName);
    });
    this.UserGroupsObj.id = this.userId;
    this.entitiesObj.id = this.userId;
    this.currentUser = this.authenticationService.currentUserValue;

    // if (this.isProfile) {
    //   if (this.currentUser.role == 'SuperAdmin') {
    //     this.isSuperAdmin = true;
    //   } else {
    //     this.isSuperAdmin = false;
    //   }
    // }
  }

  ngOnInit() {
    this.CreateForm();
    this.getUser();
    this.GetUserGroups(null);
    this.getEntities(null);
    this.getCountries();
    this.getNationalities();
    this.dataSource.paginator = this.paginator;
    this.userInformation.name = '';
    this.changeDetectorRef.detectChanges();
    this.dataSourceGroup.paginator = this.paginatorGroup;
    this.obs = this.dataSourceGroup.connect();
    this.entities.forEach(element => {
      element.name = '';
    });

    // Refresh all page
    this.MyprofileService.refreshDetails.subscribe(data => {
      if (data) { this.getUser() }
    });
  }
  GoOffline(status) {
    this.offlineLoader = true;
    this.MyprofileService.GoOffline(status).subscribe(
      data => {
        this.offlineLoader = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.success'),
          text: '',
          icon: 'success',
          confirmButtonText: this.translate.instant('swalMsg.ok')
        });
        this.getUser();
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  clearDate(event) {
    event.stopPropagation();
    this.date = null;
  }
  updatePageEntities(event) {
    if (event.target.value <= this.pageCountEntities) {
      this.entitiesObj.pageNumber = event.target.value - 1;
      // this.manualPageEntities = this.entitiesObj.pageNumber + 1;
      this.getEntities(null);
    }
  }
  updatePageGroups(event) {
    if (event.target.value <= this.pageCountGroups) {
      this.UserGroupsObj.pageNumber = event.target.value - 1;
      // this.manualPageGroup = this.UserGroupsObj.pageNumber + 1;
      this.GetUserGroups(null);
    }
  }
  logout() {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }
  getUser() {
    this.MyprofileService.GetUser(this.userId).subscribe(
      data => {
        this.userInformation = data;
        this.userStatus.status = data.status;
        if (data.status == 1) {
          this.userStatus.status = 2;
        } else if (data.status == 2) {
          this.userStatus.status = 1;
        } else if (data.status == 0) {
          this.userStatus.status = 1;
        }
        this.authenticationService.getUserProfile();
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  getEntities(event) {
    this.dataLoadingEntities = true;
    if (event) {
      this.entitiesObj.pageNumber = event.pageIndex;
      this.entitiesObj.pageSize = event.pageSize;
    }
    // console.log(this.entitiesObj)
    this.MyprofileService.getEntities(this.entitiesObj).subscribe(
      data => {
        this.entities = data.pageData;
        this.entitesCount = data.totalCount;
        this.totalCountEntities = data.totalCount;
        this.entitiesObj.pageNumber = data.pageIndex;
        this.entitiesObj.pageSize = data.pageSize;
        this.dataSource = new MatTableDataSource<Entity>(this.entities);
        this.dataLoadingEntities = false;
        this.pageCountEntities = Math.ceil(this.totalCountEntities / this.entitiesObj.pageSize);
        this.manualPageEntities = this.entitiesObj.pageNumber + 1;
        // console.log("get entities are ", this.entities);
        // console.log("get entities are ", data);
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  GetUserGroups(event) {
    this.dataLoadingGroups = true;
    if (event) {
      this.UserGroupsObj.pageNumber = event.pageIndex;
      this.UserGroupsObj.pageSize = event.pageSize;
    }
    // console.log("UserGroupsObj are ", this.UserGroupsObj);
    this.MyprofileService.GetUserGroups(this.UserGroupsObj).subscribe(
      data => {
        this.groups = data.pageData as Group[];
        this.dataSourceGroup = new MatTableDataSource<Group>(this.groups);
        this.obs = this.dataSourceGroup.connect();
        this.totalCountGroups = data.totalCount;
        this.groupCount = data.totalCount;
        this.UserGroupsObj.pageNumber = data.pageIndex;
        this.UserGroupsObj.pageSize = data.pageSize;
        this.dataLoadingGroups = false;
        this.pageCountGroups = Math.ceil(this.totalCountGroups / this.UserGroupsObj.pageSize);
        this.manualPageGroup = this.UserGroupsObj.pageNumber + 1;
        // console.log("get groups are ", data);
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  deleteItem() {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.ManageUserService.deleteUser(this.userId).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              // text: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.router.navigate(['admin/manageusers']);
          },
          error => {
            console.log('Try again!, ' + error);
          });
      }
    });
  }
  changeUserStatus() {
    //  console.log(this.userStatus);
    this.ManageUserService.deactivateUser(this.userStatus).subscribe(
      result => {
        // console.log(result);
        this.getUser();
      }
      , err => {
        console.error(err);
      }
      , () => {
      }
    );
  }
  initName(name) {
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
  // custom modal
  open() {
    document.getElementById('formModalBody').scrollTop = 0;
    this.showModal = true;
    this.updateUser = false;
    // this.addUserForm.controls.emailControl.enable();
    this.addUserForm.controls.userName.enable();
  }
  emailmodelChanged(event, userId) {
    // console.log("email is", event);
    this.authenticationService.checkEmail(event, this.userId)
      .pipe(first())
      .subscribe(
        data => {
          console.log('data is!, ' + data);

          if (data) {
            this.emailnameexist = false;
          } else {
            this.emailnameexist = true;
          }

        },
        error => {
          console.log('Try again!, ' + error);
          this.emailnameexist = false;
        });
  }
  closeModal() {
    this.showModal = false;
    this.updateUser = false;
    this.addUserForm.reset();
    this.form.resetForm();
    this.imageSrc = this.budge = '';
    this.addUserForm.controls.gender.setValue('f');
    // this.addUserForm.controls.emailControl.enable();
    this.addUserForm.controls.userName.enable();
  }
  CreateForm() {
    this.addUserForm = this.formBuilder.group({
      emailControl: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      userName: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_-]{2,15}$'),
      ]),
      fullnameControl: new FormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9 _-]+$')
      ]),
      nameControl: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')
      ]),
      // extentionControl: new FormControl('', [
      //   Validators.required,
      //   Validators.pattern('^((\\+91-?)|0)?[0-9]{2,4}$')
      // ]),
      phoneNumberControl: new FormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{1,12}$')
      ]),
      NationalitiesControl: new FormControl('', Validators.required),
      countryControl: new FormControl('', Validators.required),
      cityControl: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', Validators.required),
      identityNumber: new FormControl('', Validators.required),
      jobTitleControl: new FormControl('', Validators.required),
      gender: new FormControl('f', Validators.required),
      employer: new FormControl('', [
        Validators.required
      ]),
      workLocationControl: new FormControl('', [
        Validators.required
      ]),
      isVisitor: new FormControl(false)
    });
  }
  setFormFieldsValues(addUserFormValue) {
    this.newUserObject = {
      id: this.userId,
      email: addUserFormValue.emailControl,
      username: addUserFormValue.userName,
      fullName: addUserFormValue.fullnameControl,
      arabicName: addUserFormValue.nameControl,
      countryKey: this.countryKey,
      // extention: addUserFormValue.extentionControl,
      phoneNumber: addUserFormValue.phoneNumberControl,
      nationalityId: addUserFormValue.NationalitiesControl,
      countryId: addUserFormValue.countryControl,
      cityId: addUserFormValue.cityControl,
      jobTitle: addUserFormValue.jobTitleControl,
      profilePhoto: '',
      birthdate: addUserFormValue.dateOfBirth,
      identityNumber: addUserFormValue.identityNumber,
      gender: addUserFormValue.gender,
      status: 0,
      joinedDate: '2020-04-22T10:34:50.783Z',
      lastLoginDate: '2020-04-22T10:34:50.783Z',
      password: '12345678910',
      confirmPassword: '12345678910',
      employer: addUserFormValue.employer,
      workLocation: addUserFormValue.workLocationControl,
      isVisitor: addUserFormValue.isVisitor
    };
    if (addUserFormValue.dateOfBirth !== this.userInformation.birthdate) {
      this.newUserObject.birthdate = this.datePlusDay(addUserFormValue.dateOfBirth);
    }
  }
  openUpdateForm() {
    // const userId = user.userID;
    // this.updateSelectedUser = user;
    // console.log(this.userId);
    this.CheckIsVisitor();
    this.MyprofileService.GetUser(this.userId).subscribe(
      data => {
        // console.log("pop data", data);
        this.userInformation = data
        this.updateUser = true; // open edit popup
        this.addUserForm.controls.emailControl.setValue(this.userInformation.email);
        this.addUserForm.controls.userName.setValue(this.userInformation.userName);
        this.addUserForm.controls.fullnameControl.setValue(data.fullName);
        this.addUserForm.controls.nameControl.setValue(data.arabicName);
        if (!data.canEditArabicName) {
          this.addUserForm.controls.fullnameControl.disable();
          this.addUserForm.controls.nameControl.disable();
        }
        this.addUserForm.controls.NationalitiesControl.setValue(parseInt(data.nationalityId));
        this.addUserForm.controls.countryControl.setValue(parseInt(data.countryId));
        this.getCountryId(data.countryId, parseInt(data.cityId));

        var countryIndex = this.countries.find(e => e.id == parseInt(data.countryId));
        if (countryIndex && countryIndex.countryKey) {
          this.countryKey = countryIndex.countryKey;
        }
        console.log('country id', parseInt(data.countryId))
        // this.addUserForm.controls.extentionControl.setValue(parseInt(this.countryKey));

        this.addUserForm.controls.jobTitleControl.setValue(data.jobTitle);
        this.addUserForm.controls.dateOfBirth.setValue(data.birthdate);
        this.addUserForm.controls.identityNumber.setValue(data.identityNumber);
        this.addUserForm.controls.phoneNumberControl.setValue(data.phoneNumber);
        this.addUserForm.controls.employer.setValue(data.employer);
        this.addUserForm.controls.workLocationControl.setValue(data.workLocation);
        this.addUserForm.controls.isVisitor.setValue(data.isVisitor);
        this.visitorCheck.visible = true;
        this.visitorCheck.required = !data.canEditType;
        this.budge = this.initName(data.fullName);
        if (data.profilePhoto) {
          this.imageSrc = data.profilePhoto;
        }

        if (this.addUserForm.controls.gender.value == '') {
          this.addUserForm.controls.gender.setValue('f');
        } else {
          this.addUserForm.controls.gender.setValue(data.gender);
        }

        if (this.updateUser == true) {
          // this.addUserForm.controls.emailControl.disable();
          this.addUserForm.controls.userName.disable();
        } else {
          // this.addUserForm.controls.emailControl.enable();
          this.addUserForm.controls.userName.enable();
        }
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  hasError(controlName: string, errorName: string) {
    return this.addUserForm.controls[controlName].hasError(errorName);
  }
  CheckIsVisitor() {
    this.authenticationService.CheckIsVisitor()
      .subscribe(data => {
        this.visitorCheck = data;
      }, error => {
        console.log(error);
      });
  }
  getCountries() {
    this.ManageUserService.getCounties().subscribe(
      data => {
        this.countries = data;
        // console.log("data is ", this.countries);
      },
      error => {
        // console.log('Try again!, ' + error);
      });
  }
  getNationalities() {
    this.ManageUserService.getNationalities().subscribe(
      data => {
        this.Nationalities = data;
        // console.log("data is ", this.Nationalities);
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  getCountryId(countryId, updateCity = undefined) {
    let cId = countryId.value;

    // var countryIndex = this.countries.find(e=> e.id == cId);
    // this.countryKey = countryIndex.countryKey;

    if (updateCity !== undefined) {
      cId = countryId;
    }

    var countryIndex = this.countries.find(e => e.id == cId);
    if (countryIndex && countryIndex.countryKey) {
      this.countryKey = countryIndex.countryKey;
    }

    if (countryId.value !== '') {
      this.enableCity = true;
      // console.log(this.enableCity);
      this.ManageUserService.getCities(cId).subscribe(
        data => {
          this.cities = data;
          if (updateCity !== undefined) {
            this.addUserForm.controls.cityControl.setValue(updateCity);
          }
          // console.log("data is ", this.cities);
        },
        error => {
          console.log('Try again!, ' + error);
        });
    }
  }
  addFile(event) {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0] && this.validateFile(fi.files[0])) {
      this.fileToUpload = fi.files[0];
    }
    // Preview File
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }
  makeBudge(event) {
    this.budge = this.initName(event.target.value);
  }
  saveUser() {
    console.log(this.addUserForm);
    if (this.addUserForm.valid && !this.fileSizeError && !this.fileTypeError) {
      this.loading = true;
      this.executeUserSubs(this.addUserForm);
    }
  }
  executeUserSubs(formValues) {
    // console.log(formValues.getRawValue());
    this.setFormFieldsValues(formValues.getRawValue());
    // console.log("photo", this.fileToUpload);
    this.ManageUserService.saveUser(this.updateUser, this.newUserObject, this.fileToUpload).subscribe(
      data => {
        this.loading = this.showModal = this.showErrorMsg = false;
        this.imageSrc = '';
        this.addUserForm.controls.gender.setValue('f');
        this.closeModal();
        this.getUser();
        // this.authenticationService.getUserProfile();
      },
      error => {
        // console.error(error);
        this.loading = false;
        this.formErrors = error;
        // console.log(this.formErrors);
        this.showErrorMsg = true;
      },
      () => {

      });
  }

  datePlusDay(date) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }
  dateMinusDay(date) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  }

}
