import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddUserModel } from '../models/user/add-user';
import { UsersSearchModel } from '../models/user/UserSearchModel';
import { Config } from '../models/config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ManageUsersService {

    refreshDetails = new BehaviorSubject<boolean>(false);
    constructor(private http: HttpClient) { }

    GetEntityUsers(searchModel: UsersSearchModel, entityId: number): any {
        return this.http.post(`${Config.apiUrl}/CollectionEntity/GetEntityUsers?entityId=` + entityId, searchModel);
    }

    saveUser(update, user: AddUserModel, fileToUpload: any) {
        const formData = new FormData();
        formData.append('data', JSON.stringify(user));
        if (fileToUpload.name) {
            formData.append('file', fileToUpload, fileToUpload.name);
        }
        let serviceUrl = `${Config.apiUrl}/accounts/AddUser`;
        if (update) {
            serviceUrl = `${Config.apiUrl}/accounts/EditUser`;
        }
        return this.http.post(serviceUrl, formData);
    }

    getPermissions() {
        return this.http.get(`${Config.apiUrl}/accounts/GetUserPagePermissions`);
    }

    GetUsers(searchModel: UsersSearchModel): any {
        return this.http.post(`${Config.apiUrl}/accounts/SearchUser`, searchModel);
    }

    getCounties() {
        return this.http.get(`${Config.apiUrl}/Lookup/Countries`);
    }

    getCities(id) {
        return this.http.get(`${Config.apiUrl}/Lookup/Cities/` + id);
    }

    getNotMembersUsers(collectionId) {
        return this.http.get(`${Config.apiUrl}/CollectionEntity/NotMembersUsers?collectionId=` + collectionId);
    }

    getNationalities() {
        return this.http.get(`${Config.apiUrl}/Lookup/Nationalities`);
    }

    deleteUser(id) {
        return this.http.delete(`${Config.apiUrl}/accounts/Delete/` + id);
    }
    deleteUsers(userIds): any {
        return this.http.post(`${Config.apiUrl}/accounts/DeleteUsers`, userIds);
    }
    deactivateUser(UsersObject) {
        return this.http.put(`${Config.apiUrl}/accounts/EditUserStatus`, UsersObject);
    }
    sendEmail(sendEmailObject) {
        return this.http.post(`${Config.apiUrl}/accounts/SendEmail`, sendEmailObject);
    }

    GetUser(id): any {
        return this.http.get(`${Config.apiUrl}/accounts/GetUser/` + id);
    }

    UploadSubscriberUsers(filetype, files: any){
      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file, file.name);
      });
      return this.http.post(`${Config.apiUrl}/Subscriber/UploadSubscriberUsers?fileType=` + filetype, formData);
    }
}
