export const ColorSelectorWidget = (args: any) => {
  const currentBody = args.annotation && args.annotation.bodies && args.annotation.bodies.length ?
    args.annotation.bodies[0] : null;
  const creator = currentBody ? currentBody.creator.id : null;
  const isReadOnly = () => {
    if (args.editable === true)
      return false;

    if (args.editable === false)
      return true;

    if (args.editable === 'MINE_ONLY') {
      // The original creator of the body

      // The current user
      const me = args.user.id;

      return me !== creator;
    }
    // Global setting as last possible option
    return args.readOnly;
  }

  // 1. Find a current color setting in the annotation, if any
  const currentColorBody = args.annotation ?
    args.annotation.bodies.find((b) => {
      return b.purpose == 'highlighting';
    }) : null;

  // 2. Keep the value in a variable
  const currentColorValue = currentColorBody ? currentColorBody.value : 'YELLOW';

  // 3. Triggers callbacks on user action
  const addTag = (evt) => {
    if (currentColorBody) {
      args.onUpdateBody(currentColorBody, {
        type: 'TextualBody',
        purpose: 'highlighting',
        value: evt.target.dataset.tag
      });
    } else {
      args.onAppendBody({
        type: 'TextualBody',
        purpose: 'highlighting',
        value: evt.target.dataset.tag
      });
    }
  }

  // 4. This part renders the UI elements
  const createButton = (value) => {
    const button = document.createElement('button');

    if (value == currentColorValue)
      button.className = 'selected';

    button.dataset.tag = value;
    button.style.backgroundColor = value == 'YELLOW' ? '#817805' : value;
    button.addEventListener('click', addTag);
    return button;
  }

  const container = document.createElement('div');


  const button0 = createButton('YELLOW');
  const button1 = createButton('RED');
  const button2 = createButton('GREEN');
  const button3 = createButton('BLUE');
  // console.log(isReadOnly())
  if (isReadOnly() && currentBody) {
    button0.disabled = true;
    button1.disabled = true;
    button2.disabled = true;
    button3.disabled = true;
  }
  if ((creator && !isReadOnly()) || !currentBody) {
    container.className = 'colorselector-widget';
    container.appendChild(button0);
    container.appendChild(button1);
    container.appendChild(button2);
    container.appendChild(button3);
  }

  // document.querySelector('.r6o-comment-dropdown-menu').addEventListener('click', () => {
  //   // Handle the edit button click event
  //   console.log('Edit button clicked for annotation:');
  //   // ... Your code here ...
  // });

  return container;
}

export const ColorFormatter = (annotation) => {
  const highlightBody = annotation.bodies.find((b) => {
    return b.purpose == 'highlighting';
  });

  if (highlightBody)
    return highlightBody.value;
}

