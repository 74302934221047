import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {
  language = localStorage.getItem('language') || 'en';
  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(this.language);
  }

    transform(value: any, args?: any): any {

        if (value) {
            let expiryDate = +new Date(value)
            let now = +new Date()
            let seconds = (expiryDate - now) / 1000; // expiry Date - current time
            let sign = Math.sign(seconds)
            let suffix = this.translate.instant('meetings.left') // if the time is yet to come.
            if (sign === -1) {
                seconds = Math.floor(seconds * sign) // removign the sign and the float part -25.5  = 25 seconds
                suffix = this.translate.instant('meetings.ago')// if time is already expired.
            }
            const intervalsEn = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            const intervalsAr = {
              'سنه': 31536000,
              'شهر': 2592000,
              'اسبوع': 604800,
              'يوم': 86400,
              'ساعة': 3600,
              'دقيقة': 60,
              'ثانيه': 1
          };

            // const allInterval =
            //   [
            //     'year',
            //     'month',
            //     'week',
            //     'day',
            //     'hour',
            //     'minute',
            //     'second'
            // ];
            const allInterval =
            [
              this.translate.instant('general.year'),
              this.translate.instant('general.month'),
              this.translate.instant('general.week'),
              this.translate.instant('general.day'),
              this.translate.instant('general.hour'),
              this.translate.instant('general.minute'),
              this.translate.instant('general.second')
          ];
            let counter;
            let intervals;
            if (this.language === 'en') {
              intervals = intervalsEn;
            }else{
              intervals = intervalsAr;
            }
            for (let i of allInterval) {

                counter = Math.floor(seconds / intervals[i]);
                let toReturn: string = ''
                // calculateion shown for for 2hour:51 minute = 171 minute = 10260 second
                if (counter > 0) {
                    toReturn += this.calculateTime(counter, i);  // this will give 2 hours
                    let timeLeft = seconds - counter * intervals[i] // 3060 second
                    let index = allInterval.indexOf(i) + 1 // get the index of next unit
                    i = allInterval[index]; // value of next unit = minute
                    if (index > 6) {
                      if(this.language === 'en'){
                        return toReturn + suffix // second ago for boundary case
                      }else{
                        return suffix + toReturn  // second ago for boundary case
                      }
                    }
                    counter = Math.floor(timeLeft / intervals[i]);  // 3060 second = 51
                    if(this.language === 'en'){
                      toReturn = toReturn + ' ' + this.calculateTime(counter, i) + ' ' + suffix; // will calculate "2 hours 51 Minutes" from current time
                    }else{
                      toReturn =   suffix + ' ' + toReturn + ' ' + this.calculateTime(counter, i); // will calculate "2 hours 51 Minutes" from current time
                    }
                    // toReturn = toReturn + ' ' + this.calculateTime(counter, i) + ' ' + suffix; // will calculate "2 hours 51 Minutes" from current time
                    return toReturn;
                }

            }
        }
        return value;
    }

    calculateTime(counter: number, timeUnit: string) {
        if (counter === 1) {
            return counter + ' ' + timeUnit; // singular (1 hours ago)
        } else {

            let toReturn;
            if(this.language === 'en'){
              toReturn = counter + ' ' + timeUnit + 's ';
            }else{
              toReturn = counter + ' ' + timeUnit;
            }
            return toReturn; // plural (2 hours ago)
        }

    }
}
