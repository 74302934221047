import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lab-test-results',
  templateUrl: './lab-test-results.component.html'
})
export class LabTestResultsComponent implements OnInit {

  labTestResults!: any;
  totalCount: number;
  @Input() meetingAgendaItemId!: any;
  @Input() meetingId!: any;
  @Input() liveView!: boolean;
  dataLoading = false;

  openForm = false;
  selectedLabTestResult: any;
  labTestResultId: any;
  @Output() refreshDetails: EventEmitter<any> = new EventEmitter();

  constructor(private meetingService: MeetingDetailsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getLapTest();
    this.meetingService.refreshSlides.subscribe(data => {
      if (data) {
        this.getLapTest();
      }
    });
  }

  getLapTest() {
    this.dataLoading = true;
    let filter = {
      pageNumber: 0,
      pageSize: 200,
      meetingAgendaItemId: this.meetingAgendaItemId
    }
    this.meetingService.getLapTest(filter).subscribe((data: any) => {
      this.labTestResults = data;
      this.totalCount = data.totalCount;
      this.dataLoading = false;
    })
  }

  deleteTestResult(id) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.meetingService.DeleteLapTest(id).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.meetingService.refreshSlides.next(true);
          },
          error => {
            console.log('Try again!, ' + error);
          });
      }
    });
  }

  openLabTestResultForm(item) {
    this.openForm = true;
    this.selectedLabTestResult = item;
    this.labTestResultId = item.id;
  }

  saveItemSucsses() {
    this.openForm = false;
    Swal.fire({
      title: this.translate.instant('swalMsg.addedsuccessfully'),
      text: '',
      icon: 'success',
      confirmButtonText: this.translate.instant('swalMsg.ok'),
    }).then((result) => {
      this.getLapTest();
    });
  }

}
