import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { BehaviorSubject } from 'rxjs';
import * as FileSaver from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class VotingService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getAll(filterObj): any {
    return this.http.post(`${Config.apiUrl}/Voting/List`, filterObj);
  }

  GetGroupsForVoting(isFilter): any{
    return this.http.get(`${Config.apiUrl}/groups/GetGroupsForVoting?isFilter=` + isFilter);
  }

  GetAssigneesToGroupVoting(id, searchText = null): any {
    if(searchText != null){
      return this.http.get(`${Config.apiUrl}/groups/GetAssigneesToGroupVoting/` + id + '?searchText=' + searchText);
    }else{
      return this.http.get(`${Config.apiUrl}/groups/GetAssigneesToGroupVoting/` + id);

    }
  }

  GetVotingSchema(): any{
    return this.http.get(`${Config.apiUrl}/lookup/GetVotingSchema`);
  }

  addVoting(Voting): any{
    if(Voting.id != null){
      return this.http.put(`${Config.apiUrl}/Voting`, Voting);
    }else{
      return this.http.post(`${Config.apiUrl}/Voting`, Voting);
    }
  }

  GetVotingDetails(id): any{
    return this.http.get(`${Config.apiUrl}/Voting/Details/` + id);
  }


  WhoCanVote(obj): any{
    return this.http.post(`${Config.apiUrl}/Voting/WhoCanVote`, obj);
  }

  GetAnswers(id): any{
    return this.http.get(`${Config.apiUrl}/Voting/GetAnswers/` + id);
  }

  GetSummary(id): any{
    return this.http.get(`${Config.apiUrl}/Voting/GetSummary/` + id);
  }

  GetPowerStatistics(id): any{
    return this.http.get(`${Config.apiUrl}/Voting/GetPowerStatistics/` + id);
  }

  GetGroupPowerStatistics(id): any{
    return this.http.get(`${Config.apiUrl}/Voting/GetGroupPowerStatistics/` + id);
  }

  ReopenVote(id): any{
    return this.http.get(`${Config.apiUrl}/Voting/ReopenVote/` + id);
  }

  ChangeVotingStatus(id, status): any{
    return this.http.get(`${Config.apiUrl}/Voting/ChangeVotingStatus/` + id + "?status=" + status);
  }

  ExtendVote(obj): any{
    return this.http.post(`${Config.apiUrl}/Voting/ExtendVote`, obj);
  }

  SubmitVote(obj): any{
    return this.http.post(`${Config.apiUrl}/Voting/SubmitVote`, obj);
  }

  deleteVote(id): any{
    return this.http.delete(`${Config.apiUrl}/Voting/Delete/` + id);
  }

  RequestFirstOneTimePassword(voterAnswerId, mileStoneId){
    return this.http.get(`${Config.apiUrl}/Voting/RequestFirstOneTimePassword/?voterAnswerId=` + voterAnswerId + '&mileStoneId=' + mileStoneId);
  }

  // Voting/GenerateDetailsAsPdf?mileStoneId=62&isPowerAllowed=true


  GenerateDetailsAsPdf(mileStoneId, isPowerAllowed): any{
    return this.http.get(`${Config.apiUrl}/Voting/GenerateDetailsAsPdf?mileStoneId=` + mileStoneId +'&isPowerAllowed=' + isPowerAllowed, { responseType: 'blob' });
  }

  public saveAsPDFFile(buffer: any, fileName: string, voteCode:any): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/pdf'
    });
    FileSaver.saveAs(data, fileName + '-' + voteCode + '.pdf');
  }
}
