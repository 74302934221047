import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AgendaItem } from 'src/app/models/meeting/AgendaItem';
import { CaseService } from 'src/app/services/Case.service';
import Swal from 'sweetalert2';
import { VotingView } from '../../case-voting/Voting.models';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { MatDialog } from '@angular/material';
import { TreatmentFormComponent } from '../treatment-form/treatment-form.component';

@Component({
  selector: 'app-treatment-list',
  templateUrl: './treatment-list.component.html'
})
export class TreatmentListComponent implements OnInit {
  @Input() item: AgendaItem;
  VotingView = VotingView;

  dataLoading = false;
  plansData: any;

  sortSubscribe: Subscription;

  constructor(
    private service: CaseService,
    private signalRService: SignalRServiceService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getAll();
    this.signalRService.handleTreatmentPlaneEvent(this, this.updateList);

    this.service.refreshList.subscribe(data => {
      if (data && this.item.agendaItemEntityId) {
        this.getAll();
      }
    });
  }

  updateList(context, agendaItemId) {
    if (context.item.agendaItemEntityId === agendaItemId) {
      context.getAll();
    }
  }

  getAll() {
    this.dataLoading = true;
    this.service.AgendItemTreatmentList(this.item.agendaItemEntityId).subscribe(
      (resp: any) => {
        this.dataLoading = false;
        this.plansData = resp;
      }, err => {
        console.error(err);
        this.dataLoading = false;
      });
  }

  deleteRecord(id: number) {
    Swal.fire({
      title: this.translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('swalMsg.Yesdeleteit'),
      cancelButtonText: this.translate.instant('swalMsg.Nokeepit')
    }).then((result) => {
      if (result.value) {
        this.service.DeleteAgendItemTreatment(id).subscribe(
          data => {
            Swal.fire({
              title: this.translate.instant('swalMsg.deletededsuccessfuly'),
              icon: 'success',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
            this.getAll();
          }, error => {
            console.error(error);
            Swal.fire({
              title: this.translate.instant('swalMsg.Failed'),
              text: this.translate.instant('swalMsg.FailedText'),
              icon: 'error',
              confirmButtonText: this.translate.instant('swalMsg.ok')
            });
          });
      }
    });
  }

  // Treatment List
  removeTermsItem(index) {
    this.plansData.pageData.splice(index, 1);
  }
  drop(event: CdkDragDrop<string[]>) {
    this.sortSubscribe ? this.sortSubscribe.unsubscribe() : '';
    moveItemInArray(this.plansData.pageData, event.previousIndex, event.currentIndex);
    let data = {
      agendaItemId: this.item.agendaItemEntityId,
      sortData: this.plansData.pageData.map((i, index) => {
        return {
          id: i.id,
          sortorder: index
        }
      })
    };
    this.sortSubscribe = this.service.SortAgendItemTreatment(data).subscribe(
      data => {
        // this.getAll();
      }, error => {
        console.error(error);
        Swal.fire({
          title: this.translate.instant('swalMsg.Failed'),
          text: this.translate.instant('swalMsg.FailedText'),
          icon: 'error',
          confirmButtonText: this.translate.instant('swalMsg.ok')
        });
      });
  }

  openForm(record: any | null = null) {
    const dialogRef = this.dialog.open(TreatmentFormComponent, {
      width: '560px',
      data: { record, agendaItemId: this.item.agendaItemEntityId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll();
      }
    });
  }
}
