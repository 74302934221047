import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {
  refreshList = new BehaviorSubject<boolean>(false);
  openSlidesViewer = new BehaviorSubject<number[]>([]);

  constructor(private http: HttpClient) { }

  GetSlideLookUps() {
    return this.http.get(`${Config.apiUrl}/Meetings/GetSlideLookUps`);
  }
  GetPathologySlides(meetingId, agendaItemId) {
    return this.http.get(`${Config.apiUrl}/Meetings/GetPathologySlides?meetingId=${meetingId}&MeetingAgendaItemId=${agendaItemId}`);
  }
  GetSelectedSlides(meetingId: number, CommunicationMethodLookup: number) {
    return this.http.get(`${Config.apiUrl}/Meetings/GetSelectedSlides?meetingId=${meetingId}&CommunicationMethodLookup=${CommunicationMethodLookup}`);
  }
  AddSlide(data, wsiFile) {
    const formData = new FormData();
    if (wsiFile.length) {
      formData.append('file', wsiFile[0], wsiFile[0].name);
    }
    formData.append('data', JSON.stringify(data));
    return this.http.post(`${Config.apiUrl}/Meetings/AddSlide`, formData);
  }
  LinkUnLinkAgendaItemSlide(data) {
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/LinkUnLinkAgendaItemSlide/`, data);
  }
  DeleteSlide(slideId: number) {
    return this.http.get(`${Config.apiUrl}/Meetings/DeleteSlide?slideId=${slideId}`);
  }
  EditSlide(data) {
    return this.http.post(`${Config.apiUrl}/Meetings/EditSlide`, data);
  }
}
