import { Directive, Input, HostListener } from '@angular/core';
import { LookupsService } from '../services/lookups.service';

@Directive({
  selector: '[appDownload]'
})
export class DownloadDirective {
  @Input() file: any;
  constructor(
    private lookupsService: LookupsService
  ) { }

  @HostListener('click', ['$event']) onClick($event) {
    this.download();
  }

  download() {
    this.lookupsService.downloadAttachment(this.file);
  }
}
