import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpInterceptor } from '@angular/common/http';
import { Config } from '../models/config';
import { Group } from '../models/group/group';
import { FilterGroupEntity } from '../models/group/FilterGroupEntity';
import { FilterGroupAssignees } from '../models/group/FilterGroupAssignees';
import { map } from 'rxjs/operators';
import { Entity } from '../models/entity/Entity';
import { Observable } from 'rxjs';
import { EnitiesFromGroup } from '../models/group/EnitiesFromGroup';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ManageGroupsService {

  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  // getGroups() {
  //   return this.http.get(`${Config.apiUrl}/groups/Groups/`).pipe(map((data: any[]) => {
  //     return data.map(item => {
  //      return {
  //       id: item.id,
  //       name: item.name,
  //       nameInArabic: item.nameInArabic,
  //       purpose: item.purpose,
  //       workingMechanisms: item.workingMechanisms,
  //       abbreviation: item.abbreviation,
  //       created:item.created,
  //       entitiesLogo:item.entitiesLogo
  //     } as Group;
  //   }); }));
  // }
  GetGroups(FilterGroupEntity: FilterGroupEntity): any {
    return this.http.post(`${Config.apiUrl}/groups/Groups`, FilterGroupEntity);
  }
  getEntities() {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${Config.apiUrl}/groups/GetEntitesForNewGroup`).pipe(map((data: any[]) => {
      return data.map(item => {
        return {
          name: item.name,
          id: item.collectionEntityId,
          logo: item.logo,
          arabicName: item.nameInArabic
        } as unknown as Entity;
      });
    }));
  }

  canCreateNewGroup(){
    return this.http.get<any>(`${Config.apiUrl}/groups/CanCreateNewGroup`);
  }

  Entities(id) {
    return this.http.get(`${Config.apiUrl}/groups/GetEntities/` + id).pipe(map((data: any[]) => {
      return data.map(item => {
        return {
          name: item.name,
          id: item.collectionEntityId,
          logo: item.logo,
          arabicName: item.nameInArabic,
        } as unknown as Entity;
      });
    }));
  }
  IndvUsers(groupId) {
    return this.http.get(`${Config.apiUrl}/groups/GetPossibleIndividualUsers/` + groupId).pipe(map((data: any[]) => {
      return data.map(item => {
        return {
          name: item.name,
          id: item.userId,
          logo: item.logo,
          arabicName: item.nameInArabic
        } as any;
      });
    }));
  }

  AddGroup(group: Group, files: any) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file[]', file, file.name);
    });
    formData.append('data', JSON.stringify(group));
    return this.http.post(`${Config.apiUrl}/groups/AddGroup`, formData);
  }
  getGroup(id): Observable<Group> {
    return this.http.get<Group>(`${Config.apiUrl}/groups/GetGroup/` + id);
  }
  RespondAssigneeToGroupInvitation(obj) {
    return this.http.post(`${Config.apiUrl}/groups/RespondAssigneeToGroupInvitation`, obj);
  }
  updateGroup(id, groupInfo) {
    return this.http.put(`${Config.apiUrl}/groups/UpdateGroup/` + id, groupInfo);
  }
  deleteGroup(id) {
    return this.http.get(`${Config.apiUrl}/groups/DeleteGroup/` + id);
  }
  getGroupTerms(id): Observable<[]> {
    return this.http.get<[]>(`${Config.apiUrl}/groups/GetGroupTerms/` + id);
  }
  updateGroupTerms(id, termsList) {
    return this.http.post(`${Config.apiUrl}/groups/UpdateGroupTerms/` + id, termsList);
  }
  getGroupAttachments(id) {
    return this.http.get(`${Config.apiUrl}/groups/GetGroupAttachments/` + id);
  }
  updateGroupAttachments(id, files, deleted) {
    const formData = new FormData();
    files.forEach(file => {
      if (file.attachmentId === undefined) { // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(deleted));
    return this.http.post(`${Config.apiUrl}/groups/UpdateGroupAttachments/` + id, formData);
  }
  GetEntitesFromGroup(EnitiesGroupObject: EnitiesFromGroup): any {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${Config.apiUrl}/groups/GetEntitesFromGroup`, EnitiesGroupObject, {headers: reqHeader});
  }
  GetGroupMembers(UsersGroupObject: EnitiesFromGroup): any {
    return this.http.post(`${Config.apiUrl}/groups/GetGroupMembers`, UsersGroupObject);
  }
  AddEntitiesWithPermissions(addMemberObj: any): any {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${Config.apiUrl}/groups/AddEntitiesWithPermissions/`, addMemberObj, {headers: reqHeader});
  }
  EditEntitiesPermissions(editableObj: any): any {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${Config.apiUrl}/groups/EditEntitiesPermissions/`, editableObj, { headers: reqHeader });
  }

  EditAssigneePermissions(editableObj: any): any {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${Config.apiUrl}/groups/EditAssigneePermissions/`, editableObj, { headers: reqHeader });
  }
  deleteEntityFromGroup(deleteObj) {
    return this.http.post(`${Config.apiUrl}/groups/RemoveEntityFromGroup`, deleteObj);
  }
  GetUsersNotExistInGroup(collectionObject): any {
    return this.http.post(`${Config.apiUrl}/groups/GetUsersNotExistInGroup`, collectionObject);
  }
  listingUsersInGroup(FilterGroupAssignees: FilterGroupAssignees): any {
    return this.http.post(`${Config.apiUrl}/groups/listingUsersInGroup`, FilterGroupAssignees);
  }
  AssignUserToGroup(data) {
    return this.http.post(`${Config.apiUrl}/groups/AssignUserToGroup`, data);
  }
  DeleteAssignee(deleteObj) {
    console.log(deleteObj);
    return this.http.post(`${Config.apiUrl}/groups/DeleteAssignee`, deleteObj);
  }



  ListEntitiesInGroup(obj): any {
    return this.http.post(`${Config.apiUrl}/groups/ListEntitiesInGroup`, obj);
  }

  ListUsersInGroup(obj): any {
    return this.http.post(`${Config.apiUrl}/groups/ListUsersInGroup`, obj);
  }
}
